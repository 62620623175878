<template>
  <!-- Edit Fault Type dialog box -->
  <v-dialog v-model="editDialog" width="408">
    <v-card>
      <v-card-title>
        <div class="mx-auto font-semibold text-lg text-[#101828]">
          {{ $t("editfaulttypedialog.editFaults") }}
        </div>
        <div>
          <v-img
            @click="editDialog = false"
            :src="require(`../../assets/close-icon.svg`)"
            :lazy-src="require(`../../assets/close-icon.svg`)"
            class="cursor-pointer"
          >
          </v-img>
        </div>
      </v-card-title>
      <v-card-text>
        <v-label>{{ $t("editfaulttypedialog.category") }}</v-label>
        <v-select
          :items="categoryNames"
          :label="$t('editfaulttypedialog.selectOne')"
          dense
          outlined
          single-line
          v-model="selectedFaultTypeName"
        ></v-select>
        <v-label>German</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newGermanLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>English</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newEnglishLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>Romanian</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newRomanianLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>Spanish</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newSpanishLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>Chinese</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newChineseLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>Bosnian</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newBosnianLang"
          outlined
          color="#667085"
        ></v-text-field>
        <v-label>Serbian</v-label>
        <v-text-field
          :placeholder="$t('faulttype.waitingForShiftHandover')"
          dense
          ref="name"
          v-model="newSerbianLang"
          outlined
          color="#667085"
        ></v-text-field>
        <div class="flex justify-between items-center">
          <div>
            <v-btn
              color="#344054"
              class="cancel-btn"
              @click="editDialog = false"
              depressed
              outlined
              width="174"
              height="44"
              >{{ $t("editfaulttypedialog.cancel") }}</v-btn
            >
          </div>
          <div>
            <v-btn
              class="save-btn"
              depressed
              color="primary"
              width="174"
              height="44"
              :loading="editFaultTypeLoader"
              @click="editFaultType"
              >{{ $t("editfaulttypedialog.save") }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["selectedFaultType", "categoryNames"],
  data() {
    return {
      editDialog: false,
      editFaultTypeLoader: false,
      selectedFaultTypeId: "",
      selectedFaultTypeName: "",
    };
  },
  computed: {
    newEnglishLang: {
      get() {
        return this.selectedFaultType?.english;
      },
      set(val) {
        this.$emit("update-englishLang", val);
      },
    },
    newGermanLang: {
      get() {
        return this.selectedFaultType?.german;
      },
      set(val) {
        this.$emit("update-germanLang", val);
      },
    },
    newRomanianLang: {
      get() {
        return this.selectedFaultType?.romanian;
      },
      set(val) {
        this.$emit("update-romanianLang", val);
      },
    },
    newSpanishLang: {
      get() {
        return this.selectedFaultType?.spanish;
      },
      set(val) {
        this.$emit("update-spanishLang", val);
      },
    },
    newChineseLang: {
      get() {
        return this.selectedFaultType?.chinese;
      },
      set(val) {
        this.$emit("update-chineseLang", val);
      },
    },
    newBosnianLang: {
      get() {
        return this.selectedFaultType?.bosnian;
      },
      set(val) {
        this.$emit("update-bosnianLang", val);
      },
    },
    newSerbianLang: {
      get() {
        return this.selectedFaultType?.serbian;
      },
      set(val) {
        this.$emit("update-serbianLang", val);
      },
    },
  },

  methods: {
    showEditDialog() {
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },

    stopEditLoader() {
      this.editFaultTypeLoader = false;
    },

    editFaultType() {
      let newData = {
        old_category: this.selectedFaultType.category,
        new_category: this.selectedFaultTypeName,
        english: this.newEnglishLang,
        german: this.newGermanLang,
        chinese: this.newChineseLang,
        romanian: this.newRomanianLang,
        spanish: this.newSpanishLang,
        bosnian: this.newBosnianLang,
        serbian: this.newSerbianLang,
        id: this.selectedFaultType.id,
      };
      this.editFaultTypeLoader = true;
      this.$emit("update-faultType", newData);
    },

    getSelectedFaultTypeId() {
      let faultTypeName = this.selectedFaultType?.category;
      this.categoryNames.forEach((category) => {
        if (category == faultTypeName) {
          this.selectedFaultTypeName = category;
        }
      });
    },
  },

  watch: {
    editDialog(val) {
      if (val) {
        this.getSelectedFaultTypeId();
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}
</style>
