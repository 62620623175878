<template>
  <router-link
    :to="to"
    class="flex justify-center items-center w-[91px] h-[40px] gap-5 cursor-pointer"
  >
    <div>
      <v-img :src="require(`../assets/chevron-left-gray.svg`)"></v-img>
    </div>
    <div class="text-[#667085] text-[14px] font-[400]">
      {{ $t("loginworkerview.GoBack") }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
  },
};
</script>
