<template>
  <div class="bg-[#ffffff] h-[100vh] max-h-[90.5vh]">
    <div class="w-[94%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div class="flex my-4">
            <div class="text-[18px] font-[600] text-[#101828]">
              {{ $t("automaticmessages.automaticMessages") }}
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="flex items-center justify-start gap-10">
              <!-- Select field -->
              <div class="mt-3">
                <v-select
                  :items="machineNames"
                  height="46"
                  :label="$t('automaticmessages.selectMachine')"
                  item-text="name"
                  item-value="id"
                  v-model="selectedMachine"
                  dense
                  outlined
                  single-line
                ></v-select>
              </div>
              <!-- Buttons  -->
              <div
                class="bg-[white] flex justify-between items-center align-middle mb-4"
              >
                <div
                  class="border-[1px] border-[#D0D5DD] px-[10px] py-3 rounded-tl-lg rounded-bl-lg cursor-pointer"
                  :class="`${isViewAll ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                  @click="getAllAutomaticMessages"
                >
                  {{ $t("automaticmessages.all") }}
                </div>
                <div
                  class="border-[1px] border-[#D0D5DD] px-[10px] py-3 cursor-pointer"
                  :class="`${isViewNIO ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                  @click="getAllNIOMessages"
                >
                  {{ $t("automaticmessages.nio") }}
                </div>
                <div
                  class="border-[1px] border-[#D0D5DD] px-[10px] py-3 rounded-tr-lg rounded-br-lg cursor-pointer"
                  :class="`${isViewFaults ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                  @click="getAllFaultMessages"
                >
                  {{ $t("automaticmessages.faults") }}
                </div>
              </div>
            </div>
            <!-- Search input field  -->
            <div class="w-[400px] mb-3">
              <div
                class="w-full bg-[#ffffff] border border-[#D0D5DD] rounded-lg flex flex-row justify-between items-center align-middle h-[50px]"
              >
                <div class="py-5 pl-5">
                  <v-img src="@/assets/searchIcon.svg"></v-img>
                </div>
                <input
                  class="flex flex-row justify-between flex-1 p-4 mx-auto align-middle outline-none"
                  :placeholder="$t('theinput.search')"
                  v-model="search"
                  type="text"
                  @keyup.enter="fetchSearchItems"
                />
              </div>
            </div>
          </div>

          <!-- Automatic Messages Table -->
          <v-data-table
            :headers="
              automaticMessagesLang === 'en'
                ? headers
                : automaticMessagesLang === 'de'
                ? deHeaders
                : automaticMessagesLang === 'cn'
                ? cnHeaders
                : automaticMessagesLang === 'ro'
                ? roHeaders
                : automaticMessagesLang === 'ba'
                ? baHeaders
                : automaticMessagesLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="automaticMessages"
            class="mt-5 border-2"
            :hide-default-footer="true"
            :no-data-text="$t('userrole.nodata')"
          >
            <template v-slot:[`header.icon`]="{}">
              <div class="flex justify-end mt-5 mr-10">
                <div>{{ $t("automaticmessages.actions") }}</div>
              </div>
            </template>

            <template v-slot:[`item.icon`]="{ item }">
              <div class="flex justify-end mr-16">
                <div class="cursor-pointer w-[15px]">
                  <v-img
                    :src="require('../../assets/edit-icon.svg')"
                    :lazy-src="require('../../assets/edit-icon.svg')"
                    @click="startEditAutomaticMessages(item)"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-data-table>

          <EditAutomaticMessageDialog
            :selectedAutomaticMessage="selectedAutomaticMessage"
            ref="EditAutomaticMessageDialogRef"
            @update-automaticMessage="updateAutomaticMessage"
            @update-englishLang="updateEnglishLang"
            @update-germanLang="updateGermanLang"
            @update-romanianLang="updateRomanianLang"
            @update-spanishLang="updateSpanishLang"
            @update-chineseLang="updateChineseLang"
            @update-bosnianLang="updateBosnianLang"
            @update-serbianLang="updateSerbianLang"
          />

          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>

          <v-overlay :value="automaticMessagesLoader" color="grey">
            <v-progress-circular
              indeterminate
              size="70"
              color="primary"
            ></v-progress-circular>
          </v-overlay>

          <div class="position-alert" v-if="showAlert">
            <AlertComponent
              :alertColor="alertColor"
              :textColor="textColor"
              :alertMessage="alertMessage"
              :isSuccess="isSuccess"
              @hide-alert="hideAlert"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import debounce from "lodash.debounce";
import EditAutomaticMessageDialog from "../../components/admin/EditAutomaticMessageDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";
export default {
  components: { EditAutomaticMessageDialog, AlertComponent },
  data() {
    return {
      selectedMachine: "",
      machineNames: [],

      showAlert: false,
      automaticMessagesLoader: false,
      selectedAutomaticMessage: null,
      machineId: "",
      selectedCat: "All",
      search: "",

      isViewAll: true,
      isViewNIO: false,
      isViewFaults: false,

      totalMessagesCount: 1,

      page: 1,
      totalPages: 1,
      perPage: 10,

      headers: [
        {
          text: "Machine Name",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Event",
          value: "event_name",
        },
        {
          text: "Description",
          value: "english",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      deHeaders: [
        {
          text: "Name der Maschine",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Kategorie",
          value: "category",
        },
        {
          text: "Event",
          value: "event_name",
        },
        {
          text: "Text",
          value: "german",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      cnHeaders: [
        {
          text: "机器名称",
          align: "start",
          value: "machine_name",
        },
        {
          text: "类别",
          value: "category",
        },
        {
          text: "活动",
          value: "event_name",
        },
        {
          text: "描述",
          value: "chinese",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      roHeaders: [
        {
          text: "Numele mașinii",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Categoria",
          value: "category",
        },
        {
          text: "Eveniment",
          value: "event_name",
        },
        {
          text: "Descriere",
          value: "romanian",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      esHeaders: [
        {
          text: "Nombre de la máquina",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Categoría",
          value: "category",
        },
        {
          text: "Evento",
          value: "event_name",
        },
        {
          text: "Descripción",
          value: "spanish",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      baHeaders: [
        {
          text: "Naziv mašine",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Kategorija",
          value: "category",
        },
        {
          text: "Događaj",
          value: "event_name",
        },
        {
          text: "Opis",
          value: "bosnian",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      rsHeaders: [
        {
          text: "Назив машине",
          align: "start",
          value: "machine_name",
        },
        {
          text: "Категорија",
          value: "category",
        },
        {
          text: "Догађај",
          value: "event_name",
        },
        {
          text: "Опис",
          value: "serbian",
        },
        {
          text: "",
          value: "icon",
        },
      ],
      automaticMessages: [],
    };
  },
  computed: {
    automaticMessagesLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  methods: {
    //Get search results
    fetchSearchItems() {
      this.searchCategory = "All";
      Api()
        .post(`/machines/search-automatic-reason-types/0/${this.page}/`, {
          reason_type: this.searchCategory,
          search_query: this.search,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
            this.totalPages = res.data.total_pages;
            this.totalMessagesCount = res.data.total_reasons;
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    //Get all Machines
    getAllMachines(showLoader) {
      this.automaticMessagesLoader = showLoader;
      Api()
        .post(`/machines/get-dropdowns/1/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.machineNames = res.data.data.map((machine) => ({
              id: machine.id,
              name: machine.machine_name,
            }));
          }
        });
    },

    //Get Selected Machine
    getSelectedMachine(machineId) {
      Api()
        .post(`/machines/automatic-reason-types/${machineId}/${this.page}/`, {
          reason_type: this.selectedCat,
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
          }
        });
    },

    //Get Selected NIO Message
    getSelectedNIOMessage(machineId) {
      Api()
        .post(`/machines/automatic-reason-types/${machineId}/${this.page}/`, {
          reason_type: "NIO",
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
          }
        });
    },

    //Get all Automatic Messages
    getAllAutomaticMessages(showLoader) {
      this.automaticMessagesLoader = showLoader;
      this.selectedCat = "All";
      Api()
        .post(
          `/machines/automatic-reason-types/${this.machineId || 0}/${
            this.page
          }/`,
          {
            reason_type: this.selectedCat,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
            this.isViewAll = true;
            this.isViewNIO = false;
            this.isViewFaults = false;
            this.totalPages = res.data.total_pages;
            this.totalMessagesCount = res.data.total_reasons;
            this.automaticMessagesLoader = false;
          }
        })
        .catch((err) => {
          this.showError(err);
          this.automaticMessagesLoader = false;
        });
    },

    //Get all NIO messages
    getAllNIOMessages(showLoader) {
      this.automaticMessagesLoader = showLoader;
      this.selectedCat = "NIO";
      Api()
        .post(
          `/machines/automatic-reason-types/${this.machineId || 0}/${
            this.page
          }/`,
          {
            reason_type: this.selectedCat,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
            this.isViewAll = false;
            this.isViewNIO = true;
            this.isViewFaults = false;
            this.totalPages = res.data.total_pages;
            this.totalMessagesCount = res.data.total_reasons;
            this.automaticMessagesLoader = false;
          }
        });
    },

    //Get all Fault messages
    getAllFaultMessages(showLoader) {
      this.automaticMessagesLoader = showLoader;
      this.selectedCat = "Faults";
      Api()
        .post(
          `/machines/automatic-reason-types/${this.machineId || 0}/${
            this.page
          }/`,
          {
            reason_type: this.selectedCat,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.automaticMessages = res.data.data.map((responseItem) => ({
              machine_name: responseItem.machine__machine_name,
              category: responseItem.category,
              event_name: responseItem.serial_number,
              icon: "",
              id: responseItem.id,
              english: responseItem.english,
              german: responseItem.german,
              chinese: responseItem.chinese,
              romanian: responseItem.romanian,
              spanish: responseItem.spanish,
              bosnian: responseItem.bosnian,
              serbian: responseItem.serbian,
            }));
            this.isViewAll = false;
            this.isViewNIO = false;
            this.isViewFaults = true;
            this.totalPages = res.data.total_pages;
            this.totalMessagesCount = res.data.total_reasons;
            this.automaticMessagesLoader = false;
          }
        });
    },

    //Updating an automatic message
    startEditAutomaticMessages(data) {
      console.log("data start edit", data);
      this.selectedAutomaticMessage = { ...data };
      this.showEditModal();
    },

    updateAutomaticMessage(data) {
      Api()
        .put(`/machines/update-automatic-reason-type/${data.id}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.stopEditLoader();
            this.closeEditModal();
            this.showSuccess(res.data.detail);
            this.getAllAutomaticMessages();
          }
        })
        .catch(() => {
          this.stopEditLoader();
        });
    },

    updateEnglishLang(data) {
      this.selectedAutomaticMessage.english = data;
    },
    updateGermanLang(data) {
      this.selectedAutomaticMessage.german = data;
    },
    updateRomanianLang(data) {
      this.selectedAutomaticMessage.romanian = data;
    },
    updateSpanishLang(data) {
      this.selectedAutomaticMessage.spanish = data;
    },
    updateChineseLang(data) {
      this.selectedAutomaticMessage.chinese = data;
    },
    updateBosnianLang(data) {
      this.selectedAutomaticMessage.bosnian = data;
    },
    updateSerbianLang(data) {
      this.selectedAutomaticMessage.serbian = data;
    },

    showEditModal() {
      this.$refs.EditAutomaticMessageDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditAutomaticMessageDialogRef?.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditAutomaticMessageDialogRef?.stopEditLoader();
    },

    hideAlert() {
      this.showAlert = false;
    },

    handlePageChange(value) {
      this.page = value;
      this.getAllAutomaticMessages();
      this.scrollToTop();
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  watch: {
    selectedMachine(newValue) {
      this.machineId = newValue || 0;
      if (newValue !== null) {
        this.getSelectedMachine(newValue);
      }
    },
    search(...args) {
      this.debounceSearchResults(args);
    },
  },

  mounted() {
    this.getAllAutomaticMessages();
    this.getAllMachines();
    this.debounceSearchResults = debounce(this.fetchSearchItems, 100);
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
