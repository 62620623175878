<template>
  <div class="bg-[#ffffff] h-[100vh] max-h-[90.5vh]">
    <div class="w-[94%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div class="flex justify-between items-center my-4">
            <div class="text-[18px] font-[600] text-[#101828]">
              {{ $t("userrole.userRoles") }}
            </div>

            <v-btn
              height="40"
              @click="startAddUserRole()"
              color="#f5f5ff"
              depressed
              class="add-btn"
              >{{ $t("userrole.add") }}</v-btn
            >
          </div>
          <v-divider class="my-6"></v-divider>
          <!-- User Roles table  -->
          <v-data-table
            dense
            :headers="
              userRoleLang === 'en'
                ? headers
                : userRoleLang === 'de'
                ? deHeaders
                : userRoleLang === 'cn'
                ? cnHeaders
                : userRoleLang === 'ro'
                ? roHeaders
                : userRoleLang === 'ba'
                ? baHeaders
                : userRoleLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="formattedUserRoles"
            item-key="user_role_id"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('userrole.nodata')"
          >
            <template v-slot:[`header.icons`]="{}">
              <div class="flex justify-end mr-12 mt-5">
                <div>{{ $t("userrole.actions") }}</div>
              </div>
            </template>
            <template v-slot:[`item.icons`]="{ item }">
              <div class="flex items-center justify-end gap-5 pr-10">
                <div>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    width="400"
                    overlay-opacity="0.1"
                    content-class="elevation-0"
                  >
                    <template v-slot:activator="{}">
                      <v-img
                        class="cursor-pointer w-[15px]"
                        :src="require('../../assets/delete-icon.svg')"
                        :lazy-src="require('../../assets/delete-icon.svg')"
                        @click="showDeleteDialog(item)"
                      ></v-img>
                    </template>
                    <v-card class="pb-4">
                      <v-card-title class="flex justify-between">
                        <div class="font-semibold text-lg text-[#101828]">
                          {{
                            $t("userrole.delete", {
                              "1": selectedUserRole?.user_roles,
                            })
                          }}
                        </div>
                        <div>
                          <v-img
                            @click="dialogDelete = false"
                            :src="require(`../../assets/close-icon.svg`)"
                            :lazy-src="require(`../../assets/close-icon.svg`)"
                            class="cursor-pointer"
                          ></v-img>
                        </div>
                      </v-card-title>
                      <v-card-text
                        ><div class="text-[#667085] font-[400] text-[14px]">
                          {{ $t("userrole.areYouSure")
                          }}<span class="font-bold">{{
                            $t("userrole.containing", {
                              "1": selectedUserRole?.user_roles,
                              "2": selectedUserRole?.total_assigned_users,
                            })
                          }}</span
                          >{{ $t("userrole.thisAction") }}
                        </div></v-card-text
                      >
                      <div class="flex justify-center items-center gap-3">
                        <div>
                          <v-btn
                            class="group-cancel-btn"
                            outlined
                            depressed
                            color="#ffffff"
                            width="170"
                            height="44"
                            @click="dialogDelete = false"
                            >{{ $t("userrole.cancel") }}</v-btn
                          >
                        </div>
                        <div>
                          <v-btn
                            class="group-delete-btn"
                            depressed
                            color="#D92D20"
                            width="170"
                            height="44"
                            :loading="deleteUserRoleLoader"
                            @click="removeUserRole"
                            >{{ $t("userrole.yesDelete") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                </div>
                <div>
                  <v-img
                    class="cursor-pointer w-[15px]"
                    :src="require('../../assets/edit-icon.svg')"
                    :lazy-src="require('../../assets/edit-icon.svg')"
                    @click="startEditUserRole(item)"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-data-table>

          <AddUserRoleDialog
            @add-userRole="addUserRole"
            ref="AddUserRoleDialogRef"
          />

          <EditUserRoleDialog
            :selectedUserRole="selectedUserRole"
            @update-userRoleName="updateUserRoleName"
            @update-membersViewSwitch="updateMembersViewSwitch"
            @update-membersAddSwitch="updateMembersAddSwitch"
            @update-membersDeleteSwitch="updateMembersDeleteSwitch"
            @update-machinesViewSwitch="updateMachinesViewSwitch"
            @update-machinesAddSwitch="updateMachinesAddSwitch"
            @update-machinesDeleteSwitch="updateMachinesDeleteSwitch"
            @update-configViewSwitch="updateConfigViewSwitch"
            @update-configAddSwitch="updateConfigAddSwitch"
            @update-configDeleteSwitch="updateConfigDeleteSwitch"
            @update-shiftlogViewSwitch="updateShiftlogViewSwitch"
            @update-userRole="updateUserRole"
            ref="EditUserRoleDialogRef"
          />

          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay :value="allUserRolesLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import EditUserRoleDialog from "../../components/admin/EditUserRoleDialog.vue";
import AddUserRoleDialog from "../../components/admin/AddUserRoleDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";

export default {
  components: { AddUserRoleDialog, EditUserRoleDialog, AlertComponent },
  data() {
    return {
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      deHeaders: [
        {
          text: "Name",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      esHeaders: [
        {
          text: "Nombre",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      cnHeaders: [
        {
          text: "命名",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      baHeaders: [
        {
          text: "Ime",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      rsHeaders: [
        {
          text: "Име",
          align: "start",
          value: "user_roles",
        },
        { text: "", value: "icons" },
      ],
      userRoles: [
        {
          user_roles: "Admin",
        },
        {
          user_roles: "Shift manager",
        },
      ],

      allUserRolesLoader: false,
      formattedUserRoles: [],
      selectedUserRole: null,
      addUserRoleLoader: false,
      deleteUserRoleLoader: false,
      page: 1,
      totalPages: 1,
      perPage: 10,
      totalUserRolesCount: 1,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },

  computed: {
    userRoleLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  methods: {
    getAllUserRoles(showLoader) {
      this.allUserRolesLoader = showLoader;
      Api()
        .get(`/auth/paginated-user-roles/${this.page}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.formattedUserRoles = [
              ...this.formatUserRolesData(res.data.data),
            ];
            this.totalPages = res.data.total_pages;
            this.totalUserRolesCount = res.data.total_roles;
          }
          this.allUserRolesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allUserRolesLoader = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getAllUserRoles(true);
      this.scrollToTop();
    },

    // Adding a UserRole
    addUserRoleFeatures(userRole) {
      userRole.deleteIcon = require("../../assets/delete-icon.svg");
      userRole.editIcon = require("../../assets/edit-icon.svg");
      userRole.user_roles = userRole.user_role_name;
      userRole.nameTwo = userRole.user_role_name;
      return userRole;
    },

    formatUserRolesData(userRoles) {
      return userRoles.map((userRole) => {
        userRole = this.addUserRoleFeatures(userRole);
        return userRole;
      });
    },

    addToUserRolesList(userRole) {
      userRole = this.addUserRoleFeatures(userRole);
      this.formattedUserRoles.unshift(userRole);
      this.totalUserRolesCount++;
      this.totalPages = Math.ceil(this.totalUserRolesCount / this.perPage);
    },

    addUserRole(newData) {
      Api()
        .post("/auth/add-user-roles/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.addToUserRolesList(res.data.data);
            this.removeLastUserRole();
            this.closeAddModal();
            this.showSuccess(res.data.detail);
          }
          this.stopAddLoader();
          this.resetUserRoleData();
        })
        .catch((err) => {
          this.showError(err);
          this.stopAddLoader();
        });
    },

    removeLastUserRole() {
      if (this.perPage < this.totalUserRolesCount) {
        this.formattedUserRoles.splice(this.perPage, 1);
      }
    },

    startAddUserRole() {
      this.showAddModal();
    },

    showAddModal() {
      this.$refs.AddUserRoleDialogRef?.showAddDialog();
    },

    closeAddModal() {
      this.$refs.AddUserRoleDialogRef?.closeAddDialog();
    },

    stopAddLoader() {
      this.$refs.AddUserRoleDialogRef?.stopAddLoader();
    },

    // Deleting a userRole
    showDeleteDialog(data) {
      this.dialogDelete = true;
      this.selectedUserRole = { ...data };
    },

    removeFromUserRolesList(userRoleId) {
      let userRoles = [...this.formattedUserRoles];
      this.formattedUserRoles = userRoles.filter(
        (userRole) => userRole.user_role_id !== userRoleId
      );
    },

    removeUserRole() {
      this.deleteUserRoleLoader = true;
      Api()
        .delete(`/auth/delete-user-role/${this.selectedUserRole.user_role_id}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.removeFromUserRolesList(this.selectedUserRole.user_role_id);
            this.getAllUserRoles(false);
            this.showSuccess(res.data.detail);
            this.dialogDelete = false;
          }
          this.deleteUserRoleLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.deleteUserRoleLoader = false;
        });
    },

    resetUserRoleData() {
      this.$refs.AddUserRoleDialogRef?.resetUserRoleData();
    },

    // Updating UserRole
    startEditUserRole(data) {
      this.selectedUserRole = { ...data };
      this.showEditModal();
    },

    updateUserRole(data) {
      Api()
        .put(`/auth/update-user-role/${data.user_role_id}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.updateUserRolesList(res.data.data);
            this.closeEditModal();
            this.showSuccess(res.data.detail);
          }
          this.stopEditLoader();
        })
        .catch((err) => {
          this.showError(err);
          this.stopEditLoader();
        });
    },

    updateUserRolesList(userRole) {
      userRole = this.addUserRoleFeatures(userRole);
      this.findAndReplaceUserRole(userRole);
    },

    findAndReplaceUserRole(updatedUserRole) {
      let foundIndex = this.formattedUserRoles.findIndex(
        (userRole) => userRole.user_role_id == updatedUserRole.user_role_id
      );
      if (foundIndex == 0 || foundIndex) {
        this.formattedUserRoles.splice(foundIndex, 1, updatedUserRole);
      }
    },

    updateUserRoleName(data) {
      this.selectedUserRole.user_role_name = data;
    },

    updateMembersViewSwitch(data) {
      this.selectedUserRole.view_member = data;
    },

    updateMembersAddSwitch(data) {
      this.selectedUserRole.add_member = data;
    },

    updateMembersDeleteSwitch(data) {
      this.selectedUserRole.delete_member = data;
    },

    updateMachinesViewSwitch(data) {
      this.selectedUserRole.view_machine = data;
    },

    updateMachinesAddSwitch(data) {
      this.selectedUserRole.add_machine = data;
    },

    updateMachinesDeleteSwitch(data) {
      this.selectedUserRole.delete_machine = data;
    },

    updateConfigViewSwitch(data) {
      this.selectedUserRole.read_config = data;
    },

    updateConfigAddSwitch(data) {
      this.selectedUserRole.add_config = data;
    },

    updateConfigDeleteSwitch(data) {
      this.selectedUserRole.delete_config = data;
    },

    updateShiftlogViewSwitch(data) {
      this.selectedUserRole.view_shiftlog = data;
    },

    showEditModal() {
      this.$refs.EditUserRoleDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditUserRoleDialogRef?.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditUserRoleDialogRef?.stopEditLoader();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllUserRoles(true);
    this.scrollToTop();
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.v-input--selection-controls {
  padding: 10 !important;
  margin: 0 !important;
}

* /deep/ .v-input__control {
  display: flex;
  flex-direction: column;
  height: 32px !important;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #0408e7;
  opacity: 1;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  color: #344054 !important;
}

.update-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.group-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.group-delete-btn {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
