import axios from "axios";
import config from "../../public/config";

import {
  isTokenActive,
  setAuthToken,
  getAuthToken,
  getRefreshToken,
} from "../services/authentication";

export default () => {
  let authToken = getAuthToken();
  const customAxios = axios.create({
    baseURL: config.api,
    withCredentials: false,
    headers: {
      Accept: "Application/json",
      "Content-Type": "Application/json",
      Authorization: authToken ? `Bearer ${authToken}` : null,
    },
  });

  const requestHandler = async (request) => {
    if (!authToken) {
      authToken = getAuthToken();
      request.headers.Authorization = authToken ? `Bearer ${authToken}` : null;
    }

    if (authToken) {
      const isNotExpired = isTokenActive(authToken);
      if (isNotExpired) return request;

      await getNewAccessAndRefreshToken(request);
    }

    return request;
  };

  const getNewAccessAndRefreshToken = async (request) => {
    try {
      const response = await axios.post(`${config.api}/auth/refresh-token/`, {
        refresh: getRefreshToken(),
      });
      if (response?.data?.access) {
        setAuthToken(response.data.access);
        request.headers.Authorization = `Bearer ${response.data.access}`;
      }
    } catch (err) {
      console.log(err);
    }
  };

  customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => {
      console.log(error, "error interceptors");
    }
  );

  return customAxios;
};
