<template>
  <div>
    <v-alert :color="alertColor" width="400">
      <div class="flex justify-start items-center gap-2">
        <div class="w-[16.67px]">
          <v-img
            v-show="isSuccess"
            :src="require('../assets/check-icon.svg')"
            :lazy-src="require('../assets/check-icon.svg')"
          ></v-img>
          <v-img
            v-show="!isSuccess"
            :src="require('../assets/error.svg')"
            :lazy-src="require('../assets/error.svg')"
          ></v-img>
        </div>
        <div class="text-md" :class="`text-[${textColor}]`">
          {{ alertMessage }}
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: ["alertColor", "textColor", "alertMessage", "isSuccess"],
  data() {
    return {};
  },

  methods: {
    hideShownAlert() {
      setTimeout(() => {
        this.$emit("hide-alert");
      }, 3000);
    },
  },

  mounted() {
    this.hideShownAlert();
  },
};
</script>
