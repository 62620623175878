var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-[full]"},[_c('v-data-table',{staticClass:"border-2",attrs:{"fixed-header":"","dense":"","height":"404","item-key":"id","headers":_vm.technicianMachineGroup === 'en'
        ? _vm.headers
        : _vm.technicianMachineGroup === 'de'
        ? _vm.deHeaders
        : _vm.technicianMachineGroup === 'cn'
        ? _vm.cnHeaders
        : _vm.technicianMachineGroup === 'ro'
        ? _vm.roHeaders
        : _vm.technicianMachineGroup === 'ba'
        ? _vm.baHeaders
        : _vm.technicianMachineGroup === 'es'
        ? _vm.esHeaders
        : _vm.rsHeaders,"items":_vm.formattedMachines,"show-select":"","checkbox-color":"#0408E7","hide-default-footer":true,"no-data-text":_vm.$t('assignedworkerview.nodata'),"disable-pagination":""},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('div',{staticClass:"text-[#101828] text-[14px] font-[400]"},[_vm._v(_vm._s(item.name))])]}}],null,true),model:{value:(_vm.selectedTechMachines),callback:function ($$v) {_vm.selectedTechMachines=$$v},expression:"selectedTechMachines"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }