<template>
  <div class="py-5 px-13 w-[full] bg-[#ffffff]">
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-between gap-3">
        <div
          class="flex justify-center items-center w-[91px] h-[40px] gap-5 cursor-pointer"
          @click="goBack()"
        >
          <div>
            <v-img :src="require(`../../assets/chevron-left-gray.svg`)"></v-img>
          </div>
          <div class="text-[#667085] text-[14px] font-[400]">
            {{ $t("allshiftsview.back") }}
          </div>
        </div>
        <div class="text-[#101828] text-[24px] font-[600]">
          {{ shiftDaysData?.machine__name }}
        </div>
      </div>
      <div class="flex items-center justify-between gap-3">
        <div>
          <v-btn
            color="##EAECF0"
            depressed
            min-width="0"
            min-height="0"
            height="36"
            width="36"
            class="flex items-center justify-center rounded-[8px]"
            @click="goBackwardShift(true)"
          >
            <div>
              <v-img :src="require('../../assets/chevron-left.svg')"></v-img>
            </div>
          </v-btn>
        </div>

        <div class="text-[#344054] text-[16px] font-[700]">
          {{ $t("allshiftsview.viewShifts") }}
        </div>

        <div>
          <v-btn
            color="##EAECF0"
            depressed
            min-width="0"
            min-height="0"
            height="36"
            width="36"
            class="flex items-center justify-center rounded-[8px]"
            :disabled="isDisableNextButton"
            @click="goForwardShift(true)"
          >
            <div>
              <v-img :src="require('../../assets/chevron-right.svg')"></v-img>
            </div>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="flex gap-4 mr-3 mt-5 w-full">
      <div class="w-[36%]">
        <AllShiftsCard
          :formattedFaultLogs="formattedFaultLogs1"
          :formattedNioLogs="formattedNioLogs1"
          :formattedCommentLogs="formattedCommentLogs1"
          :shiftHeaderData="shiftHeaderData1"
          :plantStatus="plantStatus1"
          :formattedCardLang="formattedLang"
        />
      </div>
      <div class="w-[36%]">
        <AllShiftsCard
          :formattedFaultLogs="formattedFaultLogs2"
          :formattedNioLogs="formattedNioLogs2"
          :formattedCommentLogs="formattedCommentLogs2"
          :shiftHeaderData="shiftHeaderData2"
          :plantStatus="plantStatus2"
          :formattedCardLang="formattedLang"
        />
      </div>
      <div class="w-[36%]">
        <AllShiftsCard
          :formattedFaultLogs="formattedFaultLogs3"
          :formattedNioLogs="formattedNioLogs3"
          :formattedCommentLogs="formattedCommentLogs3"
          :shiftHeaderData="shiftHeaderData3"
          :plantStatus="plantStatus3"
          :formattedCardLang="formattedLang"
        />
      </div>
    </div>
    <v-overlay :value="allShiftLogsLoader || previousLogsLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import AllShiftsCard from "../../components/admin/AllShiftsCard.vue";
import AlertComponent from "../../components/AlertComponent.vue";
import Api from "../../services/api";
import { format, parseISO } from "date-fns";
import moment from "moment";
import { checkLanguage } from "../../services/utils";

export default {
  components: {
    AllShiftsCard,
    AlertComponent,
  },

  data() {
    return {
      currentShift: null,

      plantStatus1: null,
      plantStatus2: null,
      plantStatus3: null,

      faultLogs1: [],
      nioLogs1: [],
      commentLogs1: [],

      faultLogs2: [],
      nioLogs2: [],
      commentLogs2: [],

      faultLogs3: [],
      nioLogs3: [],
      commentLogs3: [],
      allShiftLogsLoader: false,

      formattedFaultLogs1: [],
      formattedNioLogs1: [],
      formattedCommentLogs1: [],

      formattedFaultLogs2: [],
      formattedNioLogs2: [],
      formattedCommentLogs2: [],

      formattedFaultLogs3: [],
      formattedNioLogs3: [],
      formattedCommentLogs3: [],

      formattedLang: "",

      showLoader: false,

      longAlertMsg: "",
      isLongAlert: false,
      setPoint: "",
      current: "",

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
      aftMacId: "",
      machineStatus: "Automatic",

      showPreviousShift: false,
      showCurrentShift: true,
      showNextShift: false,

      routeMachineId: "",
      // dynamicMachineId: "4", //temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
      timer: null,

      errorCount: 0,
      mainCurrentShift: null,
      allowEndpoint: true,
      previousLogsLoader: false,

      shiftHeaderData1: null,
      shiftHeaderData2: null,
      shiftHeaderData3: null,

      shiftDaysData: null,
    };
  },

  computed: {
    allShiftsLang() {
      return this.$store.state.translation.i18n.locale;
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isCurrentShift() {
      return this.isDisableNextButton;
    },

    isDisableNextButton() {
      return this.shiftDaysData?.next_day?.start_date > this.shiftStartDateTime;
    },

    shiftStartDateTime() {
      return moment().format().slice(0, 10) + "T06:00:00";
    },
  },

  methods: {
    getAllShiftLogs(showLoader) {
      this.allShiftLogsLoader = showLoader;
      Api()
        .post(
          `/machines/all-shift-logs/${this.routeMachineId || this.aftMacId}/`,
          {
            day_shift_start: this.shiftStartDateTime,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            this.shiftDaysData = dataList[0][0];
            this.formattedLang = checkLanguage(this.allShiftsLang);

            let allShiftList = dataList?.slice(1);
            this.formatAllShiftLogs(allShiftList);
          }
          this.allShiftLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allShiftLogsLoader = false;
        });
    },

    formatAllShiftLogs(allShiftList) {
      allShiftList[0].forEach((item) => {
        this.structureShiftData1(item);
      });
      this.formattedFaultLogs1 = this.formatFaultLogs(this.faultLogs1);
      this.formattedNioLogs1 = this.formatNioLogs(this.nioLogs1);
      this.formattedCommentLogs1 = this.formatCommentLogs(this.commentLogs1);

      allShiftList[1].forEach((item) => {
        this.structureShiftData2(item);
      });
      this.formattedFaultLogs2 = this.formatFaultLogs(this.faultLogs2);
      this.formattedNioLogs2 = this.formatNioLogs(this.nioLogs2);
      this.formattedCommentLogs2 = this.formatCommentLogs(this.commentLogs2);

      allShiftList[2].forEach((item) => {
        this.structureShiftData3(item);
      });
      this.formattedFaultLogs3 = this.formatFaultLogs(this.faultLogs3);
      this.formattedNioLogs3 = this.formatNioLogs(this.nioLogs3);
      this.formattedCommentLogs3 = this.formatCommentLogs(this.commentLogs3);
    },

    structureShiftData1(data) {
      if (Object.hasOwnProperty.call(data, "shift_start")) {
        this.shiftHeaderData1 = data;
      } else if (Object.hasOwnProperty.call(data, "fault_logs")) {
        this.faultLogs1 = [...data.fault_logs];
      } else if (Object.hasOwnProperty.call(data, "nio_logs")) {
        this.nioLogs1 = [...data.nio_logs];
      } else if (Object.hasOwnProperty.call(data, "comment_logs")) {
        this.commentLogs1 = [...data.comment_logs];
      } else if (Object.hasOwnProperty.call(data, "planned_stop_count")) {
        this.plantStatus1 = data;
      }
    },

    structureShiftData2(data) {
      if (Object.hasOwnProperty.call(data, "shift_start")) {
        this.shiftHeaderData2 = data;
      } else if (Object.hasOwnProperty.call(data, "fault_logs")) {
        this.faultLogs2 = [...data.fault_logs];
      } else if (Object.hasOwnProperty.call(data, "nio_logs")) {
        this.nioLogs2 = [...data.nio_logs];
      } else if (Object.hasOwnProperty.call(data, "comment_logs")) {
        this.commentLogs2 = [...data.comment_logs];
      } else if (Object.hasOwnProperty.call(data, "planned_stop_count")) {
        this.plantStatus2 = data;
      }
    },

    structureShiftData3(data) {
      if (Object.hasOwnProperty.call(data, "shift_start")) {
        this.shiftHeaderData3 = data;
      } else if (Object.hasOwnProperty.call(data, "fault_logs")) {
        this.faultLogs3 = [...data.fault_logs];
      } else if (Object.hasOwnProperty.call(data, "nio_logs")) {
        this.nioLogs3 = [...data.nio_logs];
      } else if (Object.hasOwnProperty.call(data, "comment_logs")) {
        this.commentLogs3 = [...data.comment_logs];
      } else if (Object.hasOwnProperty.call(data, "planned_stop_count")) {
        this.plantStatus3 = data;
      }
    },

    formatFaultLogs(faultList) {
      return faultList.map((faultItem) => {
        faultItem = this.addFaultFeatures(faultItem);
        return faultItem;
      });
    },

    addFaultFeatures(faultItem) {
      faultItem.info = {
        time: faultItem.duration,
        quantity: faultItem.fault_count,
      };
      faultItem.faults = faultItem.reason_type__name;
      return faultItem;
    },

    formatNioLogs(nioList) {
      return nioList.map((nioItem) => {
        nioItem = this.addNioFeatures(nioItem);
        return nioItem;
      });
    },

    addNioFeatures(nioItem) {
      nioItem.infoTwo = {
        counter: "1",
        quantity: nioItem.quantity,
      };
      nioItem.nio = nioItem.reason_type__name;
      return nioItem;
    },

    formatCommentLogs(commentList) {
      return commentList.map((commentItem) => {
        commentItem = this.addCommentFeatures(commentItem);
        return commentItem;
      });
    },

    addCommentFeatures(commentItem) {
      commentItem.comments = commentItem.reason_type__name;
      commentItem.comment = commentItem.description;

      return commentItem;
    },

    formatDate(data) {
      return format(parseISO(data), "dd/MM/yy");
    },

    formatTime(data) {
      return format(parseISO(data), "hh:mm");
    },

    goBackwardShift(showLoader) {
      this.allowEndpoint = false;
      this.previousLogsLoader = showLoader;
      Api()
        .post(
          `/machines/all-shift-logs/${this.routeMachineId || this.aftMacId}/`,
          {
            day_shift_start: this.shiftDaysData?.previous_day?.start_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            this.shiftDaysData = dataList[0][0];
            let allShiftList = dataList?.slice(1);
            this.formatAllShiftLogs(allShiftList);
          }
          this.previousLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.previousLogsLoader = false;
        });
    },

    goForwardShift(showLoader) {
      this.allowEndpoint = true;
      this.allShiftLogsLoader = showLoader;
      Api()
        .post(
          `/machines/all-shift-logs/${this.routeMachineId || this.aftMacId}/`,
          {
            day_shift_start: this.shiftDaysData?.next_day?.start_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            this.shiftDaysData = dataList[0][0];
            let allShiftList = dataList?.slice(1);
            this.formatAllShiftLogs(allShiftList);
          }
          this.allShiftLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allShiftLogsLoader = false;
        });
    },

    getCurrentShift(showLoader) {
      this.allShiftLogsLoader = showLoader;
      Api()
        .post(
          `/machines/all-shift-logs/${this.routeMachineId || this.aftMacId}/`,
          {
            day_shift_start: this.shiftStartDateTime,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            this.shiftDaysData = dataList[0][0];
            let allShiftList = dataList?.slice(1);
            this.formatAllShiftLogs(allShiftList);
          }
          this.allShiftLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allShiftLogsLoader = false;
          this.errorCount++;
        });
    },

    goBack() {
      this.$router.go(-1);
    },

    showErrorAlert(errorMessage) {
      let msg = "Authentication credentials were not provided.";
      if (msg == errorMessage) {
        this.longAlertMsg = "Please log in to perform this action";
        this.isLongAlert = true;
        this.dialogFaults = false;
      } else {
        this.showAlert = true;
        this.alertColor = "#e76a6a";
        this.textColor = "#ffffff";
        this.alertMessage = errorMessage;
        this.isSuccess = false;
      }
      this.scrollToTop();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage =
        errorData?.messages?.[0]?.message ||
        errorData?.detail ||
        "An unexpected error occurred";

      this.showErrorAlert(errorMessage);
    },
  },

  mounted() {
    this.getAllShiftLogs(true);
  },

  created() {
    this.getMachineIdFromRoute();
    this.getMachineId();
  },

  watch: {
    allShiftsLang(val) {
      if (val) {
        this.formattedLang = checkLanguage(val);
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
