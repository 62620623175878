<template>
  <div class="h-screen w-full flex items-center justify-center">
    <div class="w-[370px]">
      <v-card ref="form" elevation="0">
        <v-form ref="adminForm" v-model="adminFormValid" lazy-validation>
          <div>
            <LoginAftIcon />
          </div>
          <v-card-text>
            <div>
              <div
                class="py-4 leading-7 text-center font-semibold text-2xl text-gray-900"
              >
                {{ $t("loginadminview.logInTo") }}
              </div>
              <div class="pb-4 text-center font-normal text-base text-gray-500">
                {{ $t("loginadminview.welcomeBackPlease") }}
              </div>
            </div>
            <v-label class="font-normal text-sm text-gray-700">
              {{ $t("loginadminview.username") }}
            </v-label>
            <v-text-field
              dense
              ref="name"
              v-model="name"
              :placeholder="$t('loginadminview.enterYourUsername')"
              outlined
              color="#667085"
              class="pt-1 rounded-lg"
            ></v-text-field>
            <v-label class="font-normal text-sm text-gray-700">
              {{ $t("loginadminview.password") }}
            </v-label>
            <v-text-field
              dense
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              outlined
              color="#98A2B3"
              @click:append="show1 = !show1"
              :rules="[customRule('password')]"
              class="pt-1 rounded-lg"
            ></v-text-field>
            <v-checkbox class="mt-0 dense hide-details" v-model="rememberMe">
              <template v-slot:label>
                <span class="text-gray-700 text-sm font-normal">{{
                  $t("loginadminview.rememberMe")
                }}</span>
              </template>
            </v-checkbox>
            <v-btn
              @click="loginHandler"
              class="mt-3 w-full text-sm font-normal rounded-lg"
              color="primary"
              depressed
              :loading="loginLoader"
            >
              {{ $t("loginadminview.logIn") }}
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </div>
    <div class="absolute top-5 right-5 z-50" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import {
  setAuthToken,
  getAuthToken,
  clearAuthToken,
  setRefreshToken,
} from "../../services/authentication";
import ApiNoToken from "../../services/apiNoToken";
import validation from "../../services/validation";
import { mapActions } from "vuex";
import AlertComponent from "../../components/AlertComponent.vue";
import LoginAftIcon from "../../assets/LoginAftIcon.vue";

export default {
  components: { AlertComponent, LoginAftIcon },
  data() {
    return {
      show1: false,
      name: null,
      formHasErrors: false,
      rememberMe: "",
      password: "",
      adminFormValid: true,
      ...validation,
      loginLoader: false,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },
  computed: {
    form() {
      return {
        name: this.name,
      };
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isLoggedIn() {
      return this.$store.state.authentication.isLoggedIn;
    },
  },

  methods: {
    loginHandler() {
      if (!this.$refs.adminForm.validate()) return;
      this.loginLoader = true;
      ApiNoToken()
        .post("/auth/admin-login/", {
          user_name: this.name,
          password: this.password,
        })
        .then((res) => {
          if (res.status === 200) {
            let saveData = res.data;
            setAuthToken(saveData.data.access);
            setRefreshToken(saveData.data.refresh);
            this.setCurrentUserInState(saveData);
            this.setIsLoggedIn(saveData.data.access);
            this.setIsAdmin(true);
            this.setUserMachineId(
              saveData.data.user?.machine_id
                ? saveData.data.user?.machine_id
                : null
            );

            this.$router.push("/");
            this.loginLoader = false;
          }
        })
        .catch((err) => {
          this.showError(err);
          this.loginLoader = false;
        });
    },

    checkAndRedirect() {
      if (this.isAdmin) {
        this.$router.push("/");
      } else if (this.isLoggedIn) {
        this.$router.push("/atl");
      } else {
        this.$router.push("/login-admin");
      }
    },

    removeToken() {
      if (getAuthToken()) {
        clearAuthToken();
      }
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
    },

    ...mapActions("authentication", [
      "setCurrentUserInState",
      "setIsLoggedIn",
      "setIsAdmin",
      "setUserMachineId",
    ]),
  },

  mounted() {
    /**  this.checkAndRedirect();*/
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}
</style>
