<template>
  <v-app class="">
    <the-navbar>
      <template #content>
        <div class="nav-links ml-16">
          <router-link to="/" class="nav-links-item">{{
            $t("aftdigital.home")
          }}</router-link>
          <router-link to="" class="nav-links-item">{{
            $t("aftdigital.machines")
          }}</router-link>
          <router-link to="" class="nav-links-item">{{
            $t("aftdigital.members")
          }}</router-link>
        </div>
        <div class="w-[18px] h-[16.67px]">
          <v-img :src="require(`../assets/bell-icon.svg`)"></v-img>
        </div>
      </template>
    </the-navbar>
    <v-main><slot></slot></v-main>
  </v-app>
</template>

<script>
import TheNavbar from "./TheNavbar.vue";
export default {
  components: {
    TheNavbar,
  },
};
</script>

<style scoped>
.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}

.nav-links-item {
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  padding: 8px 12px;
}

.nav-links-item:active,
.nav-links-item:hover {
  background: #f5f5ff;
  border-radius: 6px;
  color: #0408e7;
}

.nav-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  gap: 25px;
}

.profile-pic {
  border: 1px solid #667085;
  height: 40px;
  width: 40px;
  border-radius: 200px;
}
</style>
