<template>
  <div class="h-[100vh] w-full flex items-center justify-center">
    <div class="w-[370px]">
      <BackButton :to="{ name: 'LoginView' }" />
      <v-card elevation="0" ref="form">
        <LoginAftIcon />
        <v-card-text>
          <div class="flex justify-center items-center pt-14">
            <v-btn
              depressed
              height="50"
              color="primary"
              class="rounded-lg text-xl font-semibold"
              >{{ $t("loginworkerview.scanYour") }}</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import BackButton from "../assets/LoginBackIcon.vue";
import LoginAftIcon from "../assets/LoginAftIcon.vue";

export default {
  components: { BackButton, LoginAftIcon },
};
</script>

<style scoped>
* {
  text-transform: none;
}
</style>
