<template>
  <div class="h-screen w-full flex items-center justify-center">
    <div class="w-92">
      <v-card elevation="0">
        <div class="flex justify-center items-center">
          <div class="my-5">
            <v-overlay color="grey">
              <v-progress-circular
                indeterminate
                size="70"
                color="primary"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { logoutUser } from "../services/authentication";

export default {
  data() {
    return {
      routeMachineId: "",
      isFirstTime: true,
      reloaded: false,
    };
  },

  methods: {
    clearUserDataAndRedirect() {
      logoutUser();

      setTimeout(() => {
        this.$router.push("/login");
        location.reload();
      }, 1000);
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId;
    },
  },

  computed: {
    isManual() {
      return this.$store.state.authentication.currentUser?.data.user
        ?.machine_group;
    },
  },

  mounted() {
    this.clearUserDataAndRedirect();
    this.getMachineIdFromRoute();
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.sub-div {
  width: 370px;
}

.login-text {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  color: #101828;
}

.welcome-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  padding-top: 5px;
  border-radius: 8px !important;
}

* /deep/.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #667085;
}

.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input {
  caret-color: auto;
}

.checkbox-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remember-text {
  color: #344054;
  font-weight: 400;
  font-size: 14px;
}

.forgot-text {
  color: #0408e7;
}

.login-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 999;
}
</style>
