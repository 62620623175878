import i18n from "../../i18n";

export default {
  namespaced: true,
  state: {
    i18n,
  },
  mutations: {
    SET_LOCALE(state, data) {
      state.i18n.locale = data;
    },
  },
  actions: {
    setLocale({ commit }, data) {
      commit("SET_LOCALE", data);
    },
  },
};
