<template>
  <div class="h-[100vh] w-full flex items-center justify-center bg-[#fff]">
    <div class="sub-div">
      <v-card elevation="0">
        <div>
          <div class="w-[120px] h-[85px] mx-auto">
            <v-img :src="require(`../../assets/aft-logo.svg`)"></v-img>
          </div>
        </div>
      </v-card>
    </div>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
      />
    </div>
    <v-overlay :value="updateMachineGUIDLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import Api from "../../services/api";
import AlertComponent from "../../components/AlertComponent.vue";
export default {
  components: { AlertComponent },
  data() {
    return {
      currentTab: this.$router.history.current.fullPath,
      machineID: "",

      showAlert: false,
      updateMachineGUIDLoader: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },
  mounted() {
    this.machineID = this.returnMachineID(this.currentTab);
    this.updateMachineGUID(this.getGUID, this.machineID);
  },
  computed: {
    getGUID() {
      return this.$store.state.authentication.userGUID;
    },
  },
  methods: {
    returnMachineID(data) {
      let str = data;
      let digit = str.substr(str.lastIndexOf("/") + 1);
      return digit;
    },
    updateMachineGUID(guid, machineID, showLoader) {
      const payload = {
        guid: guid,
        machine_id: machineID,
      };
      this.updateMachineGUIDLoader = showLoader;
      Api()
        .put(`/machines/update-machine-guid/`, payload)
        .then((res) => {
          if (res.data.status === "success") {
            this.showAlert = true;
            this.alertColor = "#A6F4C5";
            this.textColor = "#054F31";
            this.alertMessage = res.data.detail;
            this.isSuccess = true;
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          }
          this.updateMachineGUIDLoader = false;
        })
        .catch((err) => {
          let errorData = err?.response?.data;
          let errorMessage = errorData?.messages
            ? errorData?.messages[0].message
            : errorData?.detail
            ? errorData?.detail
            : "An unexpected error occurred";
          this.showAlert = true;
          this.alertColor = "#e76a6a";
          this.textColor = "#ffffff";
          this.alertMessage = errorMessage;
          this.isSuccess = false;
          this.updateMachineGUIDLoader = false;
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
        });
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.sub-div {
  width: 370px;
}

.login-text {
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  color: #101828;
}

.welcome-text {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #667085;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  padding-top: 5px;
  border-radius: 8px !important;
}

* /deep/.theme--light.v-input input,
.theme--light.v-input textarea {
  color: #667085;
}

.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input {
  caret-color: auto;
}

.checkbox-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remember-text {
  color: #344054;
  font-weight: 400;
  font-size: 14px;
}

.forgot-text {
  color: #0408e7;
}

.login-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 999;
}
</style>
