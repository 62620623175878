<template>
  <div class="bg-[#ffffff]">
    <div class="w-[94%] flex justify-start pl-10">
      <div class="w-[10%] flex flex-col items-center mt-4 gap-2">
        <div>
          <!-- Edit Profile sidetab -->
          <router-link :to="{ name: 'adminEditProfile' }"
            ><div
              class="flex justify-center items-center text-[#667085] text-[14px] font-[400] w-[120px] h-[36px] rounded-[6px]"
            >
              {{ $t("passwordview.editProfile") }}
            </div></router-link
          >
        </div>
        <div>
          <!-- Change Password sidetab -->
          <router-link :to="{ name: 'adminChangePassword' }"
            ><div
              class="flex justify-center items-center text-[#667085] text-[14px] font-[400] w-[120px] h-[36px] rounded-[6px]"
              :class="
                currentTab.includes('/admin/change-password')
                  ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                  : ''
              "
            >
              {{ $t("passwordview.password") }}
            </div></router-link
          >
        </div>
      </div>
      <div class="w-[40%] mt-1">
        <v-card ref="form" elevation="0">
          <!-- Change Password form -->
          <v-form
            ref="changePasswordForm"
            v-model="changePasswordFormValid"
            lazy-validation
          >
            <v-card-text>
              <div class="first-text pb-4">
                {{ $t("passwordview.password") }}
              </div>
              <div class="pb-4">{{ $t("passwordview.pleaseEnterYour") }}</div>
              <v-divider class="pb-4"></v-divider>
              <v-label>{{ $t("passwordview.oldPassword") }}</v-label>
              <v-text-field
                dense
                v-model="passwordOne"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                nameOne="input-10-1"
                outlined
                color="#98A2B3"
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-label>{{ $t("passwordview.newPassword") }}</v-label>
              <v-text-field
                dense
                v-model="passwordTwo"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                nameTwo="input-10-1"
                outlined
                color="#98A2B3"
                @click:append="show2 = !show2"
              ></v-text-field>
              <v-label>{{ $t("passwordview.confirmNewPassword") }}</v-label>
              <v-text-field
                dense
                v-model="passwordThree"
                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show3 ? 'text' : 'password'"
                nameThree="input-10-1"
                outlined
                color="#98A2B3"
                @click:append="show3 = !show3"
                :rules="[
                  passwordMatch(passwordTwo, passwordThree, passwordViewLang),
                ]"
              ></v-text-field>
              <v-divider class="pb-4"></v-divider>
              <div class="flex justify-end gap-5">
                <v-btn
                  elevation="0"
                  outlined
                  class="cancel-btn"
                  depressed
                  @click="goToHome()"
                  >{{ $t("passwordview.cancel") }}</v-btn
                >
                <v-btn
                  class="update-btn"
                  color="primary"
                  depressed
                  :loading="changePasswordLoader"
                  @click="changePasswordHandler"
                  >{{ $t("passwordview.updatePassword") }}</v-btn
                >
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </div>
      <div class="position-alert" v-if="showAlert">
        <AlertComponent
          :alertColor="alertColor"
          :textColor="textColor"
          :alertMessage="alertMessage"
          :isSuccess="isSuccess"
          @hide-alert="hideAlert"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import AlertComponent from "../../components/AlertComponent.vue";
import validation from "../../services/validation";

export default {
  components: { AlertComponent },
  data() {
    return {
      currentTab: this.$router.history.current.fullPath,
      passwordOne: "",
      passwordTwo: "",
      passwordThree: "",
      nameOne: this.nameOne,
      nameTwo: this.nameTwo,
      nameThree: this.nameThree,
      show1: false,
      show2: false,
      show3: false,
      changePasswordFormValid: true,
      changePasswordLoader: false,
      ...validation,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },

  computed: {
    passwordViewLang() {
      return this.$store.state.translation.i18n.locale;
    },

    currentUser() {
      return this.$store.state.authentication.currentUser.data.user;
    },

    isNotEmpty() {
      return this.passwordOne && this.passwordTwo && this.passwordThree;
    },

    doesPasswordMatch() {
      return (
        this.passwordTwo == "" ||
        this.passwordThree == "" ||
        this.passwordTwo == this.passwordThree
      );
    },

    isMismatchPassword() {
      return this.passwordTwo != this.passwordThree;
    },
  },

  methods: {
    changePasswordHandler() {
      /**  if (!this.doesPasswordMatch || !this.isNotEmpty) return; */
      if (this.checkPasswordMismatch()) return;
      this.changePasswordLoader = true;
      Api()
        .put("/auth/change-password/", {
          password: this.passwordOne,
          new_password: this.passwordThree,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.resetData();
          }
          this.changePasswordLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.changePasswordLoader = false;
        });
    },

    resetData() {
      this.passwordOne = "";
      this.passwordTwo = "";
      this.passwordThree = "";
    },

    goToHome() {
      this.$router.push("/");
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
    },

    checkPasswordMismatch() {
      if (this.isMismatchPassword) {
        this.alertPasswordMismatch();
        return true;
      } else {
        return false;
      }
    },

    alertPasswordMismatch() {
      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = "New passwords should match";
      this.isSuccess = false;
    },
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.first-text {
  font-size: 18px;
  color: #101828;
  font-weight: 600;
}

.second-text {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.theme--light.v-divider {
  color: #eaecf0;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  padding-top: 5px;
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.update-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
