<template>
  <div class="py-5 px-13 mr-4 bg-[#fff]">
    <div
      v-if="machineStatus == 'Stopped' && !machinesLogsLoader"
      class="w-[100%] pb-4"
    >
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.machineHasStoppedWorking") }}
        </div>
      </div>
    </div>
    <div v-if="isLongAlert && !isEmployeeBreak" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ longAlertMsg }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Employee Break'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.workerIsOnBreak") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Planned Stop'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.plannedStop") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Cleaning'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.clean") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Prepare'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.prepare") }}
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start gap-11">
        <div class="flex items-center justify-center gap-7">
          <div class="text-[#344054] text-[24px] font-[700]">
            {{ machineName }}
          </div>
          <div
            v-if="machineStatus == 'Automatic'"
            class="flex justify-center items-center rounded-[16px] bg-[#ECFDF3] w-[99px] h-[28px] text-[#027A48] text-[14px] font-[600]"
          >
            {{ $t("adminshlview.production") }}
          </div>
          <div
            v-if="machineStatus == 'Planned Stop'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[220px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.plannedStop") }}
          </div>
          <div
            v-if="machineStatus == 'Cleaning'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.clean") }}
          </div>
          <div
            v-if="machineStatus == 'Prepare'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.prepare") }}
          </div>
          <div
            v-if="machineStatus == 'Employee Break'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.break") }}
          </div>
          <div
            v-if="machineStatus == 'Stopped'"
            class="flex justify-center items-center rounded-[16px] bg-[#F63D68] w-[145px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("adminshlview.stopped") }}
          </div>
        </div>
        <div class="flex justify-center gap-8">
          <div>
            <!-- Automatic labeling tab -->
            <router-link :to="{ name: 'adminManualAtl' }">
              <div
                class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] rounded-[6px]"
              >
                {{ $t("adminshlview.automaticLabeling") }}
              </div>
            </router-link>
          </div>
          <div>
            <!-- Shiftlog tab  -->
            <router-link :to="{ name: 'adminManualShl' }">
              <div
                class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] bg-[#F5F5FF] rounded-[6px]"
                :class="
                  currentTab.includes('/admin-manual-shl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("adminshlview.shiftlog") }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <!-- View all shifts tab  -->
        <router-link :to="{ name: 'allShifts' }"
          ><div class="text-[#344054] font-[400] text-[16px]">
            {{ $t("adminshlview.viewAllShifts") }}
          </div></router-link
        >
      </div>
    </div>
    <div class="flex justify-between gap-5 mt-5">
      <div class="w-[70%]">
        <div class="flex justify-between pb-2">
          <div class="flex items-center gap-2 pl-4">
            <div class="text-[#667085] font-[400] text-[12px]">
              {{ $t("shlview.assignments") }}
            </div>
            <div class="text-[#101828] font-[600] text-[16px]">
              {{
                currentOrder[0]?.production_order_number
                  ? currentOrder[0]?.production_order_number
                  : $t("shlview.noOrder")
              }}
            </div>
          </div>
          <div class="flex items-center gap-2">
            <div class="text-[#101828] font-[400] text-[16px]">
              {{ $t("shlview.currentsetpoint") }}
            </div>
            <div class="text-[#101828] font-[600] text-[16px] pr-2">
              {{ current }}/{{ setPoint }}
            </div>
          </div>
        </div>
        <!-- Faults table  -->
        <v-data-table
          dense
          :headers="
            shlViewLang === 'en'
              ? headers
              : shlViewLang === 'de'
              ? deHeaders
              : shlViewLang === 'cn'
              ? cnHeaders
              : shlViewLang === 'ro'
              ? roHeaders
              : shlViewLang === 'ba'
              ? baHeaders
              : shlViewLang === 'es'
              ? esHeaders
              : rsHeaders
          "
          :items="formattedFaultLogs"
          item-key="id"
          class="mb-4 border-2"
          :hide-default-footer="true"
          :no-data-text="$t('shlview.nodata')"
        >
          <template v-slot:[`item.faults`]="{ item }"
            ><div class="text-[#101828] text-[17px] font-[400]">
              {{ item[`reason_type__${formattedLang}`] }}
            </div></template
          >
          <template v-slot:[`item.info`]="{ item }">
            <div class="flex items-center justify-end gap-3 mr-6">
              <div class="w-[13.33px] h-[15px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item.info.quantity }}
              </div>
              <div class="w-[16.67px] h-[16.67px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item.info.time }}
              </div>
            </div>
          </template>
        </v-data-table>

        <!-- NIO table  -->
        <v-data-table
          dense
          :headers="
            shlViewLang === 'en'
              ? headersTwo
              : shlViewLang === 'de'
              ? deHeadersTwo
              : shlViewLang === 'cn'
              ? cnHeadersTwo
              : shlViewLang === 'ro'
              ? roHeadersTwo
              : shlViewLang === 'ba'
              ? baHeadersTwo
              : shlViewLang === 'es'
              ? esHeadersTwo
              : rsHeadersTwo
          "
          :items="formattedNioLogs"
          item-key="id"
          class="border-2"
          :hide-default-footer="true"
          :no-data-text="$t('shlview.nodata')"
        >
          <template v-slot:[`item.nio`]="{ item }">
            <div class="text-[#101828] text-[17px] font-[400]">
              {{ item[`reason_type__${formattedLang}`] }}
            </div>
          </template>
          <template v-slot:[`item.infoTwo`]="{ item }">
            <div class="flex items-center justify-end gap-3 mr-6">
              <div class="w-[16.67px] h-[16.67px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item.infoTwo.quantity }}
              </div>
            </div>
          </template>
        </v-data-table>

        <div class="pt-5">
          <!-- Comments table  -->
          <v-data-table
            dense
            :headers="
              shlViewLang === 'en'
                ? headersThree
                : shlViewLang === 'de'
                ? deHeadersThree
                : shlViewLang === 'cn'
                ? cnHeadersThree
                : shlViewLang === 'ro'
                ? roHeadersThree
                : shlViewLang === 'ba'
                ? baHeadersThree
                : shlViewLang === 'es'
                ? esHeadersThree
                : rsHeadersThree
            "
            :items="formattedCommentLogs"
            item-key="id"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('shlview.nodata')"
          >
            <template v-slot:[`item.comments`]="{ item }">
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item[`reason_type__${formattedLang}`] }}
              </div>
            </template>
            <template v-slot:[`item.comment`]="{ item }">
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item.comment }}
              </div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <div class="text-[#101828] text-[17px] font-[400]">
                {{ item.date }}
              </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div class="flex justify-end pr-14">
                <div class="text-[#101828] text-[17px] font-[400]">
                  {{ item.name }}
                </div>
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
      <!-- Div with start break button  -->
      <div
        class="border-2 rounded-[8px] w-[30%] h-[1200px] bg-[#fff] border-[#EAECF0] flex flex-col p-5 mt-8"
      >
        <div class="flex justify-end pb-[20px]">
          <div>
            <v-btn
              v-if="!isEmployeeBreak"
              depressed
              color="#ffffff"
              width="200"
              height="44"
              class="flex items-center start-break-btn justify-evenly"
              :loading="startBreakLoader"
              :disabled="!isCurrentShift"
              @click="startBreak()"
            >
              <div class="w-[16.67px] h-[16.67px]">
                <v-img
                  :src="require('../../assets/break-clock-icon.svg')"
                ></v-img>
              </div>
              <div>{{ $t("shlview.startBreak") }}</div>
            </v-btn>

            <v-btn
              v-if="isEmployeeBreak"
              depressed
              color="#32d583"
              width="240"
              height="44"
              class="flex items-center start-break-btn justify-evenly"
              :loading="stopBreakLoader"
              :disabled="!isCurrentShift"
              @click="stopBreak()"
            >
              <div class="w-[16.67px] h-[16.67px}">
                <v-img
                  :src="require('../../assets/break-clock-icon-white.svg')"
                ></v-img>
              </div>
              <div class="text-[#ffffff]">{{ $t("shlview.stopBreak") }}</div>
            </v-btn>
          </div>
        </div>

        <div class="border-b-[1px]" id="modalTop">
          <div v-if="isLoggedIn" class="flex justify-start gap-1 mb-1">
            <div class="text-[#1D2939] text-[14px] font-[400]">
              {{ formatShiftDate(currentDate) }}
            </div>
            <div class="text-[#1D2939] text-[14px] font-[600]">
              {{ formatTime(currentShiftStartDate) }} -
              {{ formatTime(currentShiftEndDate) }}
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex items-center justify-center">
              <v-btn
                min-width="0"
                min-height="0"
                width="40"
                height="40"
                depressed
                color="#EAECF0"
                class="flex items-center justify-center rounded-[8px] mr-[15px]"
                v-show="isTechnician"
                @click="goBackwardShift(true)"
              >
                <div class="w-[5px] h-[10px]">
                  <v-img
                    :src="require(`../../assets/chevron-left.svg`)"
                  ></v-img>
                </div>
              </v-btn>
              <div class="font-bold">
                {{ formatFullName(fullName, 25) }}
              </div>
            </div>
            <div>
              <v-btn
                min-width="0"
                min-height="0"
                width="40"
                height="40"
                depressed
                color="#EAECF0"
                class="!w-[40px] !h-[40px] flex items-center justify-center rounded-[8px] bg-[#EAECF0]"
                :disabled="isDisableNextButton"
                v-show="isTechnician"
                @click="goForwardShift(true)"
              >
                <div class="w-[5px] h-[10px]">
                  <v-img
                    :src="require(`../../assets/chevron-right.svg`)"
                  ></v-img>
                </div>
              </v-btn>
            </div>
          </div>
          <div class="flex flex-col py-5">
            <div class="mb-1 text-[#101828] text-[14px] font-[400]">
              {{ $t("shlview.plantStatus") }}
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.automaticMode") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.automatic_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.atomatic_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.stopCount") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.stop_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.stop_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.plannedStop") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.planned_stop_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.planned_stop_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.clean") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.cleaning_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.cleaning_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="mb-1 text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.prepare") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.prepare_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.prepare_duration }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isEmployeeBreak">
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="flex justify-start gap-3">
              <div class="font-medium text-[#344054]">
                {{ $t("manualworkershlview.okayParts") }}
              </div>
              <div
                class="flex justify-center items-center bg-[#F0F9FF] text-[#026AA2] text-[16px] font-[400] rounded-[16px] w-[51px] h-[28px]"
              >
                <div>{{ currentOkayPart }}</div>
              </div>
            </div>
            <v-dialog v-model="dialogOkayParts" width="455" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="0"
                  min-height="0"
                  width="36"
                  height="36"
                  color="#C3C4FE"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isCurrentShift"
                  class="rounded-[8px] flex justify-center items-center"
                >
                  <div class="w-[11.67px] h-[11.67px]">
                    <v-img :src="require(`../../assets/plus-icon.svg`)"></v-img>
                  </div>
                </v-btn>
              </template>

              <v-card>
                <div class="flex justify-center">
                  <div>
                    <v-card-title
                      ><div class="mx-auto">
                        <div class="text-[18px] text-[#101828] font-[600]">
                          {{ $t("manualworkershlview.okayParts") }}
                        </div>
                      </div></v-card-title
                    >
                    <v-card-text>
                      <div class="flex justify-start">
                        <div class="w-[150px]">
                          <v-label>{{
                            $t("manualworkershlview.quantity")
                          }}</v-label>
                          <v-text-field
                            class="w-[149px] h-[44px]"
                            placeholder="0"
                            dense
                            outlined
                            single-line
                            v-model="okayPartsQuantity"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="my-9"></div>
                      <div class="flex justify-start gap-2">
                        <div>
                          <v-btn
                            class="faults-cancel-btn"
                            color="#ffffff"
                            depressed
                            width="174"
                            height="44"
                            @click="dialogOkayParts = false"
                            >{{ $t("shlview.cancel") }}</v-btn
                          >
                        </div>
                        <div>
                          <v-btn
                            class="faults-save-btn"
                            color="primary"
                            depressed
                            width="174"
                            height="44"
                            :loading="addOkayPartsLoader"
                            @click="addOkayParts"
                            >{{ $t("shlview.save") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                </div>
              </v-card>
            </v-dialog>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.faults") }}
            </div>
            <v-dialog v-model="dialogFaults" width="455" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="0"
                  min-height="0"
                  width="36"
                  height="36"
                  color="#C3C4FE"
                  depressed
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!isCurrentShift"
                  class="rounded-[8px] flex justify-center items-center"
                >
                  <div class="w-[11.67px] h-[11.67px]">
                    <v-img :src="require(`../../assets/plus-icon.svg`)"></v-img>
                  </div>
                </v-btn>
              </template>
              <v-card>
                <v-card-title
                  ><div class="mx-auto">
                    <div class="text-[18px] text-[#101828] font-[600]">
                      {{ $t("shlview.faults") }}
                    </div>
                  </div></v-card-title
                >
                <v-card-text>
                  <div class="select-reason">
                    <v-label>{{ $t("shlview.selectReason") }}</v-label>
                  </div>
                  <v-select
                    class="w-[360px] h-[48px]"
                    :placeholder="$t('shlview.waitingForMachineRepair')"
                    :items="faultReasons"
                    :item-text="formattedLang"
                    item-value="id"
                    v-model="selectedFaultId"
                    dense
                    outlined
                  ></v-select>
                  <div class="flex justify-start gap-2 pt-4">
                    <div class="w-[185px]">
                      <v-label>{{ $t("shlview.start") }}</v-label>
                      <v-text-field
                        v-model="dateTimeStart"
                        class="w-[184px] h-[44px]"
                        placeholder="12-09-22 08:34"
                        dense
                        outlined
                        append-icon="$vuetify.icons.calendar_icon"
                        @click:append="showStartDateTimeModal"
                        readonly
                        @click="showStartDateTimeModal"
                      ></v-text-field>
                    </div>
                    <div class="w-[185px]" v-if="faultsStopTime">
                      <v-label>{{ $t("shlview.stop") }}</v-label>
                      <v-text-field
                        v-model="dateTimeStop"
                        class="w-[184px] h-[44px]"
                        placeholder="12-09-22 11:34"
                        dense
                        outlined
                        append-icon="$vuetify.icons.calendar_icon"
                        @click:append="showStopDateTimeModal"
                        readonly
                        @click="showStopDateTimeModal"
                      ></v-text-field>
                    </div>
                  </div>
                  <div>
                    <v-checkbox v-model="faultsStopTime"
                      ><template v-slot:label
                        ><span class="text-[#344054] text-[14px] font-[400]">{{
                          $t("shlview.addStopTime")
                        }}</span></template
                      ></v-checkbox
                    >
                  </div>
                  <div class="flex justify-center gap-2">
                    <div>
                      <v-btn
                        class="faults-cancel-btn"
                        color="#ffffff"
                        depressed
                        width="197"
                        height="44"
                        @click="dialogFaults = false"
                        >{{ $t("shlview.cancel") }}</v-btn
                      >
                    </div>
                    <div>
                      <v-btn
                        class="faults-save-btn"
                        color="primary"
                        depressed
                        width="197"
                        height="44"
                        :loading="addFaultLoader"
                        @click="addMachineFault"
                        >{{ $t("shlview.save") }}</v-btn
                      >
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.nio") }}
            </div>
            <div>
              <v-dialog v-model="dialogNIO" width="455">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    min-width="0"
                    min-height="0"
                    width="36"
                    height="36"
                    color="#C3C4FE"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!isCurrentShift"
                    depressed
                    class="rounded-[8px] flex justify-center items-center"
                  >
                    <div class="w-[11.67px] h-[11.67px]">
                      <v-img
                        :src="require(`../../assets/plus-icon.svg`)"
                      ></v-img>
                    </div>
                  </v-btn>
                </template>

                <v-card>
                  <div class="flex justify-center">
                    <div>
                      <v-card-title
                        ><div class="mx-auto">
                          <div class="text-[18px] text-[#101828] font-[600]">
                            {{ $t("shlview.nio") }}
                          </div>
                        </div></v-card-title
                      >
                      <v-card-text>
                        <div class="select-reason">
                          <v-label>{{ $t("shlview.selectReason") }}</v-label>
                        </div>
                        <v-select
                          class="w-[360px] h-[48px]"
                          :placeholder="$t('shlview.brokenCase')"
                          :items="nioReasons"
                          :item-text="formattedLang"
                          item-value="id"
                          v-model="selectedNioId"
                          dense
                          outlined
                        ></v-select>
                        <div class="flex justify-start gap-2 pt-4">
                          <div class="w-[185px]">
                            <v-label>{{ $t("shlview.date") }}</v-label>
                            <v-text-field
                              v-model="dateTimeStart"
                              class="w-[184px] h-[44px]"
                              placeholder="12-09-22 11:34"
                              dense
                              outlined
                              append-icon="$vuetify.icons.calendar_icon"
                              @click:append="showStartDateTimeModal"
                              readonly
                              @click="showStartDateTimeModal"
                            ></v-text-field>
                          </div>
                          <div class="w-[150px]">
                            <v-label>{{ $t("shlview.quantity") }}</v-label>
                            <v-text-field
                              class="w-[149px] h-[44px]"
                              v-model="nioQuantity"
                              placeholder="0"
                              dense
                              outlined
                              single-line
                              :rules="[nonNegativeNumber(shlViewLang)]"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="my-9"></div>
                        <div class="flex justify-start gap-2">
                          <div>
                            <v-btn
                              class="NIO-cancel-btn"
                              color="#ffffff"
                              depressed
                              width="174"
                              height="44"
                              @click="dialogNIO = false"
                              >{{ $t("shlview.cancel") }}</v-btn
                            >
                          </div>
                          <div>
                            <v-btn
                              class="NIO-save-btn"
                              color="primary"
                              depressed
                              width="174"
                              height="44"
                              :loading="addNioLoader"
                              @click="addNioLog"
                              >{{ $t("shlview.save") }}</v-btn
                            >
                          </div>
                        </div>
                      </v-card-text>
                    </div>
                  </div>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.comments") }}
            </div>
            <div>
              <v-dialog v-model="dialogComments" width="455">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    min-width="0"
                    min-height="0"
                    width="36"
                    height="36"
                    color="#C3C4FE"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!isCurrentShift"
                    depressed
                    class="rounded-[8px] flex justify-center items-center"
                  >
                    <div class="w-[11.67px] h-[11.67px]">
                      <v-img
                        :src="require(`../../assets/plus-icon.svg`)"
                      ></v-img>
                    </div>
                  </v-btn>
                </template>
                <v-card>
                  <v-form ref="commentForm">
                    <v-card-title
                      ><div class="mx-auto">
                        <div class="text-[18px] text-[#101828] font-[600]">
                          {{ $t("shlview.comments") }}
                        </div>
                      </div></v-card-title
                    >
                    <v-card-text>
                      <div class="select-reason">
                        <v-label>{{ $t("shlview.selectReason") }}</v-label>
                      </div>
                      <v-select
                        class="w-[360px] h-[48px]"
                        :placeholder="$t('shlview.shiftHandoverRemark')"
                        :items="commentReasons"
                        :item-text="formattedLang"
                        item-value="id"
                        v-model="selectedCommentId"
                        dense
                        outlined
                      ></v-select>
                      <div class="flex justify-start gap-2 pt-4">
                        <div class="w-[355px]">
                          <v-label>{{ $t("shlview.description") }}</v-label>
                          <div class="pb-0">
                            <v-textarea
                              :placeholder="$t('shlview.enterADescription')"
                              v-model="commentDescription"
                              auto-grow
                              outlined
                              rows="2"
                              class="w-[354px]"
                            ></v-textarea>
                          </div>
                        </div>
                      </div>
                      <div class="flex justify-start gap-2">
                        <div>
                          <v-btn
                            class="comments-cancel-btn"
                            color="#ffffff"
                            depressed
                            width="174"
                            height="44"
                            @click="dialogComments = false"
                            >{{ $t("shlview.cancel") }}</v-btn
                          >
                        </div>
                        <div>
                          <v-btn
                            class="comments-save-btn"
                            color="primary"
                            depressed
                            width="174"
                            height="44"
                            :loading="commentLoader"
                            @click="addComment"
                            >{{ $t("shlview.save") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("manualworkershlview.unplannedStop") }}
            </div>
            <div>
              <v-switch
                :class="isUnplannedStop ? 'switch-control switch' : 'switch'"
                v-model="isUnplannedStop"
                @change="unPlannedStopMachine(isUnplannedStop)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("manualworkershlview.production") }}
            </div>
            <div>
              <v-switch
                :class="isProduction ? 'switch-control switch' : 'switch'"
                v-model="isProduction"
                @change="productionMachine(isProduction)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.plannedStop") }}
            </div>
            <div>
              <v-switch
                :class="isPlannedStop ? 'switch-control switch' : 'switch'"
                v-model="isPlannedStop"
                @change="plannedStopMachine(isPlannedStop)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.prepare") }}
            </div>
            <div>
              <v-switch
                :class="isPrepare ? 'switch-control switch' : 'switch'"
                v-model="isPrepare"
                @change="prepareMachine(isPrepare)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div class="py-[22px] flex justify-between items-center">
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.clean") }}
            </div>
            <div>
              <v-switch
                :class="isCleaning ? 'switch-control switch' : 'switch'"
                v-model="isCleaning"
                @change="cleanMachine(isCleaning)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
        </div>
        <div v-else>
          <v-img
            :src="require(`../../assets/break-time-image.svg`)"
            :lazy-src="require(`../../assets/break-time-image.svg`)"
          ></v-img>
        </div>
      </div>
    </div>
    <StartDateTimeModal
      :dateTimeStart="dateTimeStart"
      @save-startDateTime="saveStartDateTime"
      @cancel-startDateTime="cancelStartDateTime"
      ref="StartDateTimeRef"
    />
    <StopDateTimeModal
      :dateTimeStop="dateTimeStop"
      @save-stopDateTime="saveStopDateTime"
      @cancel-stopDateTime="cancelStopDateTime"
      ref="StopDateTimeRef"
    />
    <v-overlay :value="machinesLogsLoader || previousLogsLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import ApiNoToken from "../../services/apiNoToken";
import StartDateTimeModal from "../../components/StartDateTimeModal.vue";
import StopDateTimeModal from "../../components/StopDateTimeModal.vue";
import { format, parseISO } from "date-fns";
import { mapActions } from "vuex";
import { actionLogTypes, checkLanguage } from "../../services/utils";
import AlertComponent from "../../components/AlertComponent.vue";
import moment from "moment";
import validation from "../../services/validation";

export default {
  components: { StartDateTimeModal, StopDateTimeModal, AlertComponent },
  data() {
    return {
      currentTab: this.$router.history.current.fullPath,
      currentDate: moment().format() || "2002-01-12T09:07:02",
      dialogOkayParts: false,
      dialogFaults: false,
      dialogNIO: false,
      dialogComments: false,
      faultsStopTime: false,
      NIOStopTime: false,
      ...validation,
      headers: [
        {
          text: "Faults",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      deHeaders: [
        {
          text: "Störungen",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      esHeaders: [
        {
          text: "Fallos",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      roHeaders: [
        {
          text: "Defecțiuni",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      cnHeaders: [
        {
          text: "缺陷",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      baHeaders: [
        {
          text: "Greške",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      rsHeaders: [
        {
          text: "Грешке",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      faults: [
        {
          faults: "Plant 1 Error Msg",
          info: "",
        },
        {
          faults: "Plant 1 Error Msg",
          info: "",
        },
      ],
      headersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      deHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      esHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      roHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      cnHeadersTwo: [
        {
          text: "蔚来",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      baHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      rsHeadersTwo: [
        {
          text: "НИО",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      nio: [
        {
          nio: "Plant 1 Error Msg",
          infoTwo: "",
        },
      ],
      headersThree: [
        {
          text: "Comments",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      deHeadersThree: [
        {
          text: "Kommentare",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      esHeadersThree: [
        {
          text: "Comentarios",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      cnHeadersThree: [
        {
          text: "评论",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      roHeadersThree: [
        {
          text: "Comentarii",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      baHeadersThree: [
        {
          text: "Komentari",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      rsHeadersThree: [
        {
          text: "Коментари",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      comments: [
        {
          comments: "Handover",
          comment: "comment",
          date: "18/09/22",
          name: "nameOne",
        },
      ],

      currentOkayPart: 0,
      addOkayPartsLoader: false,
      okayPartsQuantity: "",
      previousShift: null,
      currentShift: null,
      nextShift: null,
      plantStatus: null,
      faultLogs: [],
      nioLogs: [],
      commentLogs: [],
      machinesLogsLoader: false,
      addFaultLoader: false,
      addNioLoader: false,
      nioQuantity: "",
      commentLoader: false,
      commentDescription: "",

      dateTimeStart:
        moment().format().slice(0, 10) + " " + moment().format("HH:mm"),
      dateTimeStop: "",
      formattedFaultLogs: [],
      formattedNioLogs: [],
      formattedCommentLogs: [],

      commentReasons: [],
      faultReasons: [],
      nioReasons: [],
      selectedCommentId: "",
      selectedFaultId: "",
      selectedNioId: "",
      isCleaning: false,
      isEmployeeBreak: false,
      isUnplannedStop: false,
      isProduction: false,
      isPlannedStop: false,
      isPrepare: false,
      showLoader: false,

      longAlertMsg: "",
      isLongAlert: false,
      setPoint: "",
      current: "",
      currentOrder: "",
      startBreakLoader: false,
      stopBreakLoader: false,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
      aftMacId: "",

      showPreviousShift: false,
      showCurrentShift: true,
      showNextShift: false,

      routeMachineId: "",
      // dynamicMachineId: "4", //temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
      timer: null,

      errorCount: 0,
      mainCurrentShift: null,
      allowEndpoint: true,
      previousLogsLoader: false,
      defaultMachineRole: "Worker",
      formattedLang: "",
    };
  },
  methods: {
    // get Machine logs
    getAllMachinesLogs(showLoader) {
      clearInterval(this.timer);
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .get(
          `/machines/current-shift-logs/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
              this.getMainCurrentShiftData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
          clearInterval(this.timer);
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
          clearInterval(this.timer);
        })
        .finally(() => {
          this.timer = setInterval(() => {
            if (this.errorCount < 10) {
              if (this.allowEndpoint) {
                this.getCurrentShift(false);
              }
            } else {
              clearInterval(this.timer);
            }
          }, 5000);
        });
    },

    structureData(data) {
      if (Object.hasOwnProperty.call(data, "cleaning" || "prepare")) {
        this.isCleaning = data.cleaning;
        this.isEmployeeBreak = data.employee_break;
        this.isPlannedStop = data.planned_stop;
        this.isPrepare = data.prepare;
      } else if (
        Object.hasOwnProperty.call(data, "previous_shift_start_date")
      ) {
        this.previousShift = data;
      } else if (Object.hasOwnProperty.call(data, "current_shift_start_date")) {
        this.currentShift = data;
      } else if (Object.hasOwnProperty.call(data, "next_shift_start_date")) {
        this.nextShift = data;
      } else if (Object.hasOwnProperty.call(data, "planned_stop_count")) {
        this.plantStatus = data;
      } else if (Object.hasOwnProperty.call(data, "fault_logs")) {
        this.faultLogs = [...data.fault_logs];
      } else if (Object.hasOwnProperty.call(data, "nio_logs")) {
        this.nioLogs = [...data.nio_logs];
      } else if (Object.hasOwnProperty.call(data, "comment_logs")) {
        this.commentLogs = [...data.comment_logs];
      } else if (Object.hasOwnProperty.call(data, "current_order")) {
        this.currentOrder = [...data.current_order];
      } else if (Object.hasOwnProperty.call(data, "machine_status")) {
        this.setPoint = data.set_point;
        this.currentOkayPart = data.currrent;
        this.setMachineStatus(data.machine_status);
        this.setMachineName(data.machine_name);
        this.current = data.currrent;
      }
    },

    // Adding an okay part
    addOkayParts() {
      this.addOkayPartsLoader = true;
      let newData = {
        quantity: parseInt(this.okayPartsQuantity, 10),
        shift_start_date:
          this.currentShift?.current_shift_start_date || "2022-01-12T08:07:02",
        shift_end_date:
          this.currentShift?.current_shift_end_date || "2022-01-12T09:07:02",
      };
      Api()
        .post(
          `/machines/add-manual-okay-parts/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
          }
          this.addOkayPartsLoader = false;
          this.dialogOkayParts = false;
          this.getAllMachinesLogs(false);
          this.okayPartsQuantity = "";
        })
        .catch((err) => {
          this.showError(err);
          this.addOkayPartsLoader = false;
        });
    },

    // Adding a fault
    addMachineFault() {
      this.addFaultLoader = true;
      let newData = {
        reason_type: this.selectedFaultId,
        start_time: this.dateTimeStart ? this.dateTimeStart + ":00" : null,
        end_time: this.dateTimeStop ? this.dateTimeStop + ":00" : null,
      };
      Api()
        .post(
          `/machines/fault-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.setCurrentShiftState();
          }
          this.dialogFaults = false;
          this.addFaultLoader = false;
          this.getCurrentShift(false);
          this.resetFaultData();
        })
        .catch((err) => {
          this.showError(err);
          this.addFaultLoader = false;
        });
    },

    addToFaultList(fault) {
      fault = this.addFaultFeatures(fault);
      this.formattedFaultLogs.unshift(fault);
    },

    formatFaultLogs(faultList) {
      return faultList.map((faultItem) => {
        faultItem = this.addFaultFeatures(faultItem);
        return faultItem;
      });
    },

    addFaultFeatures(faultItem) {
      faultItem.info = {
        time: faultItem.duration,
        quantity: faultItem.fault_count,
      };
      faultItem.faults = faultItem.reason_type__name;
      return faultItem;
    },

    resetFaultData() {
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
      this.dateTimeStop = "";
      this.selectedFaultId = "";
      this.faultsStopTime = true;
    },

    // Adding a NIO
    addNioLog() {
      this.addNioLoader = true;
      let newData = {
        reason_type: this.selectedNioId,
        quantity: this.nioQuantity,
        event_time: this.dateTimeStart ? this.dateTimeStart + ":00" : null,
      };
      Api()
        .post(
          `/machines/nio-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.setCurrentShiftState();
          }
          this.dialogNIO = false;
          this.addNioLoader = false;
          this.getCurrentShift(false);
          this.resetNioData();
        })
        .catch((err) => {
          this.showError(err);
          this.addNioLoader = false;
        });
    },

    addToNioList(nio) {
      nio = this.addNioFeatures(nio);
      this.formattedNioLogs.unshift(nio);
    },

    formatNioLogs(nioList) {
      return nioList.map((nioItem) => {
        nioItem = this.addNioFeatures(nioItem);
        return nioItem;
      });
    },

    addNioFeatures(nioItem) {
      nioItem.infoTwo = {
        counter: "1",
        quantity: nioItem.quantity,
      };
      nioItem.nio = nioItem.reason_type__name;
      return nioItem;
    },

    resetNioData() {
      this.nioQuantity = "";
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
      this.selectedNioId = "";
    },

    // Adding a comment
    addComment() {
      this.commentLoader = true;
      let newData = {
        reason_type: this.selectedCommentId,
        description: this.commentDescription,
      };
      Api()
        .post(
          `/machines/comment-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          newData
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.setCurrentShiftState();
          }
          this.dialogComments = false;
          this.commentLoader = false;
          this.getCurrentShift(false);
          this.resetCommentData();
        })
        .catch((err) => {
          this.showError(err);
          this.commentLoader = false;
        });
    },

    addToCommentList(comment) {
      comment = this.addCommentFeatures(comment);
      this.formattedCommentLogs.unshift(comment);
    },

    formatCommentLogs(commentList) {
      return commentList.map((commentItem) => {
        commentItem = this.addCommentFeatures(commentItem);
        return commentItem;
      });
    },

    addCommentFeatures(commentItem) {
      commentItem.comments = commentItem.reason_type__name;
      commentItem.comment = commentItem.description;
      commentItem.date = this.formatDate(commentItem.timestamp);
      commentItem.name = commentItem.worker__name;

      return commentItem;
    },

    resetCommentData() {
      this.commentDescription = "";
      this.selectedCommentId = "";
    },

    formatDate(data) {
      return format(parseISO(data), "dd/MM/yy");
    },
    formatTime(data) {
      return format(parseISO(data), "HH:mm");
    },

    formatFullName(fullName, value) {
      return fullName?.length > value
        ? fullName?.slice(0, value) + "..."
        : fullName;
    },

    // Arrow navigations
    goBackwardShift(showLoader) {
      this.allowEndpoint = false;
      clearInterval(this.timer);
      this.previousLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            shift_start_date: this.previousShift?.previous_shift_start_date,
            shift_end_date: this.previousShift?.previous_shift_end_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.previousLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.previousLogsLoader = false;
        });
    },

    goForwardShift(showLoader) {
      this.allowEndpoint = true;
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            shift_start_date: this.nextShift?.next_shift_start_date,
            shift_end_date: this.nextShift?.next_shift_end_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
        })
        .finally(() => {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.errorCount < 10 && this.isCurrentShift) {
              this.getCurrentShift(false);
            } else {
              clearInterval(this.timer);
            }
          }, 5000);
        });
    },

    getCurrentShift(showLoader) {
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            shift_start_date:
              this.currentShift?.current_shift_start_date ||
              "2022-01-12T08:07:02",
            shift_end_date:
              this.currentShift?.current_shift_end_date ||
              "2022-01-12T09:07:02",
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
          this.errorCount++;
        });
    },

    getMainCurrentShiftData(data) {
      if (Object.hasOwnProperty.call(data, "current_shift_start_date")) {
        this.mainCurrentShift = data;
        this.setCurrentShiftStartDate(data.current_shift_start_date);
        this.setCurrentShiftEndDate(data.current_shift_end_date);
      }
    },

    // Switches Section
    unPlannedStopMachine(active) {
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.stop,
            status: active ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMachineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isUnplannedStop = !this.isUnplannedStop;
        });
    },

    productionMachine(active) {
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.automaticMode,
            status: active ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMachineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isProduction = !this.isProduction;
        });
    },

    plannedStopMachine(active) {
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.plannedStop,
            status: active ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMachineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isPlannedStop = !this.isPlannedStop;
        });
    },

    prepareMachine(active) {
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.prepare,
            status: active ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMachineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isPrepare = !this.isPrepare;
        });
    },

    cleanMachine(active) {
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.cleaning,
            status: active ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMachineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isCleaning = !this.isCleaning;
        });
    },

    startBreak() {
      this.startBreakLoader = true;
      Api()
        .post(
          `/machines/manual-action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.employeeBreak,
            status: 1,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.startBreakLoader = false;
            this.setStartBreak();
            this.showSuccess(res.data.detail);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.startBreakLoader = false;
        });
    },

    stopBreak() {
      this.stopBreakLoader = true;
      Api()
        .post(
          `/machines/action-log/${
            this.routeMachineId || this.userMachineId || this.aftMacId
          }/`,
          {
            reason_type_id: actionLogTypes.employeeBreak,
            status: 0,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.setMachineStatus(res.data.machine_status);
            this.stopBreakLoader = false;
            this.setStopBreak();
            this.showSuccess(res.data.detail);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.stopBreakLoader = false;
        });
    },

    updateMachineSwitches(newData) {
      this.isCleaning = newData.clean_data;
      this.isPlannedStop = newData.stopped_data;
      this.isPrepare = newData.prepare_data;
      this.isUnplannedStop = newData.unplanned_stop;
      this.isProduction = newData.production;
    },

    setStartBreak() {
      this.scrollToModalTop();
      this.isEmployeeBreak = true;
    },

    setStopBreak() {
      this.isEmployeeBreak = false;
    },

    setCurrentShiftState() {
      this.showCurrentShift = true;
      this.showNextShift = false;
      this.showPreviousShift = false;
    },

    // Getting reasons
    getAllCommentReasons() {
      ApiNoToken()
        .post("/machines/get-dropdowns/5/")
        .then((res) => {
          if (res.data.status == "success") {
            this.commentReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getAllFaultReasons() {
      const payload = {
        machine_id: Number(this.routeMachineId),
      };
      ApiNoToken()
        .post("/machines/get-dropdowns/6/", payload)
        .then((res) => {
          if (res.data.status == "success") {
            this.faultReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getAllNioReasons() {
      const payload = {
        machine_id: Number(this.routeMachineId),
      };
      ApiNoToken()
        .post("/machines/get-dropdowns/7/", payload)
        .then((res) => {
          if (res.data.status == "success") {
            this.nioReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    showStartDateTimeModal() {
      this.$refs.StartDateTimeRef.showStartDateTimeModal();
    },

    saveStartDateTime(data) {
      this.dateTimeStart = data;
    },

    cancelStartDateTime() {
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
    },

    showStopDateTimeModal() {
      this.$refs.StopDateTimeRef.showStopDateTimeModal();
    },

    saveStopDateTime(data) {
      this.dateTimeStop = data;
    },

    cancelStopDateTime() {
      this.dateTimeStop = "";
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId
        ? this.$route.params.routeMachineId
        : this.dynamicMachineId;
      console.log(this.routeMachineId);
    },

    showErrorAlert(errorMessage) {
      let msg = "Authentication credentials were not provided.";
      if (msg == errorMessage && this.shlViewLang === "en") {
        this.longAlertMsg = "Please log in to perform this action";
        this.isLongAlert = true;
        this.dialogComments = false;
        this.dialogNIO = false;
        this.dialogFaults = false;
      } else if (msg == errorMessage && this.shlViewLang === "de") {
        this.longAlertMsg =
          "Bitte melden Sie sich an, um diese Aktion durchzuführen";
        this.isLongAlert = true;
        this.dialogComments = false;
        this.dialogNIO = false;
        this.dialogFaults = false;
      } else {
        this.showAlert = true;
        this.alertColor = "#e76a6a";
        this.textColor = "#ffffff";
        this.alertMessage = errorMessage;
        this.isSuccess = false;
      }
      this.scrollToTop();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToModalTop() {
      setTimeout(() => {
        const modalTop = document.querySelector("#modalTop");
        modalTop.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showErrorAlert(errorMessage);
    },

    formatShiftDate(data) {
      return format(parseISO(data), "EEEE LLL dd, yyyy");
    },

    clearFaultReason() {
      this.selectedFaultId = "";
    },

    clearNioReason() {
      this.selectedNioId = "";
    },

    clearNioQuantity() {
      this.nioQuantity = "";
    },

    closeAddCommentDialog() {
      this.$refs.commentForm.reset();
    },

    ...mapActions("users", [
      "setCurrentShiftStartDate",
      "setCurrentShiftEndDate",
    ]),

    ...mapActions("machines", ["setMachineStatus", "setMachineName"]),
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.authentication.isLoggedIn;
    },

    currentShiftStartDate() {
      return this.$store.state.users.currentShiftStartDate;
    },

    currentShiftEndDate() {
      return this.$store.state.users.currentShiftEndDate;
    },

    shlViewLang() {
      return this.$store.state.translation.i18n.locale;
    },

    fullName() {
      return this.$store.state.authentication.currentUser?.data?.user?.name;
    },

    currentUserData() {
      return this.$store.state.authentication.currentUser?.data;
    },

    userMachineId() {
      return this.currentUserData?.user?.machine_id;
    },

    machineRole() {
      return (
        this.currentUserData?.user?.machine_role || this.defaultMachineRole
      );
    },

    isTechnician() {
      return this.machineRole == "Technician";
    },

    machineStatus() {
      return this.$store.state.machines.machineStatus;
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isCurrentShift() {
      return (
        this.mainCurrentShift?.current_shift_start_date ==
        this.currentShift?.current_shift_start_date
      );
    },

    isDisableNextButton() {
      return (
        this.mainCurrentShift?.current_shift_end_date ==
        this.nextShift?.next_shift_start_date
      );
    },

    machineName() {
      return this.$store.state.machines.machineName;
    },
  },

  watch: {
    faultsStopTime(val) {
      if (!val) {
        this.cancelStopDateTime();
      }
    },

    dialogFaults(val) {
      if (!val) {
        this.clearFaultReason();
      }
    },

    dialogNIO(val) {
      if (!val) {
        this.clearNioReason();
        this.clearNioQuantity();
      }
    },

    dialogComments(val) {
      !val && this.closeAddCommentDialog();
    },

    shlViewLang(val) {
      if (val) {
        this.formattedLang = checkLanguage(val);
      }
    },
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );
    this.getAllMachinesLogs(true);
    this.getAllCommentReasons();
    this.getAllFaultReasons();
    this.getAllNioReasons();
  },

  created() {
    this.getMachineIdFromRoute();
    this.getMachineId();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-time-picker-title__time {
  height: 56px;
}

* /deep/ .v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  font-size: 60px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 16px !important;
}

* /deep/ .switch .v-input--selection-controls__input {
  margin-right: 0 !important;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #dad5d5;
}

* /deep/.switch.switch-control .v-input--switch__track {
  background-color: #32d583;
  opacity: 1;
}

.start-break-btn {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.select-reason .v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-label {
  font-weight: 600;
  font-size: 18px;
  color: #344054;
}

.v-text-field {
  border-radius: 8px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.faults-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.faults-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.NIO-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.NIO-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.comments-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.comments-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

/* .newBtnColor {
  background-color: #32d583;
  color: red;
} */

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
