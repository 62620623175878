<template>
  <v-dialog
    ref="startMenu"
    v-model="startMenu"
    :return-value.sync="date"
    transition="scale-transition"
    class="bg-[white]"
    max-width="590"
  >
    <div class="flex justify-center gap-1 bg-[white]">
      <v-date-picker v-model="date" width="290"> </v-date-picker>
      <v-time-picker v-model="time" width="290"></v-time-picker>
    </div>

    <div class="pa-4 flex justify-center bg-[white]">
      <v-btn text color="primary" @click="cancelDateTime">{{
        $t("datetimemodal.cancel")
      }}</v-btn>
      <v-btn text color="primary" @click="saveDateTime(date)">{{
        $t("datetimemodal.save")
      }}</v-btn>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      date: moment().format().slice(0, 10),
      startMenu: false,
      time: moment().format("HH:mm"),
    };
  },

  computed: {
    dateTime() {
      return this.date + " " + this.time;
    },
  },

  methods: {
    cancelDateTime() {
      this.date = moment().format().slice(0, 10);
      this.time = moment().format("HH:mm");
      this.startMenu = false;
      this.$emit("cancel-startDateTime");
    },

    saveDateTime(date) {
      this.$refs.startMenu.save(date);
      this.startMenu = false;
      this.$emit("save-startDateTime", this.dateTime);
    },

    showStartDateTimeModal() {
      this.startMenu = true;
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-time-picker-title__time {
  height: 56px;
}

* /deep/ .v-time-picker-title__time .v-picker__title__btn,
.v-time-picker-title__time span {
  font-size: 60px;
}
</style>
