import Vue from "vue";
import Vuex from "vuex";
/** import createPersistedState from "vuex-persistedstate"; */
import settingsModule from "./modules/settingsModule";
import authModule from "./modules/authModule";
import translationModule from "./modules/translationModule";
import createPersistedState from "vuex-plugin-persistedstate";
import usersModule from "./modules/usersModule";
import machinesModule from "./modules/machinesModule";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "aftvuex",
    }),
  ],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    settings: settingsModule,
    authentication: authModule,
    translation: translationModule,
    users: usersModule,
    machines: machinesModule,
  },
});
