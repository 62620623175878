<template>
  <!-- reusable card -->
  <div
    class="w-full bg-[#ffffff] border border-[#EAECF0] rounded-lg p-6 mx-auto flex flex-row justify-between align-middle"
    @click="$emit('go-ToMachineDetails')"
  >
    <div class="">
      <h1 class="text-[#101828] font-semibold text-[16px]">
        {{ $props.machineName }}
      </h1>
      <p class="text-[#667085] font-[400] text-[16px]">
        {{ $props.machineNumber }}
      </p>
    </div>
    <div class="">
      <slot name="indicator"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["machineName", "machineNumber"],
};
</script>
