export default {
  namespaced: true,
  state: {
    isStopped: false,
    isLoggedIn: true,
  },

  mutations: {
    SET_IS_STOPPED(state, data) {
      state.isStopped = data;
    },

    SET_IS_LOGGED_IN(state, data) {
      state.isLoggedIn = data;
    },
  },
  actions: {
    setIsStopped({ commit }, data) {
      commit("SET_IS_STOPPED", data);
    },

    setIsLoggedIn({ commit }, data) {
      commit("SET_IS_LOGGED_IN", data);
    },
  },
};
