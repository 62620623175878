<template>
  <div class="w-[94%] flex">
    <div class="flex-[0] md:flex-[0.1]"></div>
    <div class="flex-full md:flex-[0.9] p-3 md:p-10">
      <!-- tops cards -->
      <div
        class="w-[100%] sm:w-[100%] md:w-[80%] grid grid-cols-1 md:grid-cols-3 gap-8 mb-7"
      >
        <template v-if="homeViewLang === 'en' && testMachines.length > 0">
          <the-card
            v-for="(item, index) in testMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'de' && deTestMachines.length > 0">
          <the-card
            v-for="(item, index) in deTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'cn' && cnTestMachines.length > 0">
          <the-card
            v-for="(item, index) in cnTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'es' && esTestMachines.length > 0">
          <the-card
            v-for="(item, index) in esTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'ro' && roTestMachines.length > 0">
          <the-card
            v-for="(item, index) in roTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'ba' && baTestMachines.length > 0">
          <the-card
            v-for="(item, index) in baTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
        <template v-if="homeViewLang === 'rs' && rsTestMachines.length > 0">
          <the-card
            v-for="(item, index) in rsTestMachines"
            :key="index"
            :machineName="item.machine_name"
            :machineNumber="
              item.isDigital ? digitalStationMsg : labelStationMsg
            "
          >
            <template v-slot:indicator>
              <v-img :src="`${item.src}`"></v-img>
            </template>
          </the-card>
        </template>
      </div>
      <div class="bg-[white] w-[80%] md:w-full">
        <!-- buttons -->
        <div class="bg-[white] px-5 flex flex-col mr-32 md:mr-36">
          <div
            class="bg-[white] px-5 mt-5 pb-0 flex flex-row justify-between items-center align-middle"
          >
            <div class="flex justify-start flex-1 align-middle">
              <div
                class="border-[1px] !border-[#D0D5DD] px-[10px] py-4 rounded-tl-lg rounded-bl-lg cursor-pointer"
                :class="`${isViewAll ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                @click="getAllMachineStates"
              >
                {{ $t("homeview.viewAll") }}
              </div>
              <div
                class="border-[1px] border-[#D0D5DD] px-[10px] py-4 cursor-pointer"
                :class="`${isViewProduction ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                @click="getProductionMachines"
              >
                {{ $t("homeview.production") }}
              </div>
              <div
                class="border-[1px] border-[#D0D5DD] px-[10px] py-4 rounded-tr-lg rounded-br-lg cursor-pointer"
                :class="`${isViewStopped ? 'bg-[#F9FAFB]' : 'bg-[#ffffff]'}`"
                @click="getStoppedMachines"
              >
                {{ $t("homeview.stopped") }}
              </div>
            </div>
            <!-- input -->
            <div class="flex-1 my-4">
              <the-input></the-input>
            </div>
            <!-- divider -->
          </div>
          <div class="w-full px-5">
            <v-divider></v-divider>
          </div>
        </div>
        <!-- machines -->
        <div class="p-5" v-for="(mach, idx) in machList" :key="idx">
          <!-- Automatic machines -->
          <div class="w-[100%] my-5">
            <h1 class="text-[#101828] font-semibold text-[18px]">
              {{ getMachineName(mach) }}
            </h1>
            <div class="w-[100%] grid grid-cols-1 md:grid-cols-3 gap-8 mb-2">
              <template
                ><the-card
                  v-for="(item, index) in getMachineList(mach)"
                  :key="index"
                  :machineName="item.machine_name"
                  :machineNumber="item.machine_number"
                  @go-ToMachineDetails="
                    goToMachineDetails(item, getMachineName(mach))
                  "
                  class="cursor-pointer"
                >
                  <template #indicator>
                    <v-chip
                      :color="getChipColor(item)"
                      text-color="white"
                      class="cursor-pointer"
                    >
                      {{ getChipStatus(item) }}
                    </v-chip>
                  </template>
                </the-card>
              </template>
            </div>
            <div v-if="getMachineList(mach) == 0">
              {{ $t("homeview.noMachinesFound") }}
            </div>
          </div>
        </div>
      </div>
      <v-overlay :value="machinesLoader" color="grey">
        <v-progress-circular
          indeterminate
          size="70"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <div class="position-alert" v-if="showAlert">
        <AlertComponent
          :alertColor="alertColor"
          :textColor="textColor"
          :alertMessage="alertMessage"
          :isSuccess="isSuccess"
          @hide-alert="hideAlert"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard.vue";
import infoIcon from "@/assets/info.svg";
import errorIcon from "@/assets/error.svg";
import TheInput from "@/components/TheInput.vue";
import Api from "../../services/api";
import AlertComponent from "../../components/AlertComponent.vue";

export default {
  components: { TheCard, TheInput, AlertComponent },
  data() {
    return {
      title: "Title of the page",
      automaticMachines: [],
      manualMachines: [],
      otherMachines: [],

      testMachines: [
        {
          machine_name: "Label Station Service",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automatic",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Digital Shift Log",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automatic",
          src: infoIcon,
          isDigital: true,
        },
      ],
      deTestMachines: [
        {
          machine_name: "Etikettierstation Service",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automatisch",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Digitales Schichtprotokoll",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automatisch",
          src: infoIcon,
          isDigital: true,
        },
      ],
      esTestMachines: [
        {
          machine_name: "Servicio de estación de etiquetas",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automático",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Registro digital de turnos",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automático",
          src: infoIcon,
          isDigital: true,
        },
      ],
      roTestMachines: [
        {
          machine_name: "Serviciul stației de etichetare",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automat",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Jurnal de schimb digital",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Automat",
          src: infoIcon,
          isDigital: true,
        },
      ],
      cnTestMachines: [
        {
          machine_name: "标签站服务",
          machine_number: "14.11.22  11:01:02",
          machine_state: "自动的",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "数字移位记录",
          machine_number: "14.11.22  11:01:02",
          machine_state: "自动的",
          src: infoIcon,
          isDigital: true,
        },
      ],
      baTestMachines: [
        {
          machine_name: "Servis stanice za etiketiranje",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Auto",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Servis stanice za etiketiranje",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Auto",
          src: infoIcon,
          isDigital: true,
        },
      ],
      rsTestMachines: [
        {
          machine_name: "Услуга етикетирања станице",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Ауто",
          src: infoIcon,
          isDigital: false,
        },
        {
          machine_name: "Услуга етикетирања станице",
          machine_number: "14.11.22  11:01:02",
          machine_state: "Ауто",
          src: infoIcon,
          isDigital: true,
        },
      ],
      isViewAll: true,
      isViewProduction: false,
      isViewStopped: false,
      machinesLoader: false,
      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,

      // dynamicMachineId: "4", // temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),

      machList: [],
    };
  },

  methods: {
    searchEvent() {
      // eslint-disable-next-line no-undef
      Fire.$on("search-event", (res) => {
        this.structureMachinesData(res);
      });
    },
    getAllMachineStates() {
      this.machinesLoader = true;
      Api()
        .get("/machines/all-machine-states/")
        .then((res) => {
          if (res.data.status == "success") {
            let digitalStationCard = res.data.heartbeat_data;
            this.$store.commit(
              "machines/SET_DIGITAL_STATION_CARD_DATA",
              digitalStationCard
            );
            this.structureMachinesData(res.data.data);
          }
          this.isViewProduction = false;
          this.isViewStopped = false;
          this.isViewAll = true;
          this.machinesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLoader = false;
        });
    },

    getProductionMachines() {
      this.machinesLoader = true;
      Api()
        .get("/machines/all-running-machines/")
        .then((res) => {
          if (res.data.status == "success") {
            this.structureMachinesData(res.data.data);
          }
          this.isViewAll = false;
          this.isViewStopped = false;
          this.isViewProduction = true;
          this.machinesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLoader = false;
        });
    },

    getStoppedMachines() {
      this.machinesLoader = true;
      Api()
        .get("/machines/all-stopped-machines/")
        .then((res) => {
          if (res.data.status == "success") {
            this.structureMachinesData(res.data.data);
          }
          this.isViewAll = false;
          this.isViewProduction = false;
          this.isViewStopped = true;
          this.machinesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLoader = false;
        });
    },

    getMachineName(machine) {
      return Object.keys(machine)[0];
    },

    getMachineList(machine) {
      return Object.values(machine)[0];
    },

    getChipColor(machine) {
      let statusData = {
        Automatic: "#32D583",
        Stopped: "#F63D68",
        "Planned Stop": "#FF9C66",
        Cleaning: "#FF9C66",
        Prepare: "#FF9C66",
        "Employee Break": "#FF9C66",
      };
      return statusData[machine.machine_state];
    },

    getChipStatus(machine) {
      let statusData = {
        Automatic: {
          en: "Production",
          de: "Produktion",
          cn: "生产",
          ro: "Producție",
          es: "Producción",
          ba: "Proizvodnja",
          rs: "Производња",
        },
        Stopped: {
          en: "Stopped",
          de: "Maschinenstillstand",
          cn: "停止",
          ro: "Oprit",
          es: "Parado",
          ba: "Stao",
          rs: "стао",
        },
        "Planned Stop": {
          en: "Planned Stop",
          de: "Geplanter Maschinenstillstand",
          cn: "计划中的停止",
          ro: "Oprire planificată",
          es: "Parada prevista",
          ba: "Planirano zaustavljanje",
          rs: "Планирано заустављање",
        },
        Cleaning: {
          en: "Clean",
          de: "Säubern",
          cn: "清洁",
          ro: "Curat",
          es: "Limpiar",
          ba: "Čisto",
          rs: "Чист",
        },
        Prepare: {
          en: "Prepare",
          de: "Rüsten",
          cn: "准备",
          ro: "Pregătiți",
          es: "Prepare",
          ba: "Pripremite se",
          rs: "Припремити",
        },
        "Employee Break": {
          en: "Break",
          de: "Pause",
          cn: "休息",
          ro: "Pauză",
          es: "Pausa",
          ba: "Pauza",
          rs: "Перерыв",
        },
      };

      return statusData[machine.machine_state][this.homeViewLang];
    },

    structureMachinesData(dataList) {
      this.machList = dataList;
    },

    formatMachinesData(machines) {
      return machines.map((machine, idx) => {
        machine = this.addMachineFeatures(machine, idx);
        return machine;
      });
    },

    addMachineFeatures(machine, idx) {
      let inProduction = machine.machine_state == "Automatic";
      machine.color = inProduction ? "#32D583" : "#F63D68";
      machine.src = idx % 2 ? infoIcon : errorIcon;
      return machine;
    },

    goToMachineDetails(machineData, machineType) {
      if (machineType === "Manual") {
        this.$router.push(
          `/admin-manual-atl/${machineData?.id || this.dynamicMachineId}`
        );
      } else {
        this.$router.push(
          `/admin-atl/${machineData?.id || this.dynamicMachineId}`
        );
      }
    },

    checkAndRedirect() {
      let isManual = this.isManual === "Manual";
      if (this.isAdmin) {
        this.$router.push("");
      } else if (this.userGUID && this.dynamicMachineId) {
        this.$router.push(
          `${isManual ? "/manual-worker" : ""}/atl/${this.dynamicMachineId}`
        ); // temporal
      } else {
        this.$router.push("/login");
      }
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
    },
  },
  computed: {
    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isLoggedIn() {
      return this.$store.state.authentication.isLoggedIn;
    },
    homeViewLang() {
      return this.$store.state.translation.i18n.locale;
    },

    userGUID() {
      return this.$store.state.authentication.userGUID;
    },

    labelStationMsg() {
      return this.$store.state.machines.labelStationCardData;
    },

    digitalStationMsg() {
      return this.$store.state.machines.digitalStationCardData;
    },

    isManual() {
      return this.$store.state.authentication.currentUser?.data.user
        ?.machine_group;
    },
  },

  mounted() {
    this.checkAndRedirect();
    this.getAllMachineStates();
    this.scrollToTop();
    this.searchEvent();
  },
};
</script>

<style scoped>
.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
