import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: {
    isLoggedIn: false,
    authToken: null,
    currentUser: {},
    isAdmin: false,
    isManual: false,
    userMachineId: null,
    userGUID: Cookies.get("deviceID") || "",
  },

  getters: {
    getIsLoggedIn(state) {
      return state.isLoggedIn;
    },

    getIsAdmin(state) {
      return state.isAdmin;
    },

    getisManual(state) {
      return state.isManual;
    },
  },

  mutations: {
    SET_CURRENT_USER_IN_STATE(state, data) {
      state.currentUser = { ...data };
    },
    SET_IS_LOGGED_IN(state, data) {
      state.authToken = data;
      state.isLoggedIn = !!data;
    },
    SET_IS_ADMIN(state, data) {
      state.isAdmin = data;
    },
    SET_IS_MANUAL(state, data) {
      state.isManual = data;
    },
    UPDATE_CURRENT_USER_IN_STATE(state, data) {
      state.currentUser = data;
    },
    UPDATE_CURRENT_USER_FULLNAME(state, data) {
      state.currentUser.data.user.name = data;
    },
    CLEAR_CURRENT_USER(state) {
      state.currentUser = null;
      state.authToken = null;
      state.isLoggedIn = false;
      state.isAdmin = false;
      state.isManual = false;
      state.userMachineId = null;
    },
    SET_USER_ROLE_IN_STATE(state, data) {
      state.role = data.role;
    },
    SET_USER_MACHINE_ID(state, data) {
      state.userMachineId = data;
    },
  },

  actions: {
    setCurrentUserInState({ commit }, data) {
      commit("SET_CURRENT_USER_IN_STATE", data);
    },
    setIsLoggedIn({ commit }, data) {
      commit("SET_IS_LOGGED_IN", data);
    },
    setIsAdmin({ commit }, data) {
      commit("SET_IS_ADMIN", data);
    },
    setIsManual({ commit }, data) {
      commit("SET_IS_MANUAL", data);
    },
    updateCurrentUserInState({ commit }, data) {
      commit("UPDATE_CURRENT_USER_IN_STATE", data);
    },
    updateCurrentUserFullname({ commit }, data) {
      commit("UPDATE_CURRENT_USER_FULLNAME", data);
    },
    setUserMachineId({ commit }, data) {
      commit("SET_USER_MACHINE_ID", data);
    },
    clearCurrentUser({ commit }) {
      commit("CLEAR_CURRENT_USER");
    },
  },
};
