<template>
  <v-dialog v-model="pinDialog" width="408" :retain-focus="false">
    <!-- Enter PIN text -->
    <v-card class="p-1">
      <v-card-title class="flex justify-between">
        <div class="text-lg text-[#101828] font-semibold mx-auto">
          {{ $t("pindialog.pleaseEnterPin") }}
        </div>
      </v-card-title>
      <v-card-text>
        <!-- Keypad to enter PIN -->
        <div class="p-2 button-div">
          <div class="flex justify-center gap-2 pb-3">
            <button
              @click="changeBtnColorOne('1')"
              :class="{ buttonClicked: isButtonClickedOne }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>1</div>
            </button>
            <button
              @click="changeBtnColorTwo('2')"
              :class="{ buttonClicked: isButtonClickedTwo }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>2</div>
            </button>
            <button
              @click="changeBtnColorThree('3')"
              :class="{ buttonClicked: isButtonClickedThree }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>3</div>
            </button>
          </div>
          <div class="flex justify-center gap-2 pb-3">
            <button
              @click="changeBtnColorFour('4')"
              :class="{ buttonClicked: isButtonClickedFour }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>4</div>
            </button>
            <button
              @click="changeBtnColorFive('5')"
              :class="{ buttonClicked: isButtonClickedFive }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>5</div>
            </button>
            <button
              @click="changeBtnColorSix('6')"
              :class="{ buttonClicked: isButtonClickedSix }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>6</div>
            </button>
          </div>
          <div class="flex justify-center gap-2 pb-3">
            <button
              @click="changeBtnColorSeven('7')"
              :class="{ buttonClicked: isButtonClickedSeven }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>7</div>
            </button>
            <button
              @click="changeBtnColorEight('8')"
              :class="{ buttonClicked: isButtonClickedEight }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>8</div>
            </button>
            <button
              @click="changeBtnColorNine('9')"
              :class="{ buttonClicked: isButtonClickedNine }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>9</div>
            </button>
          </div>
          <div class="flex justify-center gap-2 pb-3">
            <button
              @click="changeBtnColorStar('*')"
              :class="{ buttonClicked: isButtonClickedStar }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>*</div>
            </button>
            <button
              @click="changeBtnColorZero('0')"
              :class="{ buttonClicked: isButtonClickedZero }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>0</div>
            </button>
            <button
              @click="changeBtnColorHash('#')"
              :class="{ buttonClicked: isButtonClickedHash }"
              class="flex justify-center items-center w-[80px] h-[80px] rounded-lg text-5xl border-2 border-[#D0D5DD] text-[#1D2939]"
            >
              <div>#</div>
            </button>
          </div>
        </div>
        <div class="flex justify-between pb-1 gap-2">
          <div>
            <button
              class="w-[174px] h-[44px] rounded-[8px] text-[16px] bg-[#ffffff] text-[#344054] cancel-btn"
              @click="closePinModal()"
            >
              {{ $t("pindialog.cancel") }}
            </button>
          </div>
          <div>
            <v-btn
              depressed
              width="174"
              height="44"
              color="primary"
              class="text-[#ffffff] text-[18px] flex justify-center items-center rounded-[8px]"
              :loading="unLoadOrderLoader"
              @click="unLoadOrderOrPackage()"
              ><span v-if="isClosePackage">{{
                $t("pindialog.closePackage")
              }}</span>
              <span v-else>{{ $t("pindialog.unloadOrder") }}</span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["isUnloadCurrent", "isUnloadNext", "isClosePackage"],
  data() {
    return {
      pinDialog: false,
      isButtonClickedOne: false,
      isButtonClickedTwo: false,
      isButtonClickedThree: false,
      isButtonClickedFour: false,
      isButtonClickedFive: false,
      isButtonClickedSix: false,
      isButtonClickedSeven: false,
      isButtonClickedEight: false,
      isButtonClickedNine: false,
      isButtonClickedStar: false,
      isButtonClickedZero: false,
      isButtonClickedHash: false,
      pinCode: "",
      unLoadOrderLoader: false,
    };
  },

  methods: {
    changeBtnColorOne(data) {
      this.isButtonClickedOne = !this.isButtonClickedOne;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorTwo(data) {
      this.isButtonClickedTwo = !this.isButtonClickedTwo;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorThree(data) {
      this.isButtonClickedThree = !this.isButtonClickedThree;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorFour(data) {
      this.isButtonClickedFour = !this.isButtonClickedFour;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorFive(data) {
      this.isButtonClickedFive = !this.isButtonClickedFive;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorSix(data) {
      this.isButtonClickedSix = !this.isButtonClickedSix;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorSeven(data) {
      this.isButtonClickedSeven = !this.isButtonClickedSeven;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorEight(data) {
      this.isButtonClickedEight = !this.isButtonClickedEight;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorNine(data) {
      this.isButtonClickedNine = !this.isButtonClickedNine;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorStar(data) {
      this.isButtonClickedStar = !this.isButtonClickedStar;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorZero(data) {
      this.isButtonClickedZero = !this.isButtonClickedZero;
      this.addOrRemoveDigit(data);
    },
    changeBtnColorHash(data) {
      this.isButtonClickedHash = !this.isButtonClickedHash;
      this.addOrRemoveDigit(data);
    },

    resetData() {
      this.isButtonClickedOne = false;
      this.isButtonClickedTwo = false;
      this.isButtonClickedThree = false;
      this.isButtonClickedFour = false;
      this.isButtonClickedFive = false;
      this.isButtonClickedSix = false;
      this.isButtonClickedSeven = false;
      this.isButtonClickedEight = false;
      this.isButtonClickedNine = false;
      this.isButtonClickedStar = false;
      this.isButtonClickedZero = false;
      this.isButtonClickedHash = false;
      this.pinCode = "";
    },

    showPinModal() {
      this.resetData();
      this.pinDialog = true;
    },

    closePinModal() {
      this.pinDialog = false;
      this.resetData();
    },

    stopUnLoadOrderLoader() {
      this.unLoadOrderLoader = false;
    },

    startUnloadOrderLoader() {
      this.unLoadOrderLoader = true;
    },

    unLoadOrderOrPackage() {
      if (this.isUnloadCurrent) {
        // unload current order
        this.$emit("unload-currentOrder", this.pinCode);
        this.unLoadOrderLoader = true;
      } else if (this.isUnloadNext) {
        // unload next order
        this.$emit("unload-nextOrder", this.pinCode);
        this.unLoadOrderLoader = true;
      } else if (this.isClosePackage) {
        // close package
        this.$emit("close-package", this.pinCode);
        this.unLoadOrderLoader = true;
      }
    },

    removeLastDigit() {
      let pinCodeList = this.pinCode.split("");
      if (pinCodeList.length > 0) {
        pinCodeList.pop();
        this.pinCode = pinCodeList.join("");
      }
    },

    addOrRemoveDigit(data) {
      let pinCodeList = this.pinCode.split("");

      let foundIndex = pinCodeList.findIndex((value) => value == data);
      if (foundIndex > -1) {
        pinCodeList.splice(foundIndex, 1);
        this.pinCode = pinCodeList.join("");
      } else {
        this.pinCode = this.pinCode + data;
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.cancel-btn {
  border: 1px solid #d0d5dd;
}

.button-div button {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
}

.buttonClicked {
  background-color: #9b9dfd;
  color: #ffffff;
}
</style>
