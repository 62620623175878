<template>
  <div class="bg-white">
    <div class="w-[95%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div
            class="flex flex-col lg:flex-row items-center justify-between my-4"
          >
            <div class="text-[#101828] text-[18px] font-[600]">
              {{ $t("usersview.teamMembers") }}
            </div>
            <div
              class="flex flex-col lg:flex-row items-center justify-between gap-x-10"
            >
              <!-- Search input field  -->
              <div>
                <div
                  class="w-full bg-[#ffffff] border border-[#D0D5DD] rounded-lg flex flex-row justify-between items-center align-middle h-[50px]"
                >
                  <div class="py-5 pl-5">
                    <v-img src="@/assets/searchIcon.svg"></v-img>
                  </div>
                  <input
                    class="flex flex-row justify-between flex-1 p-4 mx-auto w-[375px] align-middle outline-none"
                    :placeholder="$t('theinput.search')"
                    v-model="search"
                    type="search"
                    @keyup.enter="fetchSearchItems"
                  />
                </div>
              </div>
              <!-- Add Member dialog box -->
              <v-dialog v-model="dialogAdd" width="408">
                <template v-slot:activator="{ on, attrs }"
                  ><v-btn
                    height="40"
                    depressed
                    color="#F5F5FF"
                    elevation="0"
                    class="add-btn"
                    v-bind="attrs"
                    v-on="on"
                    >{{ $t("usersview.add") }}</v-btn
                  ></template
                >
                <div class="w-[408px]">
                  <v-card class="p-1">
                    <v-form ref="usersForm">
                      <v-card-title>
                        <div
                          class="mx-auto text-lg font-semibold text-[#101828]"
                        >
                          {{ $t("usersview.addMember") }}
                        </div>
                        <div>
                          <v-img
                            @click="dialogAdd = false"
                            :src="require(`../../assets/close-icon.svg`)"
                            :lazy-src="require(`../../assets/close-icon.svg`)"
                            class="cursor-pointer"
                          >
                          </v-img>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <v-label>{{ $t("usersview.name") }}</v-label>
                        <v-text-field
                          dense
                          ref="name"
                          v-model="name"
                          outlined
                          color="#667085"
                        ></v-text-field>
                        <v-label>{{ $t("usersview.username") }}</v-label>
                        <v-text-field
                          dense
                          ref="name"
                          v-model="userName"
                          outlined
                          color="#667085"
                          :rules="[noSpaceAllowed(usersViewLang)]"
                        ></v-text-field>
                        <v-label>{{ $t("usersview.role") }}</v-label>
                        <v-select
                          :label="$t('usersview.selectRole')"
                          :items="userRoles"
                          item-text="name"
                          item-value="id"
                          v-model="selectedRoleId"
                          dense
                          outlined
                          single-line
                        ></v-select>
                        <v-label class="password-text">{{
                          $t("usersview.password")
                        }}</v-label>
                        <v-text-field
                          dense
                          v-model="password"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show1 ? 'text' : 'password'"
                          name="input-10-1"
                          outlined
                          color="#98A2B3"
                          @click:append="show1 = !show1"
                        ></v-text-field>
                        <v-label>{{ $t("usersview.rfid") }}</v-label>
                        <v-text-field
                          dense
                          ref="name"
                          v-model="pin"
                          outlined
                          color="#667085"
                        ></v-text-field>
                        <div class="flex justify-between">
                          <div>
                            <v-btn
                              @click="dialogAdd = false"
                              depressed
                              class="cancel-btn !bg-[#ffffff]"
                              >{{ $t("usersview.cancel") }}</v-btn
                            >
                          </div>
                          <div>
                            <v-btn
                              depressed
                              color="primary"
                              class="save-btn"
                              :loading="addMemberLoader"
                              @click="addMember"
                              >{{ $t("usersview.save") }}</v-btn
                            >
                          </div>
                        </div>
                      </v-card-text>
                    </v-form>
                  </v-card>
                </div>
              </v-dialog>
            </div>
          </div>
          <div class="text-[#667085] text-[14px] font-[400]">
            {{ $t("usersview.manageYourTeam") }}
          </div>
          <v-divider class="mt-4 mb-6"></v-divider>
          <!-- Members table  -->
          <v-data-table
            dense
            :headers="
              usersViewLang === 'en'
                ? headers
                : usersViewLang === 'de'
                ? deHeaders
                : usersViewLang === 'cn'
                ? cnHeaders
                : usersViewLang === 'ro'
                ? roHeaders
                : usersViewLang === 'ba'
                ? baHeaders
                : usersViewLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="formattedUsers"
            item-key="user_key"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('usersview.nodata')"
          >
            <template v-slot:[`header.icons`]="{}">
              <div class="flex justify-center mt-5 mr-2">
                <div>{{ $t("usersview.actions") }}</div>
              </div>
            </template>
            <template v-slot:[`item.machines`]="{ item }">
              <div
                v-if="item.machines"
                class="text-[#0408E7] w-[70px] cursor-pointer"
                @click="startAssignMachine(item)"
              >
                {{ $t("usersview.assign") }}
              </div>
            </template>

            <template v-slot:[`item.role`]="{ item }">
              <div class="text-[#667085]">{{ item.role }}</div>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <div class="text-[#101828] pr-16">{{ item.name }}</div>
            </template>

            <template v-slot:[`item.icons`]="{ item }">
              <div>
                <div class="flex justify-center gap-5">
                  <div class="w-[15px] cursor-pointer">
                    <v-dialog
                      :retain-focus="false"
                      v-model="dialogDelete"
                      width="400"
                      overlay-opacity="0.1"
                      content-class="elevation-0"
                    >
                      <template v-slot:activator="{}">
                        <v-img
                          :src="item.deleteIcon"
                          :lazy-src="item.deleteIcon"
                          @click="showDeleteDialog(item)"
                        ></v-img>
                      </template>
                      <div class="w-[400px]">
                        <v-card class="p-1">
                          <v-card-title class="flex justify-between">
                            <div class="text-lg text-[#101828] font-semibold">
                              {{ $t("usersview.deleteMember") }}
                            </div>
                            <div>
                              <v-img
                                @click="dialogDelete = false"
                                :src="require(`../../assets/close-icon.svg`)"
                                :lazy-src="
                                  require(`../../assets/close-icon.svg`)
                                "
                                class="cursor-pointer"
                              >
                              </v-img>
                            </div>
                          </v-card-title>
                          <v-card-text>
                            <div>
                              {{ $t("usersview.areYouSure") }}
                              <span class="font-bold">{{
                                selectedUser?.name
                              }}</span>
                              {{ $t("usersview.thisAction") }}
                            </div>
                            <div class="flex justify-between pt-5">
                              <div>
                                <v-btn
                                  @click="dialogDelete = false"
                                  class="users-cancel-btn !bg-[#ffffff]"
                                  elevation="0"
                                  depressed
                                  >{{ $t("usersview.cancel") }}</v-btn
                                >
                              </div>
                              <div>
                                <v-btn
                                  class="users-del-btn"
                                  elevation="0"
                                  depressed
                                  color="#D92D20"
                                  :loading="deleteUserLoader"
                                  @click="removeMember(item)"
                                  >{{ $t("usersview.yesDelete") }}</v-btn
                                >
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-dialog>
                  </div>
                  <div class="w-[15px] cursor-pointer">
                    <v-img
                      :src="item.editIcon"
                      :lazy-src="item.editIcon"
                      @click="startEditMember(item)"
                    ></v-img>
                  </div>
                  <div class="w-[15px] cursor-pointer">
                    <v-img
                      class="!text-[#667085]"
                      :src="item.resetPasswordIcon"
                      :lazy-src="item.resetPasswordIcon"
                      @click="openResetPassword(item)"
                    ></v-img>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>

          <EditUserDialog
            :selectedUser="selectedUser"
            :userRoles="userRoles"
            @update-name="updateName"
            @update-username="updateUsername"
            @update-rfidKeynumber="updateRfidKeyNumber"
            @update-selectedRoleId="updateSelectedRoleId"
            @update-member="updateMember"
            ref="EditUserDialogRef"
          />
          <v-dialog v-model="resetDialog" width="408">
            <div>
              <v-card class="p-1">
                <v-card-title>
                  <div class="mx-auto text-lg font-semibold text-[#101828]">
                    {{ $t("resetPassword.resetPassword") }}
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-label>{{ $t("resetPassword.newPassword") }}</v-label>
                  <v-text-field
                    dense
                    ref="password"
                    v-model="newPassword"
                    outlined
                    color="#667085"
                  ></v-text-field>
                  <v-label>{{ $t("resetPassword.confirmPassword") }}</v-label>
                  <v-text-field
                    dense
                    ref="password"
                    v-model="confirmPassword"
                    outlined
                    color="#667085"
                  ></v-text-field>
                </v-card-text>
                <div class="flex justify-between px-4 mb-4">
                  <div>
                    <v-btn
                      @click="resetDialog = false"
                      depressed
                      class="cancel-btn !bg-[#ffffff]"
                      >{{ $t("edituserdialog.cancel") }}</v-btn
                    >
                  </div>
                  <div>
                    <v-btn
                      depressed
                      color="primary"
                      class="save-btn"
                      @click="saveResetPassword"
                      >{{ $t("edituserdialog.save") }}</v-btn
                    >
                  </div>
                </div>
              </v-card>
            </div>
          </v-dialog>
        </v-card-text>
      </v-card>
    </div>

    <v-overlay :value="allUsersLoader || allRolesLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import debounce from "lodash.debounce";
import EditUserDialog from "../../components/admin/EditUserDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";
import validation from "../../services/validation";

export default {
  components: { EditUserDialog, AlertComponent },
  data() {
    return {
      search: "",
      name: "",
      userName: "",
      password: "",
      pin: "",
      dialogAdd: false,
      dialogAssign: false,
      dialogDelete: false,
      resetDialog: false,
      ...validation,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Role", value: "role" },
        { text: "Machines", value: "machines" },
        { text: "", value: "icons" },
      ],
      deHeaders: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Rolle", value: "role" },
        { text: "Maschinen", value: "machines" },
        { text: "", value: "icons" },
      ],
      cnHeaders: [
        {
          text: "命名",
          align: "start",
          value: "name",
        },
        { text: "角色", value: "role" },
        { text: "机器", value: "machines" },
        { text: "", value: "icons" },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "name",
        },
        { text: "Rol", value: "role" },
        { text: "Mașini", value: "machines" },
        { text: "", value: "icons" },
      ],
      baHeaders: [
        {
          text: "Ime",
          align: "start",
          value: "name",
        },
        { text: "Uloga", value: "role" },
        { text: "Mašine", value: "machines" },
        { text: "", value: "icons" },
      ],
      rsHeaders: [
        {
          text: "Име",
          align: "start",
          value: "name",
        },
        { text: "Улога", value: "role" },
        { text: "Машине", value: "machines" },
        { text: "", value: "icons" },
      ],
      esHeaders: [
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        { text: "Papel", value: "role" },
        { text: "Máquinas", value: "machines" },
        { text: "", value: "icons" },
      ],
      machines: [
        {
          name: "Olivia Rhye",
          role: "Admin",
          machines: "Assign",
          deleteIcon: require("../../assets/delete-icon.svg"),
          editIcon: require("../../assets/edit-icon.svg"),
          resetPasswordIcon: require("../../assets/lock-reset.svg"),
        },
      ],
      show1: false,
      allUsersLoader: false,
      allRolesLoader: false,
      formattedUsers: [],
      addMemberLoader: false,
      selectedUser: null,
      editMemberLoader: false,
      deleteUserLoader: false,
      selectedRoleId: "",
      userRoles: [],
      assignMachineLoader: false,
      machineList: [],
      selectedMachineId: "",
      machineGroups: [],

      machineRoleList: [],
      selectedMachineRoleId: "",

      page: 1,
      totalPages: 1,
      perPage: 10,
      totalUsersCount: 1,
      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
      userToResetPassword: null,
    };
  },

  computed: {
    usersViewLang() {
      return this.$store.state.translation.i18n.locale;
    },
    translatedMachines() {
      return this.$t("usersview.assign");
    },
  },

  methods: {
    // Fetch search results
    fetchSearchItems() {
      Api()
        .get(`/auth/all-system-users/${this.page}`, {
          params: {
            search_query: this.search,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            this.formattedUsers = [...this.formatUsersData(res.data.data)];
            this.totalPages = res.data.total_pages;
            this.totalUsersCount = res.data.total_users;
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    // Fetch all users
    getAllUsers(showLoader) {
      this.allUsersLoader = showLoader;
      Api()
        .get(`/auth/all-system-users/${this.page}/`)
        .then((res) => {
          if (res.data.status === "success") {
            this.formattedUsers = [...this.formatUsersData(res.data.data)];
            this.totalPages = res.data.total_pages;
            this.totalUsersCount = res.data.total_users;
          }
          this.allUsersLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allUsersLoader = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getAllUsers(true);
      this.scrollToTop();
    },

    getRoles() {
      this.allRolesLoader = true;
      Api()
        .post("/machines/get-dropdowns/2/")
        .then((res) => {
          if (res.data.status == "success") {
            this.userRoles = [...res.data.data];
          }
          this.allRolesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allRolesLoader = false;
        });
    },

    getMachinesDropdown() {
      Api()
        .post("/machines/get-dropdowns/1/")
        .then((res) => {
          if (res.data.status == "success") {
            this.machineList = [...res.data.data];
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getMachineRolesDropdown() {
      Api()
        .post("/machines/get-dropdowns/8/")
        .then((res) => {
          if (res.data.status == "success") {
            this.machineRoleList = [...res.data.data];
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getMachineGroups() {
      Api()
        .post("/machines/get-dropdowns/4/")
        .then((res) => {
          if (res.data.status == "success") {
            this.machineGroups = [...res.data.data];
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    // Adding a member
    addMemberFeatures(user) {
      user.role = user.user_role__name;
      if (user.user_role__name == "Factory Worker") {
        user.machines = "Assign";
      } else {
        user.machines = "";
      }
      user.deleteIcon = require("../../assets/delete-icon.svg");
      user.editIcon = require("../../assets/edit-icon.svg");
      user.resetPasswordIcon = require("../../assets/lock-reset.svg");
      return user;
    },

    formatUsersData(users) {
      return users.map((user) => {
        user = this.addMemberFeatures(user);
        return user;
      });
    },

    addToMembersList(user) {
      user = this.addMemberFeatures(user);
      this.formattedUsers.unshift(user);
      this.totalUsersCount++;
      this.totalPages = Math.ceil(this.totalUsersCount / this.perPage);
    },

    addMember() {
      this.addMemberLoader = true;
      let newData = {
        name: this.name,
        user_name: this.userName,
        password: this.password,
        rfid_key_number: this.pin,
        user_role_id: this.selectedRoleId,
      };
      Api()
        .post("/auth/add-user/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.addToMembersList(res.data.data);
            this.removeLastUser();
            this.showSuccess(res.data.detail);
            this.dialogAdd = false;
          }
          this.addMemberLoader = false;
          this.resetMemberData();
        })
        .catch((err) => {
          this.showError(err);
          this.addMemberLoader = false;
        });
    },
    openResetPassword(data) {
      this.userToResetPassword = data.user_key;
      this.resetDialog = true;
    },
    saveResetPassword() {
      let passwordData = {
        new_password: this.newPassword,
        confirm_password: this.confirmPassword,
      };
      Api()
        .post(`/auth/reset-password/${this.userToResetPassword}/`, passwordData)
        .then((response) => {
          if (response.data.status == "success") {
            this.showSuccess(response.data.detail);
            this.newPassword = "";
            this.confirmPassword = "";
            this.resetDialog = false;
          }
        })
        .catch((error) => {
          this.showError(error);
          this.resetDialog = false;
        });
    },

    removeLastUser() {
      if (this.perPage < this.totalUsersCount) {
        this.formattedUsers.splice(this.perPage, 1);
      }
    },

    // Deleting a member
    showDeleteDialog(data) {
      this.dialogDelete = true;
      this.selectedUser = { ...data };
    },

    removeFromMembersList(userKey) {
      let users = [...this.formattedUsers];
      this.formattedUsers = users.filter((user) => user.user_key !== userKey);
    },

    removeMember() {
      this.deleteUserLoader = true;
      Api()
        .delete(`/auth/delete-user/${this.selectedUser.user_key}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.removeFromMembersList(this.selectedUser.user_key);
            this.showSuccess(res.data.detail);
            this.getAllUsers(false);
            this.dialogDelete = false;
          }
          this.deleteUserLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.deleteUserLoader = false;
        });
    },

    resetMemberData() {
      this.name = "";
      this.userName = "";
      this.password = "";
      this.pin = "";
      this.selectedRoleId = "";
    },

    // Updating User Details
    startEditMember(data) {
      this.selectedUser = { ...data };
      this.showEditModal();
    },
    updateMember(data) {
      Api()
        .put(`/auth/update-user/${data.user_key}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.updateMembersList(res.data.data);
            this.showSuccess(res.data.detail);
            this.closeEditModal();
          }
          this.stopEditLoader();
        })
        .catch((err) => {
          this.showError(err);
          this.stopEditLoader();
        });
    },

    updateMembersList(user) {
      user = this.addMemberFeatures(user);
      this.findAndReplaceUser(user);
    },

    findAndReplaceUser(updatedUser) {
      let foundIndex = this.formattedUsers.findIndex(
        (user) => user.user_key == updatedUser.user_key
      );
      if (foundIndex == 0 || foundIndex) {
        this.formattedUsers.splice(foundIndex, 1, updatedUser);
      }
    },

    updateName(data) {
      this.selectedUser.name = data;
    },

    updateUsername(data) {
      this.selectedUser.user_name = data;
    },

    updateRfidKeyNumber(data) {
      this.selectedUser.rfid_key_number = data;
    },

    updateSelectedRoleId(data) {
      this.selectedUser.user_role_id = data;
    },

    showEditModal() {
      this.$refs.EditUserDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditUserDialogRef?.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditUserDialogRef?.stopEditLoader();
    },

    closeAddUsersDialog() {
      this.$refs.usersForm.reset();
    },

    // Assigning machine to worker
    startAssignMachine(data) {
      this.$router.push(`/admin/assigned-worker/${data.rfid_key_number}`);
    },

    assignMachineToWorker() {
      this.assignMachineLoader = true;
      Api()
        .post(
          `/machines/assign-machine/${this.selectedUser.rfid_key_number}/`,
          {
            machine_id: this.selectedMachineId,
            machine_role_id: this.selectedMachineRoleId,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.dialogAssign = false;
            this.resetAssignData();
            this.showSuccess(res.data.detail);
          }
          this.assignMachineLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.assignMachineLoader = false;
        });
    },

    resetAssignData() {
      this.selectedMachineId = "";
      this.selectedMachineRoleId = "";
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllUsers(true);
    this.getRoles();
    this.getMachinesDropdown();
    this.getMachineRolesDropdown();
    this.getMachineGroups();
    this.scrollToTop();
    this.debounceSearchResults = debounce(this.fetchSearchItems, 100);
  },

  watch: {
    dialogAdd(val) {
      !val && this.closeAddUsersDialog();
    },
    search(...args) {
      this.debounceSearchResults(args);
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.theme--light.v-divider {
  color: #eaecf0;
}

.edit-text {
  color: #0408e7;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.users-del-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  width: 170px;
  color: #ffffff;
}

.users-cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  width: 170px;
  color: #344054;
  border: 1px solid #d0d5dd;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
