<template>
  <div>
    <v-dialog v-model="dialogComments" width="455">
      <v-card>
        <v-form ref="commentForm">
          <v-card-title
            ><div class="mx-auto">
              <div class="text-[18px] text-[#101828] font-[600]">
                {{ $t("shlview.comments") }}
              </div>
            </div></v-card-title
          >
          <v-card-text>
            <div class="select-reason">
              <v-label>{{ $t("shlview.selectReason") }}</v-label>
            </div>
            <v-select
              class="w-[360px] h-[48px]"
              :placeholder="$t('shlview.shiftHandoverRemark')"
              :items="commentReasons"
              :item-text="formattedLang"
              item-value="id"
              v-model="selectedCommentId"
              dense
              outlined
            ></v-select>
            <div class="flex justify-start gap-2 pt-4">
              <div class="w-[355px]">
                <v-label>{{ $t("shlview.description") }}</v-label>
                <div class="pb-0">
                  <v-textarea
                    :placeholder="$t('shlview.enterADescription')"
                    v-model="commentDescription"
                    auto-grow
                    outlined
                    rows="2"
                    class="w-[354px]"
                  ></v-textarea>
                </div>
              </div>
            </div>
            <div class="flex justify-start gap-2">
              <div>
                <v-btn
                  class="comments-cancel-btn"
                  color="#ffffff"
                  depressed
                  width="174"
                  height="44"
                  @click="dialogComments = false"
                  >{{ $t("shlview.cancel") }}</v-btn
                >
              </div>
              <div>
                <v-btn
                  class="comments-save-btn"
                  color="primary"
                  depressed
                  width="174"
                  height="44"
                  :loading="addCommentLoader"
                  @click="addComment"
                  >{{ $t("shlview.save") }}</v-btn
                >
              </div>
            </div>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["commentReasons", "formattedLang"],
  components: {},
  data() {
    return {
      dialogComments: false,
      selectedCommentId: "",
      commentDescription: "",
      addCommentLoader: false,
    };
  },

  methods: {
    addComment() {
      this.addCommentLoader = true;
      let newData = {
        reason_type: this.selectedCommentId,
        description: this.commentDescription,
      };

      this.$emit("add-machineComment", newData);
    },

    showCommentDialog() {
      this.dialogComments = true;
    },

    closeAddCommentDialog() {
      this.dialogComments = false;
    },

    stopAddCommentLoader() {
      this.addCommentLoader = false;
    },

    resetCommentData() {
      this.commentDescription = "";
      this.selectedCommentId = "";
    },

    clearAddCommentFields() {
      this.$refs.commentForm.reset();
    },
  },

  watch: {
    dialogComments(val) {
      !val && this.clearAddCommentFields();
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-text-field {
  border-radius: 8px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.comments-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.comments-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
