import { render, staticRenderFns } from "./ATLView.vue?vue&type=template&id=f542b080&scoped=true"
import script from "./ATLView.vue?vue&type=script&lang=js"
export * from "./ATLView.vue?vue&type=script&lang=js"
import style0 from "./ATLView.vue?vue&type=style&index=0&id=f542b080&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f542b080",
  null
  
)

export default component.exports