<template>
  <div class="w-[full]">
    <div
      class="h-[320px] border-[2px] border-[#F2F4F7] rounded-t-[8px] px-4 pt-4"
    >
      <div class="flex justify-start">
        <div class="text-[#101828] text-[18px] font-[600]">
          {{ shiftHeaderData?.worker ? shiftHeaderData?.worker : "John Doe" }}
        </div>
      </div>
      <div class="flex justify-start mt-4 gap-2" v-if="shiftHeaderData">
        <div class="text-[#1D2939] text-[16px] font-[400]">
          {{ formatShiftDate(shiftHeaderData?.shift_start) }}
        </div>
        <div class="text-[#1D2939] text-[16px] font-[600]">
          {{ formatShiftTime(shiftHeaderData?.shift_start) }} -
          {{ formatShiftTime(shiftHeaderData?.shift_end) }}
        </div>
      </div>
      <div class="flex justify-start mt-4">
        <div class="text-[#667085] text-[12px] font-[400]">
          {{ $t("allshiftscard.assignments") }}
        </div>
      </div>
      <div class="flex justify-between items-center mb-3">
        <div class="text-[#101828] text-[20px] font-[600]">
          {{ $t("shlview.noOrder") }}
        </div>
        <div class="text-[#101828] text-[16px] font-[400]">
          {{ $t("allshiftscard.currentsetpoint") }}
          <span class="text-[#1D2939] text-[16px] font-[600]"
            >{{ shiftHeaderData?.currrent }}/{{
              shiftHeaderData?.set_point
            }}</span
          >
        </div>
      </div>
      <div class="flex flex-col">
        <div class="mb-1 text-[#101828] text-[14px] font-[400]">
          {{ $t("shlview.plantStatus") }}
        </div>
        <div class="flex gap-1 items-center">
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ $t("shlview.automaticMode") }}
          </div>
          <div class="w-[8px] h-[9px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.automatic_count }}
          </div>
          <div class="w-[10px] h-[10px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.atomatic_duration }}
          </div>
        </div>
        <div class="flex gap-1 items-center">
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ $t("shlview.stopCount") }}
          </div>
          <div class="w-[8px] h-[9px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.stop_count }}
          </div>
          <div class="w-[10px] h-[10px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.stop_duration }}
          </div>
        </div>
        <div class="flex gap-1 items-center">
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ $t("shlview.plannedStop") }}
          </div>
          <div class="w-[8px] h-[9px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.planned_stop_count }}
          </div>
          <div class="w-[10px] h-[10px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.planned_stop_duration }}
          </div>
        </div>
        <div class="flex gap-1 items-center">
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ $t("shlview.clean") }}
          </div>
          <div class="w-[8px] h-[9px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.cleaning_count }}
          </div>
          <div class="w-[10px] h-[10px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.cleaning_duration }}
          </div>
        </div>
        <div class="flex gap-1 items-center">
          <div class="mb-1 text-[#475467] text-[16px] font-[400]">
            {{ $t("shlview.prepare") }}
          </div>
          <div class="w-[8px] h-[9px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.prepare_count }}
          </div>
          <div class="w-[10px] h-[10px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div class="text-[#475467] text-[16px] font-[400]">
            {{ plantStatus?.prepare_duration }}
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      dense
      :headers="
        allShiftsLang === 'en'
          ? headers
          : allShiftsLang === 'de'
          ? deHeaders
          : allShiftsLang === 'cn'
          ? cnHeaders
          : allShiftsLang === 'ro'
          ? roHeaders
          : allShiftsLang === 'ba'
          ? baHeaders
          : allShiftsLang === 'es'
          ? esHeaders
          : rsHeaders
      "
      :items="formattedFaultLogs"
      item-key="id"
      class="border-x-[2px] border-b-[2px] border-t-0"
      :hide-default-footer="true"
      :no-data-text="$t('allshiftscard.nodata')"
    >
      <template v-slot:[`item.faults`]="{ item }"
        ><div class="text-[#101828] w-[180px]">
          {{ item[`reason_type__${formattedCardLang}`] }}
        </div></template
      >
      <template v-slot:[`item.info`]="{ item }">
        <div class="flex items-center gap-3">
          <div class="w-[13.33px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div>{{ item.info.quantity }}</div>
          <div class="w-[16.67px]">
            <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
          </div>
          <div>{{ item.info.time }}</div>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      dense
      :headers="
        allShiftsLang === 'en'
          ? headersTwo
          : allShiftsLang === 'de'
          ? deHeadersTwo
          : allShiftsLang === 'cn'
          ? cnHeadersTwo
          : allShiftsLang === 'ro'
          ? roHeadersTwo
          : allShiftsLang === 'ba'
          ? baHeadersTwo
          : allShiftsLang === 'es'
          ? esHeadersTwo
          : rsHeadersTwo
      "
      :items="formattedNioLogs"
      item-key="id"
      class="border-2 mt-5"
      :hide-default-footer="true"
      :no-data-text="$t('allshiftscard.nodata')"
    >
      <template v-slot:[`item.nio`]="{ item }">
        <div class="text-[#101828] w-[180px]">
          {{ item[`reason_type__${formattedCardLang}`] }}
        </div>
      </template>
      <template v-slot:[`item.infoTwo`]="{ item }">
        <div class="flex items-center gap-3">
          <div class="w-[13.33px]">
            <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
          </div>
          <div>{{ item.infoTwo.quantity }}</div>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      dense
      :headers="
        allShiftsLang === 'en'
          ? headersThree
          : allShiftsLang === 'de'
          ? deHeadersThree
          : allShiftsLang === 'cn'
          ? cnHeadersThree
          : allShiftsLang === 'ro'
          ? roHeadersThree
          : allShiftsLang === 'ba'
          ? baHeadersThree
          : allShiftsLang === 'es'
          ? esHeadersThree
          : rsHeadersThree
      "
      :items="formattedCommentLogs"
      item-key="id"
      class="border-2 mt-5"
      :hide-default-footer="true"
      :no-data-text="$t('allshiftscard.nodata')"
    >
      <template v-slot:[`item.comments`]="{ item }">
        <div class="text-[#101828] w-[140px]">
          {{ item[`reason_type__${formattedCardLang}`] }}
        </div>
      </template>
      <template v-slot:[`item.comment`]="{ item }">
        <div class="w-[160px]">
          {{ item.comment }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";

export default {
  props: [
    "formattedFaultLogs",
    "formattedNioLogs",
    "formattedCommentLogs",
    "shiftHeaderData",
    "plantStatus",
    "formattedCardLang",
  ],
  data() {
    return {
      headers: [
        {
          text: "Faults",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      deHeaders: [
        {
          text: "Störungen",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      esHeaders: [
        {
          text: "Fallos",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      roHeaders: [
        {
          text: "Defecțiuni",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      cnHeaders: [
        {
          text: "缺陷",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      baHeaders: [
        {
          text: "Greške",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      rsHeaders: [
        {
          text: "Грешке",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      faults: [
        {
          faults: "Plant 1 Error Msg",
          info: "",
        },
      ],
      headersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      deHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      esHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      roHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      cnHeadersTwo: [
        {
          text: "蔚来",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      baHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      rsHeadersTwo: [
        {
          text: "НИО",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      nio: [
        {
          nio: "Plant 1 Error Msg",
          infoTwo: "",
        },
      ],
      headersThree: [
        {
          text: "Comments",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
      ],
      deHeadersThree: [
        {
          text: "Kommentare",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      esHeadersThree: [
        {
          text: "Comentarios",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      cnHeadersThree: [
        {
          text: "评论",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      roHeadersThree: [
        {
          text: "Comentarii",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      baHeadersThree: [
        {
          text: "Komentari",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      rsHeadersThree: [
        {
          text: "Коментари",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      commentsInfo: [
        {
          comments: "Handover",
          comment: "comment",
        },
      ],
    };
  },

  computed: {
    allShiftsLang() {
      return this.$store.state.translation.i18n.locale;
    },

    currentShiftStartDate() {
      return this.$store.state.users.currentShiftStartDate;
    },

    currentShiftEndDate() {
      return this.$store.state.users.currentShiftEndDate;
    },
  },

  methods: {
    formatDate(data) {
      return format(parseISO(data), "dd/MM/yy");
    },

    formatTime(data) {
      return format(parseISO(data), "hh:mm");
    },

    formatFullName(fullName, value) {
      return fullName?.length > value
        ? fullName?.slice(0, value) + "..."
        : fullName;
    },

    formatShiftDate(data) {
      return format(parseISO(data), "EEEE LLL dd, yyyy");
    },

    formatShiftTime(data) {
      return format(parseISO(data), "HH:mm");
    },
  },
};
</script>

<style scoped>
* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}
</style>
