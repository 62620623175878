export default {
  namespaced: true,
  state: {
    currentShiftStartDate: "2002-01-12T09:07:02",
    currentShiftEndDate: "2002-01-12T09:07:02",
  },

  mutations: {
    SET_CURRENT_SHIFT_START_DATE(state, data) {
      state.currentShiftStartDate = data;
    },

    SET_CURRENT_SHIFT_END_DATE(state, data) {
      state.currentShiftEndDate = data;
    },

    CLEAR_USER_DATA(state) {
      state.currentShiftStartDate = "";
      state.currentShiftEndDate = "";
    },
  },

  actions: {
    setCurrentShiftStartDate({ commit }, data) {
      commit("SET_CURRENT_SHIFT_START_DATE", data);
    },

    setCurrentShiftEndDate({ commit }, data) {
      commit("SET_CURRENT_SHIFT_END_DATE", data);
    },

    clearUserData({ commit }) {
      commit("CLEAR_USER_DATA");
    },
  },
};
