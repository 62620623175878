<template>
  <!-- Add User Role dialog box -->
  <v-dialog :retain-focus="false" v-model="dialogAdd" width="480">
    <v-card>
      <v-card-title
        ><div class="mx-auto font-semibold text-lg text-[#101828]">
          {{ $t("adduserroledialog.userRole") }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-label class="text-[#344054] font-[400]">{{
          $t("adduserroledialog.name")
        }}</v-label>
        <v-text-field
          class="w-[360px] mb-7"
          :placeholder="$t('adduserroledialog.forEgAdmin')"
          v-model="userRoleName"
          dense
          ref="name"
          outlined
          color="#667085"
        ></v-text-field>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("adduserroledialog.members") }}
            </div>
            <div class="flex items-center justify-center">
              <div>
                <v-switch
                  v-model="membersViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    membersViewSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("adduserroledialog.informationRelatedToMembers") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="membersAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="membersAddSwitch ? 'clicked-switch switch' : 'switch'"
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="membersDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    membersDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.delete')" />
            </div>
          </div>
        </div>

        <v-divider class="my-3"></v-divider>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("adduserroledialog.machines") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="machinesViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    machinesViewSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("adduserroledialog.informationRelatedToMachines") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="machinesAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    machinesAddSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="machinesDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    machinesDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.delete')" />
            </div>
          </div>
        </div>

        <v-divider class="my-3"></v-divider>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("adduserroledialog.configurations") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="configViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="configViewSwitch ? 'clicked-switch switch' : 'switch'"
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("adduserroledialog.willBeAble") }}<br />{{
                $t("adduserroledialog.andMachineGroups")
              }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="configAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="configAddSwitch ? 'clicked-switch switch' : 'switch'"
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="configDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    configDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('adduserroledialog.delete')" />
            </div>
          </div>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="flex justify-between items-center">
          <div class="text-[#667085] font-[400]">
            {{ $t("adduserroledialog.allShiftlogs") }}
          </div>
          <div class="flex items-center justify-between">
            <div>
              <v-switch
                v-model="shiftlogViewSwitch"
                inset
                hide-details
                color="#ffffff"
                :class="shiftlogViewSwitch ? 'clicked-switch switch' : 'switch'"
              ></v-switch>
            </div>
            <switch-label :title="$t('adduserroledialog.view')" />
          </div>
        </div>

        <div class="flex justify-between items-center gap-3 mt-3">
          <div>
            <v-btn
              color="#ffffff"
              class="cancel-btn"
              @click="dialogAdd = false"
              depressed
              outlined
              width="210"
              height="44"
              >{{ $t("adduserroledialog.cancel") }}</v-btn
            >
          </div>
          <div>
            <v-btn
              class="update-btn"
              depressed
              color="primary"
              width="210"
              height="44"
              :loading="addUserRoleLoader"
              @click="addUserRole"
              >{{ $t("adduserroledialog.save") }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SwitchLabel from "../SwitchLabel.vue";

export default {
  components: { SwitchLabel },
  data() {
    return {
      dialogAdd: false,
      addUserRoleLoader: false,

      userRoleName: "",
      membersViewSwitch: true,
      membersAddSwitch: false,
      membersDeleteSwitch: false,

      machinesViewSwitch: true,
      machinesAddSwitch: false,
      machinesDeleteSwitch: false,

      configViewSwitch: true,
      configAddSwitch: false,
      configDeleteSwitch: false,

      shiftlogViewSwitch: false,
    };
  },

  computed: {},

  methods: {
    showAddDialog() {
      this.dialogAdd = true;
    },

    closeAddDialog() {
      this.dialogAdd = false;
    },

    stopAddLoader() {
      this.addUserRoleLoader = false;
    },

    addUserRole() {
      let newData = {
        name: this.userRoleName,
        view_member: this.membersViewSwitch ? 1 : 0,
        add_member: this.membersAddSwitch ? 1 : 0,
        delete_member: this.membersDeleteSwitch ? 1 : 0,
        view_machine: this.machinesViewSwitch ? 1 : 0,
        add_machine: this.machinesAddSwitch ? 1 : 0,
        delete_machine: this.machinesDeleteSwitch ? 1 : 0,
        read_config: this.configViewSwitch ? 1 : 0,
        add_config: this.configAddSwitch ? 1 : 0,
        delete_config: this.configDeleteSwitch ? 1 : 0,
        view_shiftlog: this.shiftlogViewSwitch ? 1 : 0,
      };

      this.addUserRoleLoader = true;
      this.$emit("add-userRole", newData);
    },

    resetUserRoleData() {
      this.userRoleName = "";
      this.membersViewSwitch = true;
      this.membersAddSwitch = false;
      this.membersDeleteSwitch = false;
      this.machinesViewSwitch = true;
      this.machinesAddSwitch = false;
      this.machinesDeleteSwitch = false;
      this.configViewSwitch = true;
      this.configAddSwitch = false;
      this.configDeleteSwitch = false;
      this.shiftlogViewSwitch = false;
    },

    clearUserRoleName() {
      this.userRoleName = "";
    },
  },

  watch: {
    dialogAdd(val) {
      if (!val) {
        this.clearUserRoleName();
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-input--selection-controls {
  padding: 10 !important;
  margin: 0 !important;
}

* /deep/ .v-input__control {
  display: flex;
  flex-direction: column;
  height: 32px !important;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #0408e7;
  opacity: 1;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  color: #344054 !important;
}

.update-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.group-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.group-delete-btn {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 8px !important;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #dad5d5;
}

* /deep/.switch.clicked-switch .v-input--switch__track {
  background-color: #0408e7;
  opacity: 1;
}
</style>
