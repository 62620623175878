<template>
  <div>
    <v-dialog v-model="dialogNIO" width="455">
      <v-card>
        <div class="flex justify-center">
          <div>
            <v-card-title
              ><div class="mx-auto">
                <div class="text-[18px] text-[#101828] font-[600]">
                  {{ $t("shlview.nio") }}
                </div>
              </div></v-card-title
            >
            <v-card-text>
              <div class="select-reason">
                <v-label>{{ $t("shlview.selectReason") }}</v-label>
              </div>
              <v-select
                class="w-[360px] h-[48px]"
                :placeholder="$t('shlview.brokenCase')"
                :items="nioReasons"
                :item-text="formattedLang"
                item-value="id"
                v-model="selectedNioId"
                dense
                outlined
              ></v-select>
              <div class="flex justify-start gap-2 pt-4">
                <div class="w-[185px]">
                  <v-label>{{ $t("shlview.date") }}</v-label>
                  <v-text-field
                    v-model="dateTimeStart"
                    class="w-[184px] h-[44px]"
                    placeholder="12-09-22 11:34"
                    dense
                    outlined
                    append-icon="$vuetify.icons.calendar_icon"
                    @click:append="showStartDateTimeModal"
                    readonly
                    @click="showStartDateTimeModal"
                  ></v-text-field>
                </div>
                <div class="w-[150px]">
                  <v-label>{{ $t("shlview.quantity") }}</v-label>
                  <v-text-field
                    class="w-[149px] h-[44px]"
                    v-model="nioQuantity"
                    placeholder="0"
                    dense
                    outlined
                    single-line
                    :rules="[nonNegativeNumber(addNioLang)]"
                  ></v-text-field>
                </div>
              </div>
              <div class="my-9"></div>
              <div class="flex justify-start gap-2">
                <div>
                  <v-btn
                    class="NIO-cancel-btn"
                    color="#ffffff"
                    depressed
                    width="174"
                    height="44"
                    @click="dialogNIO = false"
                    >{{ $t("shlview.cancel") }}</v-btn
                  >
                </div>
                <div>
                  <v-btn
                    class="NIO-save-btn"
                    color="primary"
                    depressed
                    width="174"
                    height="44"
                    :loading="addNioLoader"
                    @click="addNioLog"
                    >{{ $t("shlview.save") }}</v-btn
                  >
                </div>
              </div>
            </v-card-text>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <StartDateTimeModal
      :dateTimeStart="dateTimeStart"
      @save-startDateTime="saveStartDateTime"
      @cancel-startDateTime="cancelStartDateTime"
      ref="StartDateTimeRef"
    />
    <StopDateTimeModal
      :dateTimeStop="dateTimeStop"
      @save-stopDateTime="saveStopDateTime"
      @cancel-stopDateTime="cancelStopDateTime"
      ref="StopDateTimeRef"
    />
  </div>
</template>

<script>
import StartDateTimeModal from "../../components/StartDateTimeModal.vue";
import StopDateTimeModal from "../../components/StopDateTimeModal.vue";
import moment from "moment";
import validation from "../../services/validation";

export default {
  props: ["nioReasons", "formattedLang"],
  components: { StartDateTimeModal, StopDateTimeModal },
  data() {
    return {
      dialogNIO: false,
      selectedNioId: "",
      dateTimeStart:
        moment().format().slice(0, 10) + " " + moment().format("HH:mm"),
      dateTimeStop: "",
      addNioLoader: false,
      nioQuantity: "",
      ...validation,
    };
  },

  computed: {
    addNioLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  methods: {
    saveStartDateTime(data) {
      this.dateTimeStart = data;
    },

    cancelStartDateTime() {
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
    },

    showStartDateTimeModal() {
      this.$refs.StartDateTimeRef?.showStartDateTimeModal();
    },

    showStopDateTimeModal() {
      this.$refs.StopDateTimeRef?.showStopDateTimeModal();
    },

    saveStopDateTime(data) {
      this.dateTimeStop = data;
    },

    cancelStopDateTime() {
      this.dateTimeStop = "";
    },

    addNioLog() {
      this.addNioLoader = true;
      let newData = {
        reason_type: this.selectedNioId,
        quantity: this.nioQuantity,
        event_time: this.dateTimeStart ? this.dateTimeStart + ":00" : null,
      };

      this.$emit("add-machineNio", newData);
    },

    showNioDialog() {
      this.dialogNIO = true;
    },

    closeAddNioDialog() {
      this.dialogNIO = false;
    },

    stopAddNioLoader() {
      this.addNioLoader = false;
    },

    resetNioData() {
      this.nioQuantity = "";
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
      this.selectedNioId = "";
    },

    clearNioReason() {
      this.selectedNioId = "";
    },

    clearNioQuantity() {
      this.nioQuantity = "";
    },
  },

  watch: {
    dialogNIO(val) {
      if (!val) {
        this.clearNioReason();
        this.clearNioQuantity();
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-text-field {
  border-radius: 8px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.NIO-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.NIO-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
