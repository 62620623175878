<template>
  <div class="title-text text-[#344054] max-h-[40px] w-[80px] font-[400]">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
