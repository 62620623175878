<template>
  <div class="w-[full]">
    <!-- Technician Machine Groups table -->
    <v-data-table
      fixed-header
      dense
      height="404"
      item-key="id"
      :headers="
        technicianMachineGroup === 'en'
          ? headers
          : technicianMachineGroup === 'de'
          ? deHeaders
          : technicianMachineGroup === 'cn'
          ? cnHeaders
          : technicianMachineGroup === 'ro'
          ? roHeaders
          : technicianMachineGroup === 'ba'
          ? baHeaders
          : technicianMachineGroup === 'es'
          ? esHeaders
          : rsHeaders
      "
      :items="formattedMachines"
      v-model="selectedTechMachines"
      show-select
      class="border-2"
      checkbox-color="#0408E7"
      :hide-default-footer="true"
      :no-data-text="$t('assignedworkerview.nodata')"
      disable-pagination
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="text-[#101828] text-[14px] font-[400]">{{ item.name }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["technicianDetails", "selectedTechnicianMacGroupId"],

  data() {
    return {
      headers: [
        {
          text: "Names",
          align: "start",
          value: "name",
        },
      ],
      deHeaders: [
        {
          text: "Namen",
          align: "start",
          value: "name",
        },
      ],
      cnHeaders: [
        {
          text: "名称",
          align: "start",
          value: "name",
        },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "name",
        },
      ],
      esHeaders: [
        {
          text: "Nombres",
          align: "start",
          value: "name",
        },
      ],
      baHeaders: [
        {
          text: "Imena",
          align: "start",
          value: "name",
        },
      ],
      rsHeaders: [
        {
          text: "Имена",
          align: "start",
          value: "name",
        },
      ],
      machineGroups: [
        {
          name: "SAR3",
        },
      ],

      formattedMachines: [],
      selectedTechMachines: [],
      formattedSelectedList: [],
      technicianId: 2,
      formattedUnselectedList: [],
    };
  },
  computed: {
    technicianMachineGroup() {
      return this.$store.state.translation.i18n.locale;
    },
  },
  methods: {
    formatMachinesData() {
      return this.technicianDetails.tech_general_machines.map((machine) => {
        machine = this.addMachineFeatures(machine);
        return machine;
      });
    },

    addMachineFeatures(data) {
      data.name = data.machine_name;
      return data;
    },

    preselectTechMachines(machineList) {
      return machineList.filter((machine) => machine.is_assigned);
    },

    getNotAssignedList(machineList) {
      return machineList.filter((machine) => !machine.is_assigned);
    },

    formatSelectedItems(itemList) {
      return itemList.map((item) => {
        return {
          id: item.id,
          machine_role_id: this.technicianId,
          machine_group_id: this.selectedTechnicianMacGroupId,
        };
      });
    },

    formatUnSelectedItems(itemList) {
      return itemList.map((item) => {
        return {
          id: item.id,
          machine_role_id: 0,
          machine_group_id: this.selectedTechnicianMacGroupId,
        };
      });
    },

    assignMachineToTechnician() {
      this.$emit(
        "assign-machineToTechnician",
        this.formattedSelectedList.concat(this.formattedUnselectedList)
      );
    },

    getUnSelectedMachines() {
      return this.formattedMachines.filter(
        (item) => !this.formattedSelectedList.some((i) => i.id == item.id)
      );
    },
  },

  watch: {
    technicianDetails(val) {
      if (val) {
        this.formattedMachines = this.formatMachinesData();
        this.selectedTechMachines = this.preselectTechMachines(
          this.formattedMachines
        );
      }
    },

    selectedTechMachines(val) {
      if (val) {
        this.formattedSelectedList = this.formatSelectedItems(val);

        let unSelectedMachines = this.getUnSelectedMachines();
        this.formattedUnselectedList =
          this.formatUnSelectedItems(unSelectedMachines);
      }
    },
  },
};
</script>

<style scoped>
* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar {
  width: 9px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #eaecf0;
  border-radius: 90px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 90px;
}
</style>
