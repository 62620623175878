<template>
  <div class="bg-[#ffffff] h-[100vh] max-h-[90.5vh]">
    <div class="w-[94%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div class="flex items-center justify-between my-4">
            <div class="text-[18px] font-[600] text-[#101828]">
              {{ $t("faulttype.faultType") }}
            </div>
            <!-- Add Manual message dialog box -->
            <v-dialog v-model="dialogAdd" width="408">
              <template v-slot:activator="{ on, attrs }"
                ><v-btn
                  height="40"
                  v-bind="attrs"
                  v-on="on"
                  color="#f5f5ff"
                  depressed
                  class="add-btn"
                  >{{ $t("faulttype.add") }}</v-btn
                ></template
              >
              <v-card>
                <v-form ref="manualMessagesForm">
                  <v-card-title>
                    <div class="mx-auto font-semibold text-lg text-[#101828]">
                      {{ $t("faulttype.addFault") }}
                    </div>
                    <div>
                      <v-img
                        @click="dialogAdd = false"
                        :src="require(`../../assets/close-icon.svg`)"
                        :lazy-src="require(`../../assets/close-icon.svg`)"
                        class="cursor-pointer"
                      >
                      </v-img>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-label>{{ $t("faulttype.category") }}</v-label>
                    <v-select
                      :items="categoryNames"
                      :label="$t('faulttype.selectOne')"
                      item-text="name"
                      item-value="id"
                      v-model="selectedFaultTypeId"
                      dense
                      outlined
                      single-line
                    ></v-select>
                    <v-label>German</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="germanLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>English</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="englishLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>Romanian</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="romanianLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>Spanish</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="spanishLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>Chinese</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="chineseLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>Bosnian</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="bosnianLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <v-label>Serbian</v-label>
                    <v-text-field
                      :placeholder="$t('faulttype.waitingForShiftHandover')"
                      dense
                      ref="name"
                      v-model="serbianLang"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <div class="flex items-center justify-between">
                      <div>
                        <v-btn
                          color="#344054"
                          class="cancel-btn"
                          @click="dialogAdd = false"
                          depressed
                          outlined
                          width="174"
                          height="44"
                          >{{ $t("faulttype.cancel") }}</v-btn
                        >
                      </div>
                      <div>
                        <v-btn
                          class="save-btn"
                          depressed
                          color="primary"
                          width="174"
                          height="44"
                          :loading="addFaultTypeLoader"
                          @click="addFaultType"
                          >{{ $t("faulttype.save") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
          <v-divider class="my-6"></v-divider>

          <!-- Manual messages table -->
          <v-data-table
            :headers="
              manualMessagesLang === 'en'
                ? headers
                : manualMessagesLang === 'de'
                ? deHeaders
                : manualMessagesLang === 'cn'
                ? cnHeaders
                : manualMessagesLang === 'ro'
                ? roHeaders
                : manualMessagesLang === 'ba'
                ? baHeaders
                : manualMessagesLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="formattedFaultTypes"
            item-key="fault_id"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('userrole.nodata')"
          >
            <template v-slot:[`header.icons`]="{}">
              <div class="flex justify-end mt-5 mr-12">
                <div>{{ $t("faulttype.actions") }}</div>
              </div>
            </template>
            <template v-slot:[`item.icons`]="{ item }">
              <div class="flex items-center justify-end gap-5 pr-10">
                <div>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    width="400"
                    overlay-opacity="0.1"
                    content-class="elevation-0"
                  >
                    <template v-slot:activator="{}">
                      <v-img
                        class="cursor-pointer w-[15px]"
                        :src="require('../../assets/delete-icon.svg')"
                        :lazy-src="require('../../assets/delete-icon.svg')"
                        @click="showDeleteDialog(item)"
                      ></v-img>
                    </template>
                    <v-card class="pb-4">
                      <v-card-title class="flex justify-between">
                        <div class="font-semibold text-lg text-[#101828]">
                          {{
                            $t("faulttype.delete", {
                              "1": selectedFaultType?.name,
                            })
                          }}
                        </div>
                        <div>
                          <v-img
                            @click="dialogDelete = false"
                            :src="require(`../../assets/close-icon.svg`)"
                            :lazy-src="require(`../../assets/close-icon.svg`)"
                            class="cursor-pointer"
                          ></v-img>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="text-[#667085] font-[400] text-[14px]">
                          {{ $t("faulttype.areYouSure")
                          }}<span class="font-bold">{{
                            selectedFaultType?.name
                          }}</span
                          >{{ $t("faulttype.thisAction") }}
                        </div>
                      </v-card-text>
                      <div class="flex items-center justify-center gap-3">
                        <div>
                          <v-btn
                            outlined
                            depressed
                            width="170"
                            height="44"
                            class="cancel-btn"
                            @click="dialogDelete = false"
                            >{{ $t("faulttype.cancel") }}</v-btn
                          >
                        </div>
                        <div>
                          <v-btn
                            depressed
                            color="#D92D20"
                            width="170"
                            height="44"
                            class="fault-delete-btn"
                            :loading="deleteFaultTypeLoader"
                            @click="removeFaultType"
                            >{{ $t("faulttype.yesDelete") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                </div>
                <div>
                  <v-img
                    class="cursor-pointer w-[15px]"
                    :src="require('../../assets/edit-icon.svg')"
                    :lazy-src="require('../../assets/edit-icon.svg')"
                    @click="startEditFaultType(item)"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-data-table>

          <EditFaultTypeDialog
            :selectedFaultType="selectedFaultType"
            :categoryNames="categoryNames"
            @update-englishLang="updateEnglishLang"
            @update-germanLang="updateGermanLang"
            @update-romanianLang="updateRomanianLang"
            @update-spanishLang="updateSpanishLang"
            @update-chineseLang="updateChineseLang"
            @update-bosnianLang="updateBosnianLang"
            @update-serbianLang="updateSerbianLang"
            @update-faultTypeCategory="updateFaultTypeCategory"
            @update-faultType="updateFaultType"
            ref="EditFaultTypeDialogRef"
          />

          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay :value="allFaultTypesLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import EditFaultTypeDialog from "../../components/admin/EditManualMessageDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";
import Api from "../../services/api";

export default {
  components: {
    EditFaultTypeDialog,
    AlertComponent,
  },
  data() {
    return {
      dialogAdd: false,
      dialogDelete: false,
      allFaultTypesLoader: false,
      englishLang: "",
      germanLang: "",
      chineseLang: "",
      romanianLang: "",
      spanishLang: "",
      bosnianLang: "",
      serbianLang: "",
      selectedFaultTypeId: "",

      selectedFaultType: null,
      addFaultTypeLoader: false,
      deleteFaultTypeLoader: false,

      showAlert: false,

      formattedFaultTypes: [],
      categoryNames: ["NIO", "Fault", "Comment"],

      totalFaultTypesCount: 1,

      page: 1,
      totalPages: 1,
      perPage: 10,

      headers: [
        {
          text: "Name",
          align: "start",
          value: "english",
        },
        {},
        {
          text: "Category",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      deHeaders: [
        {
          text: "Name",
          align: "start",
          value: "german",
        },
        {},
        {
          text: "Kategorie",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      esHeaders: [
        {
          text: "Nombre",
          align: "start",
          value: "spanish",
        },
        {},
        {
          text: "Categoría",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      cnHeaders: [
        {
          text: "命名",
          align: "start",
          value: "chinese",
        },
        {},
        {
          text: "类别",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "romanian",
        },
        {},
        {
          text: "Categoria",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      baHeaders: [
        {
          text: "Ime",
          align: "start",
          value: "bosnian",
        },
        {},
        {
          text: "Kategorija",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      rsHeaders: [
        {
          text: "Име",
          align: "start",
          value: "serbian",
        },
        {},
        {
          text: "Категорија",
          value: "fault_category",
        },
        {
          text: "",
          value: "icons",
        },
      ],
      faultType: [
        {
          fault_type: "Broken case",
          fault_category: "NIO",
          icons: "",
        },
        {
          fault_type: "Shift handover",
          fault_category: "Comment",
          icons: "",
        },
      ],
    };
  },
  computed: {
    manualMessagesLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },
  methods: {
    getAllFaultTypes(showLoader) {
      this.allFaultTypesLoader = showLoader;
      Api()
        .get(`/machines/all-reason-types/${this.page}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.formattedFaultTypes = [
              ...this.formatFaultTypesData(res.data.data),
            ];
            this.totalPages = res.data.total_pages;
            this.totalFaultTypesCount = res.data.total_reasons;
          }
          this.allFaultTypesLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allFaultTypesLoader = false;
        });
    },
    showEditModal() {
      this.$refs.EditFaultTypeDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditFaultTypeDialogRef.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditFaultTypeDialogRef.stopEditLoader();
    },

    closeAddManualMessagesDialog() {
      this.$refs.manualMessagesForm.reset();
    },

    hideAlert() {
      this.showAlert = false;
    },
    handlePageChange(value) {
      this.page = value;
      this.getAllFaultTypes(true);
      this.scrollToTop();
    },

    formatFaultTypesData(faultTypes) {
      return faultTypes.map((faultType) => {
        faultType = this.addFaultTypeFeatures(faultType);
        return faultType;
      });
    },

    // Adding a faultType
    addFaultTypeFeatures(faultType) {
      if (this.manualMessagesLang === "en") {
        faultType.fault_type = faultType.english;
      } else if (this.manualMessagesLang === "de") {
        faultType.fault_type = faultType.german;
      } else if (this.manualMessagesLang === "cn") {
        faultType.fault_type = faultType.chinese;
      } else if (this.manualMessagesLang === "ro") {
        faultType.fault_type = faultType.romanian;
      } else if (this.manualMessagesLang === "es") {
        faultType.fault_type = faultType.spanish;
      } else if (this.manualMessagesLang === "ba") {
        faultType.fault_type = faultType.bosnian;
      } else faultType.fault_type = faultType.bosnian;

      faultType.fault_category = faultType.category;
      faultType.fault_id = faultType.category + faultType.id;

      return faultType;
    },

    addFaultType() {
      this.addFaultTypeLoader = true;
      let newData = {
        english: this.englishLang,
        german: this.germanLang,
        chinese: this.chineseLang,
        romanian: this.romanianLang,
        spanish: this.spanishLang,
        bosnian: this.bosnianLang,
        serbian: this.serbianLang,
        category: this.selectedFaultTypeId,
      };
      Api()
        .post("/machines/add-reason-type/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.addToFaultTypeList(res.data.data);
            this.removeLastFaultType();
            this.dialogAdd = false;
            this.showSuccess(res.data.detail);
          }
          this.addFaultTypeLoader = false;
          this.resetFaultTypeData();
        })
        .catch((err) => {
          this.showError(err);
          this.addFaultTypeLoader = false;
        });
    },

    // Deleting a fault type
    showDeleteDialog(data) {
      this.dialogDelete = true;
      this.selectedFaultType = { ...data };
    },

    removeFromFaultTypeList(faultTypeId) {
      let faultTypes = [...this.formattedFaultTypes];
      this.formattedFaultTypes = faultTypes.filter(
        (faultType) => faultType.fault_id != faultTypeId
      );
    },

    removeFaultType() {
      this.deleteFaultTypeLoader = true;
      Api()
        .delete(`/machines/delete-reason-type/${this.selectedFaultType.id}/`, {
          data: { category: this.selectedFaultType.category },
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.removeFromFaultTypeList(this.selectedFaultType.fault_id);
            this.getAllFaultTypes(false);
            this.dialogDelete = false;
            this.showSuccess(res.data.detail);
          }
          this.deleteFaultTypeLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.deleteFaultTypeLoader = false;
        });
    },

    // Updating a fault type
    startEditFaultType(data) {
      this.selectedFaultType = { ...data };
      this.showEditModal();
    },

    updateFaultType(data) {
      Api()
        .put(`/machines/update-reason-type/${data.id}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.closeEditModal();
            this.getAllFaultTypes(false);
          }
          this.stopEditLoader();
        })
        .catch((err) => {
          this.showError(err);
          this.stopEditLoader();
        });
    },

    updateFaultTypeLlist(faultType) {
      faultType = this.addFaultTypeFeatures(faultType);
      this.findAndReplaceFaultType(faultType);
    },

    findAndReplaceFaultType(updatedFaultType) {
      let foundIndex = this.formattedFaultTypes.findIndex(
        (faultType) => faultType.fault_id == updatedFaultType.fault_id
      );
      if (foundIndex == 0 || foundIndex) {
        this.formattedFaultTypes.splice(foundIndex, updatedFaultType);
      }
    },

    resetFaultTypeData() {
      this.englishLang = "";
      this.germanLang = "";
      this.chineseLang = "";
      this.romanianLang = "";
      this.spanishLang = "";
      this.bosnianLang = "";
      this.serbianLang = "";
      this.selectedFaultTypeId = "";
    },

    removeLastFaultType() {
      if (this.perPage < this.totalFaultTypesCount) {
        this.formattedFaultTypes.splice(this.perPage, 1);
      }
    },

    addToFaultTypeList(faultType) {
      faultType = this.addFaultTypeFeatures(faultType);
      this.formattedFaultTypes.unshift(faultType);
      this.totalFaultTypesCount++;
      this.totalPages = Math.ceil(this.totalFaultTypesCount / this.perPage);
    },

    updateEnglishLang(data) {
      this.selectedFaultType.english = data;
    },
    updateGermanLang(data) {
      this.selectedFaultType.german = data;
    },
    updateRomanianLang(data) {
      this.selectedFaultType.romanian = data;
    },
    updateSpanishLang(data) {
      this.selectedFaultType.spanish = data;
    },
    updateChineseLang(data) {
      this.selectedFaultType.chinese = data;
    },
    updateBosnianLang(data) {
      this.selectedFaultType.bosnian = data;
    },
    updateSerbianLang(data) {
      this.selectedFaultType.serbian = data;
    },

    updateFaultTypeCategory(data) {
      this.selectedFaultType.category = data;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllFaultTypes(true);
  },

  watch: {
    dialogAdd(val) {
      !val && this.closeAddManualMessagesDialog();
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.fault-delete-btn {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
