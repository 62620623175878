import { render, staticRenderFns } from "./AddNioDialog.vue?vue&type=template&id=0da6c250&scoped=true"
import script from "./AddNioDialog.vue?vue&type=script&lang=js"
export * from "./AddNioDialog.vue?vue&type=script&lang=js"
import style0 from "./AddNioDialog.vue?vue&type=style&index=0&id=0da6c250&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da6c250",
  null
  
)

export default component.exports