import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/tailwind.css";
import i18n from "./i18n";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from "moment-timezone";

// Sentry Configuration
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
const env = process.env.VUE_APP_ENVIRONMENT;

window.Fire = new Vue();
Sentry.init({
  Vue,
  dsn: "https://f5ee772af2be4330a775a65f5581fd60@o1182568.ingest.sentry.io/4504197643501568",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "staging.dspcloud.aftcloud.de", /^\//],
    }),
    new Sentry.Replay(),
  ],
  environment: env,

  tracesSampler: () => {
    if (env === "production") {
      return 0.5;
    } else if (env === "staging") {
      return 0.25;
    } else if (env === "local") {
      return 0;
    } else {
      return 0.1;
    }
  },
  sampleRate: process.env.ISLOCAL ? 0.0 : 0.25,

  logErrors: true,

  replaysSessionSampleRate: 0.25,

  replaysOnErrorSampleRate: 1.0,
});

Vue.config.productionTip = false;

const options = {
  draggable: false,
};
Vue.use(Toast, options);

moment.tz.setDefault("Europe/Berlin");

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
