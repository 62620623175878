<template>
  <div>
    <v-dialog v-model="dialogFaults" width="455" scrollable>
      <v-card>
        <v-card-title
          ><div class="mx-auto">
            <div class="text-[18px] text-[#101828] font-[600]">
              {{ $t("shlview.faults") }}
            </div>
          </div></v-card-title
        >
        <v-card-text>
          <div class="select-reason">
            <v-label>{{ $t("shlview.selectReason") }}</v-label>
          </div>
          <v-select
            class="w-[360px] h-[48px]"
            :placeholder="$t('shlview.waitingForMachineRepair')"
            :items="faultReasons"
            :item-text="formattedLang"
            item-value="id"
            v-model="selectedFaultId"
            dense
            outlined
          ></v-select>
          <div class="flex justify-start gap-2 pt-4">
            <div class="w-[185px]">
              <v-label>{{ $t("shlview.start") }}</v-label>
              <v-text-field
                v-model="dateTimeStart"
                class="w-[184px] h-[44px]"
                placeholder="12-09-22 11:34"
                dense
                outlined
                append-icon="$vuetify.icons.calendar_icon"
                @click:append="showStartDateTimeModal"
                readonly
                @click="showStartDateTimeModal"
              ></v-text-field>
            </div>
            <div class="w-[185px]" v-if="faultsStopTime">
              <v-label>{{ $t("shlview.stop") }}</v-label>
              <v-text-field
                v-model="dateTimeStop"
                class="w-[184px] h-[44px]"
                placeholder="12-09-22 11:34"
                dense
                outlined
                append-icon="$vuetify.icons.calendar_icon"
                @click:append="showStopDateTimeModal"
                readonly
                @click="showStopDateTimeModal"
              ></v-text-field>
            </div>
          </div>
          <div>
            <v-row>
              <v-col cols="6">
                <v-checkbox v-model="faultsStopTime"
                  ><template v-slot:label
                    ><span class="text-[#344054] text-[14px] font-[400]">{{
                      $t("shlview.addStopTime")
                    }}</span></template
                  ></v-checkbox
                >
              </v-col>
            </v-row>
          </div>
          <div class="flex justify-center gap-2">
            <div>
              <v-btn
                class="faults-cancel-btn"
                color="#ffffff"
                depressed
                width="197"
                height="44"
                @click="dialogFaults = false"
                >{{ $t("shlview.cancel") }}</v-btn
              >
            </div>
            <div>
              <v-btn
                class="faults-save-btn"
                color="primary"
                depressed
                width="197"
                height="44"
                :loading="addFaultLoader"
                @click="addMachineFault"
                >{{ $t("shlview.save") }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <StartDateTimeModal
      :dateTimeStart="dateTimeStart"
      @save-startDateTime="saveStartDateTime"
      @cancel-startDateTime="cancelStartDateTime"
      ref="StartDateTimeRef"
    />
    <StopDateTimeModal
      :dateTimeStop="dateTimeStop"
      @save-stopDateTime="saveStopDateTime"
      @cancel-stopDateTime="cancelStopDateTime"
      ref="StopDateTimeRef"
    />
  </div>
</template>

<script>
import StartDateTimeModal from "../../components/StartDateTimeModal.vue";
import StopDateTimeModal from "../../components/StopDateTimeModal.vue";
import moment from "moment";

export default {
  props: ["faultReasons", "formattedLang"],
  components: { StartDateTimeModal, StopDateTimeModal },
  data() {
    return {
      dialogFaults: false,
      selectedFaultId: "",
      dateTimeStart:
        moment().format().slice(0, 10) + " " + moment().format("HH:mm"),
      dateTimeStop: "",
      addFaultLoader: false,
      faultsStopTime: false,
    };
  },

  methods: {
    saveStartDateTime(data) {
      this.dateTimeStart = data;
    },

    cancelStartDateTime() {
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
    },

    showStartDateTimeModal() {
      this.$refs.StartDateTimeRef?.showStartDateTimeModal();
    },

    showStopDateTimeModal() {
      this.$refs.StopDateTimeRef?.showStopDateTimeModal();
    },

    saveStopDateTime(data) {
      this.dateTimeStop = data;
    },

    cancelStopDateTime() {
      this.dateTimeStop = "";
    },

    addMachineFault() {
      this.addFaultLoader = true;
      let newData = {
        reason_type: this.selectedFaultId,
        start_time: this.dateTimeStart ? this.dateTimeStart + ":00" : null,
        end_time: this.dateTimeStop ? this.dateTimeStop + ":00" : null,
      };

      this.$emit("add-machineFault", newData);
    },

    showFaultDialog() {
      this.dialogFaults = true;
    },

    closeAddFaultDialog() {
      this.dialogFaults = false;
    },

    stopAddFaultLoader() {
      this.addFaultLoader = false;
    },

    resetFaultData() {
      this.dateTimeStart =
        moment().format().slice(0, 10) + " " + moment().format("HH:mm");
      this.dateTimeStop = "";
      this.selectedFaultId = "";
      this.faultsStopTime = true;
    },

    clearFaultReason() {
      this.selectedFaultId = "";
    },
  },

  watch: {
    faultsStopTime(val) {
      if (!val) {
        this.cancelStopDateTime();
      }
    },

    dialogFaults(val) {
      if (!val) {
        this.clearFaultReason();
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-text-field {
  border-radius: 8px !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.faults-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #344054;
  border: 1px solid #d0d5dd !important;
}

.faults-save-btn {
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px !important;
  color: #ffffff;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
