export default {
  namespaced: true,
  state: {
    machineStatus: localStorage.getItem("aftMacStatus")
      ? localStorage.getItem("aftMacStatus")
      : "Automatic",
    machineName: localStorage.getItem("aftMacName")
      ? localStorage.getItem("aftMacName")
      : "",
    labelStationData: null,
    digitalStationData: null,
    otherMachineStatus: {},
    labelStationCard: "",
    digitalStationCard: "",
  },

  mutations: {
    SET_DIGITAL_STATION_CARD_DATA(state, data) {
      state.digitalStationCard = data;
      state.digitalStationCardData = data.message;
    },

    SET_LABEL_STATION_CARD_DATA(state, data) {
      state.labelStationCard = data;
      state.labelStationCardData = data.message;
    },

    SET_MACHINE_STATUS(state, data) {
      state.machineStatus = data;
      localStorage.setItem("aftMacStatus", data);
    },

    SET_MACHINE_NAME(state, data) {
      state.machineName = data;
      localStorage.setItem("aftMacName", data);
    },

    SET_OTHER_MACHINE_STATUS(state, data) {
      state.otherMachineStatus = data;
    },
  },

  actions: {
    setMachineStatus({ commit }, data) {
      commit("SET_MACHINE_STATUS", data);
    },

    setMachineName({ commit }, data) {
      commit("SET_MACHINE_NAME", data);
    },
  },
};
