<template>
  <!-- Edit User Role dialog box -->
  <v-dialog :retain-focus="false" v-model="editDialog" width="480">
    <v-card>
      <v-card-title
        ><div class="mx-auto font-semibold text-lg text-[#101828]">
          {{ $t("edituserroledialog.editUserRole") }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-label class="text-[#344054] font-[400]">{{
          $t("edituserroledialog.name")
        }}</v-label>
        <v-text-field
          class="w-[360px] mb-7"
          :placeholder="$t('edituserroledialog.forEgAdmin')"
          v-model="newUserRoleName"
          dense
          ref="name"
          outlined
          color="#667085"
        ></v-text-field>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("edituserroledialog.members") }}
            </div>
            <div class="flex items-center justify-center">
              <div>
                <v-switch
                  v-model="newMembersViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMembersViewSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("edituserroledialog.informationRelatedTo") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newMembersAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMembersAddSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newMembersDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMembersDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.delete')" />
            </div>
          </div>
        </div>

        <v-divider class="my-3"></v-divider>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("edituserroledialog.machines") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newMachinesViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMachinesViewSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("edituserroledialog.informationRelatedTo") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newMachinesAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMachinesAddSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newMachinesDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newMachinesDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.delete')" />
            </div>
          </div>
        </div>

        <v-divider class="my-3"></v-divider>
        <div>
          <div class="flex justify-between items-center">
            <div class="text-[#344054] font-[400]">
              {{ $t("edituserroledialog.configurations") }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newConfigViewSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newConfigViewSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.view')" />
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-[#667085] font-[400]">
              {{ $t("edituserroledialog.willBeAble") }}<br />{{
                $t("edituserroledialog.andMachineGroups")
              }}
            </div>
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newConfigAddSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newConfigAddSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.add')" />
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div class="flex items-center justify-between">
              <div>
                <v-switch
                  v-model="newConfigDeleteSwitch"
                  inset
                  hide-details
                  color="#ffffff"
                  :class="
                    newConfigDeleteSwitch ? 'clicked-switch switch' : 'switch'
                  "
                ></v-switch>
              </div>
              <switch-label :title="$t('edituserroledialog.delete')" />
            </div>
          </div>
        </div>
        <v-divider class="my-3"></v-divider>
        <div class="flex justify-between items-center">
          <div class="text-[#667085] font-[400]">
            {{ $t("edituserroledialog.allShiftlogs") }}
          </div>
          <div class="flex items-center justify-between">
            <div>
              <v-switch
                v-model="newShiftlogViewSwitch"
                inset
                hide-details
                color="#ffffff"
                :class="
                  newShiftlogViewSwitch ? 'clicked-switch switch' : 'switch'
                "
              ></v-switch>
            </div>
            <switch-label :title="$t('edituserroledialog.view')" />
          </div>
        </div>

        <div class="flex justify-between items-center gap-3 mt-3">
          <div>
            <v-btn
              color="#ffffff"
              class="cancel-btn"
              @click="editDialog = false"
              depressed
              outlined
              width="210"
              height="44"
              >{{ $t("edituserroledialog.cancel") }}</v-btn
            >
          </div>
          <div>
            <v-btn
              class="update-btn"
              depressed
              color="primary"
              width="210"
              height="44"
              :loading="editUserRoleLoader"
              @click="editUserRole"
              >{{ $t("edituserroledialog.update") }}</v-btn
            >
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SwitchLabel from "../SwitchLabel.vue";
export default {
  props: ["selectedUserRole"],
  components: { SwitchLabel },
  data() {
    return {
      editDialog: false,
      editUserRoleLoader: false,
    };
  },

  computed: {
    newUserRoleName: {
      get() {
        return this.selectedUserRole?.user_role_name;
      },
      set(val) {
        this.$emit("update-userRoleName", val);
      },
    },

    // members related
    newMembersViewSwitch: {
      get() {
        return this.selectedUserRole?.view_member;
      },
      set(val) {
        this.$emit("update-membersViewSwitch", val);
      },
    },

    newMembersAddSwitch: {
      get() {
        return this.selectedUserRole?.add_member;
      },
      set(val) {
        this.$emit("update-membersAddSwitch", val);
      },
    },

    newMembersDeleteSwitch: {
      get() {
        return this.selectedUserRole?.delete_member;
      },
      set(val) {
        this.$emit("update-membersDeleteSwitch", val);
      },
    },

    // machine related
    newMachinesViewSwitch: {
      get() {
        return this.selectedUserRole?.view_machine;
      },
      set(val) {
        this.$emit("update-machinesViewSwitch", val);
      },
    },

    newMachinesAddSwitch: {
      get() {
        return this.selectedUserRole?.add_machine;
      },
      set(val) {
        this.$emit("update-machinesAddSwitch", val);
      },
    },

    newMachinesDeleteSwitch: {
      get() {
        return this.selectedUserRole?.delete_machine;
      },
      set(val) {
        this.$emit("update-machinesDeleteSwitch", val);
      },
    },

    // config related
    newConfigViewSwitch: {
      get() {
        return this.selectedUserRole?.read_config;
      },
      set(val) {
        this.$emit("update-configViewSwitch", val);
      },
    },

    newConfigAddSwitch: {
      get() {
        return this.selectedUserRole?.add_config;
      },
      set(val) {
        this.$emit("update-configAddSwitch", val);
      },
    },

    newConfigDeleteSwitch: {
      get() {
        return this.selectedUserRole?.delete_config;
      },
      set(val) {
        this.$emit("update-configDeleteSwitch", val);
      },
    },

    // all shift
    newShiftlogViewSwitch: {
      get() {
        return this.selectedUserRole?.view_shiftlog;
      },
      set(val) {
        this.$emit("update-shiftlogViewSwitch", val);
      },
    },
  },

  methods: {
    showEditDialog() {
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },

    stopEditLoader() {
      this.editUserRoleLoader = false;
    },

    editUserRole() {
      let newData = {
        name: this.newUserRoleName,
        view_member: this.newMembersViewSwitch ? 1 : 0,
        add_member: this.newMembersAddSwitch ? 1 : 0,
        delete_member: this.newMembersDeleteSwitch ? 1 : 0,
        view_machine: this.newMachinesViewSwitch ? 1 : 0,
        add_machine: this.newMachinesAddSwitch ? 1 : 0,
        delete_machine: this.newMachinesDeleteSwitch ? 1 : 0,
        read_config: this.newConfigViewSwitch ? 1 : 0,
        add_config: this.newConfigAddSwitch ? 1 : 0,
        delete_config: this.newConfigDeleteSwitch ? 1 : 0,
        view_shiftlog: this.newShiftlogViewSwitch ? 1 : 0,
        user_role_id: this.selectedUserRole.user_role_id,
      };

      this.editUserRoleLoader = true;
      this.$emit("update-userRole", newData);
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-input--selection-controls {
  padding: 10 !important;
  margin: 0 !important;
}

* /deep/ .v-input__control {
  display: flex;
  flex-direction: column;
  height: 32px !important;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #0408e7;
  opacity: 1;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  color: #344054 !important;
}

.update-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.group-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.group-delete-btn {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 8px !important;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #dad5d5;
}

* /deep/.switch.clicked-switch .v-input--switch__track {
  background-color: #0408e7;
  opacity: 1;
}
</style>
