<template>
  <div
    class="w-full bg-[#ffffff] border border-[#D0D5DD] rounded-lg flex flex-row justify-between items-center align-middle"
  >
    <div class="py-5 pl-5">
      <v-img src="@/assets/searchIcon.svg"></v-img>
    </div>
    <input
      class="p-4 mx-auto flex flex-1 flex-row justify-between align-middle outline-none"
      type="text"
      :placeholder="$t('theinput.search')"
      v-model="search"
      @keyup.enter="fetchSearchItems"
    />
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import Api from "../services/api";
export default {
  data() {
    return {
      search: "",
    };
  },
  watch: {
    search(...args) {
      this.debounceSearchResults(args);
    },
  },
  methods: {
    fetchSearchItems() {
      const payload = {
        key_word: this.search,
      };
      Api()
        .post(`/machines/search-machines/`, payload)
        .then((res) => {
          const searchResult = res.data.data;
          if (searchResult) {
            // eslint-disable-next-line no-undef
            Fire.$emit("search-event", searchResult);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.debounceSearchResults = debounce(this.fetchSearchItems, 100);
  },
};
</script>
