<template>
  <div class="bg-[#ffffff]">
    <div class="w-[94%] flex justify-start pl-10">
      <div class="w-[10%] flex flex-col items-center mt-4 gap-2">
        <div>
          <!-- Edit Profile sidetab  -->
          <router-link :to="{ name: 'adminEditProfile' }"
            ><div
              class="flex justify-center items-center text-[#667085] text-[14px] font-[400] w-[120px] h-[36px] rounded-[6px]"
              :class="
                currentTab.includes('/admin/edit-profile')
                  ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                  : ''
              "
            >
              {{ $t("editprofileview.editProfile") }}
            </div></router-link
          >
        </div>

        <div>
          <!-- Change Password sidetab  -->
          <router-link :to="{ name: 'adminChangePassword' }"
            ><div
              class="flex justify-center items-center text-[#667085] text-[14px] font-[400] w-[120px] h-[36px] rounded-[6px]"
            >
              {{ $t("editprofileview.password") }}
            </div></router-link
          >
        </div>
      </div>
      <div class="w-[40%]">
        <v-card ref="form" elevation="0">
          <!-- Edit Profile form  -->
          <v-form ref="updateForm" v-model="updateFormValid" lazy-validation>
            <v-card-text>
              <v-label>{{ $t("editprofileview.name") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="name"
                :placeholder="$t('editprofileview.oliviaRhyde')"
                outlined
                color="#667085"
              ></v-text-field>
              <v-divider class="pb-4"></v-divider>
              <div class="flex justify-end gap-5">
                <div>
                  <v-btn
                    class="cancel-btn !bg-[#ffffff]"
                    elevation="0"
                    @click="goToHome()"
                    >{{ $t("editprofileview.cancel") }}</v-btn
                  >
                </div>
                <div>
                  <v-btn
                    color="primary"
                    class="update-btn"
                    elevation="0"
                    :loading="updateLoader"
                    @click="updateHandler"
                    >{{ $t("editprofileview.update") }}</v-btn
                  >
                </div>
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </div>
    </div>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import validation from "../../services/validation";
import Api from "../../services/api";
import { mapActions } from "vuex";
import AlertComponent from "../../components/AlertComponent.vue";

export default {
  components: { AlertComponent },

  data() {
    return {
      currentTab: this.$router.history.current.fullPath,
      name: this.$store.state.authentication.currentUser?.data?.user?.name,
      updateFormValid: true,
      ...validation,
      updateLoader: false,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.authentication.currentUser.data.user;
    },
  },

  methods: {
    updateHandler() {
      if (!this.name) return;
      this.updateLoader = true;
      Api()
        .put(`/auth/update-user/${this.currentUser.user_key}/`, {
          name: this.name,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateCurrentUserFullname(this.name);
          }
          this.updateLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.updateLoader = false;
        });
    },

    resetData() {
      this.name = "";
    },

    goToHome() {
      this.$router.push("/");
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
    },

    ...mapActions("authentication", ["updateCurrentUserFullname"]),
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.theme--light.v-divider {
  color: #eaecf0;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  padding-top: 5px;
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.update-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
