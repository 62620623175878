import axios from "axios";
import config from "../../public/config";

export default () => {
  const customAxios = axios.create({
    baseURL: config.api,
    withCredentials: false,
    headers: {
      Accept: "Application/json",
      "Content-Type": "Application/json",
    },
  });

  const requestHandler = async (request) => {
    return request;
  };

  customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => {
      console.log(error, "error interceptors");
    }
  );

  return customAxios;
};
