<template>
  <!-- Edit Machine dialog box  -->
  <v-dialog :retain-focus="false" v-model="editDialog" width="577">
    <div class="w-[577px]">
      <v-card class="p-1">
        <v-card-title>
          <div class="mx-auto font-semibold text-lg text-[#101828]">
            {{ $t("machineview.editMachine") }}
          </div>
          <div>
            <v-img
              @click="editDialog = false"
              :src="require(`../../assets/close-icon.svg`)"
              :lazy-src="require(`../../assets/close-icon.svg`)"
              class="cursor-pointer"
            >
            </v-img>
          </div>
        </v-card-title>
        <v-card-text>
          <v-label>{{ $t("machineview.name") }}</v-label>
          <v-text-field
            dense
            ref="name"
            v-model="newMachineName"
            outlined
            color="#667085"
          ></v-text-field>
          <div class="flex items-center justify-between gap-4">
            <div>
              <v-label>{{ $t("machineview.number") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachineNumber"
                outlined
                color="#667085"
                class="w-[300px]"
                :rules="[noSpaceAllowed(machineViewLang)]"
              ></v-text-field>
            </div>
            <div>
              <v-label>{{ $t("machineview.users") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachineUsers"
                outlined
                color="#667085"
                :rules="[onlyNumbers(machineViewLang)]"
              ></v-text-field>
            </div>
          </div>
          <v-label>{{ $t("machineview.assignGroup") }}</v-label>
          <v-select
            :label="$t('machineview.select')"
            :items="machineGroups"
            item-text="name"
            item-value="id"
            v-model="selectedMachineGroupId"
            dense
            outlined
            single-line
          ></v-select>
          <div class="flex justify-start gap-4">
            <div>
              <v-label>{{ $t("machineview.pin") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachinePin"
                outlined
                color="#667085"
                :rules="[onlyNumbers(machineViewLang)]"
              ></v-text-field>
            </div>
            <div>
              <v-label>{{ $t("machineview.totalFault") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachineFault"
                outlined
                color="#667085"
              ></v-text-field>
            </div>
            <div>
              <v-label>{{ $t("machineview.totalNIO") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachineNio"
                outlined
                color="#667085"
              ></v-text-field>
            </div>
          </div>
          <div class="flex justify-start gap-4">
            <div>
              <v-label>{{ $t("machineview.imei") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachineImei"
                outlined
                color="#667085"
              ></v-text-field>
            </div>
            <div>
              <v-label>{{ $t("machineview.setPoint") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newMachinePoint"
                outlined
                color="#667085"
              ></v-text-field>
            </div>
            <div>
              <v-label>{{ $t("machineview.aftSite") }}</v-label>
              <v-text-field
                dense
                ref="name"
                v-model="newAftSite"
                outlined
                color="#667085"
              ></v-text-field>
            </div>
          </div>
          <div class="flex justify-between">
            <div>
              <v-btn
                elevation="0"
                class="cancel-btn !bg-[#ffffff]"
                @click="editDialog = false"
                >{{ $t("machineview.cancel") }}</v-btn
              >
            </div>
            <div>
              <v-btn
                elevation="0"
                depressed
                color="primary"
                class="save-btn"
                :loading="editMachineLoader"
                @click="editMachine"
                >{{ $t("machineview.save") }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import validation from "../../services/validation";

export default {
  props: ["selectedMachine", "machineGroups"],
  data() {
    return {
      editDialog: false,
      show1: false,
      editMachineLoader: false,
      selectedMachineGroupId: "",
      ...validation,
    };
  },

  computed: {
    machineViewLang() {
      return this.$store.state.translation.i18n.locale;
    },

    newMachineName: {
      get() {
        return this.selectedMachine?.machine_name;
      },
      set(val) {
        this.$emit("update-machineName", val);
      },
    },

    newMachineNumber: {
      get() {
        return this.selectedMachine?.machine_number;
      },
      set(val) {
        this.$emit("update-machineNumber", val);
      },
    },

    newMachineUsers: {
      get() {
        return this.selectedMachine?.number_of_logged_in_users;
      },
      set(val) {
        this.$emit("update-machineUsers", val);
      },
    },

    newMachinePin: {
      get() {
        return this.selectedMachine?.pin;
      },
      set(val) {
        this.$emit("update-machinePin", val);
      },
    },

    newMachineImei: {
      get() {
        return this.selectedMachine?.imei_number;
      },
      set(val) {
        this.$emit("update-machineImei", val);
      },
    },

    newMachinePoint: {
      get() {
        return this.selectedMachine?.set_point;
      },
      set(val) {
        this.$emit("update-machinePoint", val);
      },
    },

    newMachineFault: {
      get() {
        return this.selectedMachine?.max_fault;
      },
      set(val) {
        this.$emit("update-machineFault", val);
      },
    },

    newMachineNio: {
      get() {
        return this.selectedMachine?.max_nio;
      },
      set(val) {
        this.$emit("update-machineNio", val);
      },
    },

    newAftSite: {
      get() {
        return this.selectedMachine?.aft_site;
      },
      set(val) {
        this.$emit("update-aftSite", val);
      },
    },
  },

  methods: {
    showEditDialog() {
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },

    stopEditLoader() {
      this.editMachineLoader = false;
    },

    editMachine() {
      let newData = {
        machine_name: this.newMachineName,
        machine_number: this.newMachineNumber,
        number_of_logged_in_users: this.newMachineUsers,
        pin: this.newMachinePin,
        machine_group_id: this.selectedMachineGroupId,
        imei_number: this.newMachineImei,
        set_point: this.newMachinePoint,
        id: this.selectedMachine.id,
        max_fault: this.newMachineFault,
        max_nio: this.newMachineNio,
        aft_site: this.newAftSite,
      };

      this.editMachineLoader = true;
      this.$emit("update-machine", newData);
    },

    getSelectedGroupId() {
      let groupName = this.selectedMachine?.machine_group__name;
      this.machineGroups.forEach((group) => {
        if (group.name == groupName) {
          this.selectedMachineGroupId = group.id;
        }
      });
    },
  },

  watch: {
    editDialog(val) {
      if (val) {
        this.getSelectedGroupId();
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}
</style>
