export const actionLogTypes = {
  automaticMode: 1,
  stop: 2,
  prepare: 3,
  cleaning: 4,
  employeeBreak: 5,
  plannedStop: 6,
  unplannedStop: 7,
  stopBreak: 8,
};

export function checkLanguage(currentLang) {
  if (currentLang == "en") {
    return "english";
  } else if (currentLang == "de") {
    return "german";
  } else if (currentLang == "cn") {
    return "chinese";
  } else if (currentLang == "ro") {
    return "romanian";
  } else if (currentLang == "es") {
    return "spanish";
  } else if (currentLang == "ba") {
    return "bosnian";
  } else {
    return "serbian";
  }
}
