<template>
  <v-app-bar
    color="#ffffff"
    class="px-8 !border-b-[1px] !border-[#EAECF0]"
    height="72"
    app
    flat
  >
    <v-row>
      <v-col cols="1">
        <div class="w-[52px] h-[34.07px]">
          <v-img :src="require(`../assets/aft-logo.svg`)"></v-img>
        </div>
      </v-col>
      <!-- Admin Navigation bar -->
      <v-col
        v-if="isAdminRole"
        cols="10"
        class="flex items-center justify-between"
      >
        <div class="flex items-center gap-2">
          <!-- Home tab -->
          <button>
            <router-link
              :to="{ name: 'home' }"
              :class="
                currentTab == '/' ||
                currentTab.includes('/admin-shl') ||
                currentTab.includes('/admin-atl') ||
                currentTab.includes('/all-shifts')
                  ? 'nav-links-item-active rounded-[6px] bg-[#F5F5FF]'
                  : 'w-[96px] h-[40px] rounded-[6px] nav-links-item'
              "
              >{{ $t("thenavbar.home") }}</router-link
            >
          </button>
          <!-- Machines tab  -->
          <button>
            <router-link
              :to="{ name: 'machine' }"
              :class="
                currentTab == '/machine'
                  ? 'nav-links-item-active rounded-[6px] bg-[#F5F5FF]'
                  : 'w-[96px] h-[40px] rounded-[6px] nav-links-item'
              "
              >{{ $t("thenavbar.machines") }}</router-link
            >
          </button>
          <!-- Members tab  -->
          <button>
            <router-link
              :to="{ name: 'users' }"
              :class="
                currentTab == '/users' ||
                currentTab.includes('/admin/assigned-worker')
                  ? 'nav-links-item-active rounded-[6px] bg-[#F5F5FF]'
                  : 'w-[96px] h-[40px] rounded-[6px] nav-links-item'
              "
              >{{ $t("thenavbar.members") }}</router-link
            >
          </button>
          <!-- Configuation tab  -->
          <v-menu
            offset-y
            rounded="lg"
            min-width="240"
            content-class="configuration-container"
          >
            <template v-slot:activator="{ on }">
              <button
                v-on="on"
                class="flex items-center justify-center gap-2"
                :class="
                  currentTab == '/machine-group' ||
                  currentTab == '/user-role' ||
                  currentTab == '/manual-messages' ||
                  currentTab == '/automatic-messages'
                    ? 'nav-links-item-active rounded-[6px] bg-[#F5F5FF]'
                    : 'nav-links-item'
                "
              >
                <div>{{ $t("thenavbar.configuration") }}</div>
                <div class="w-[10px] h-[5px]">
                  <v-img :src="require('../assets/chevron-down.svg')"></v-img>
                </div>
              </button>
            </template>
            <v-list class="py-0 configuration-list">
              <div v-if="storeLang === 'en'">
                <v-list-item
                  v-for="(configuration, index) in configurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'de'">
                <v-list-item
                  v-for="(configuration, index) in deConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'ro'">
                <v-list-item
                  v-for="(configuration, index) in roConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'cn'">
                <v-list-item
                  v-for="(configuration, index) in cnConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'es'">
                <v-list-item
                  v-for="(configuration, index) in esConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'ba'">
                <v-list-item
                  v-for="(configuration, index) in baConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
              <div v-if="storeLang === 'rs'">
                <v-list-item
                  v-for="(configuration, index) in rsConfigurations"
                  :key="index"
                  class="configuration-items border-b-2 border-[#F2F4F7]"
                  :to="configuration.link"
                  ><v-list-item-title
                    ><div class="text-[14px] text-[#344054] font-[400]">
                      {{ configuration.title }}
                    </div></v-list-item-title
                  >
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </div>
        <div class="pr-4">
          <div class="w-[18px] h-[16.67px]">
            <v-img :src="require(`../assets/bell-icon.svg`)"></v-img>
          </div>
        </div>
      </v-col>
      <!-- Manual Workers Navigation bar -->
      <v-col
        v-else-if="isManualRole"
        :cols="isLoggedIn ? '10' : '8'"
        class="items-center pr-14"
      >
        <div class="flex text-[16px] font-[400] items-center gap-2 pr-14">
          <div class="flex gap-2">
            <!-- Automatic labeling tab  -->
            <router-link
              :to="`${this.isManual === 'Manual' ? '/manual-worker' : ''}/atl/${
                this.routeMachineId || this.aftMacId
              }`"
            >
              <div
                class="w-[212px] h-[40px] flex justify-center items-center text-[#344054]"
                :class="
                  currentTab.includes('/atl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("thenavbar.automaticLabeling") }}
              </div>
            </router-link>

            <!-- Shiftlog tab -->
            <router-link
              :to="`/${
                this.isManual === 'Manual' ? '/manual-worker' : ''
              }/shl/${this.routeMachineId || this.aftMacId}`"
            >
              <div
                class="w-[212px] h-[40px] flex justify-center items-center text-[#344054]"
                :class="
                  currentTab.includes('/shl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("thenavbar.shiftlog") }}
              </div></router-link
            >
          </div>
          <div class="flex items-end justify-center w-full gap-2">
            <div class="text-[#344054] font-[700] text-[24px]">
              {{ machineName }}
            </div>
            <div
              v-if="machineStatus == 'Automatic'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#32D583] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.production") }}
            </div>
            <div
              v-if="machineStatus == 'Planned Stop'"
              class="rounded-[16px] w-[280px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.plannedStop") }}
            </div>
            <div
              v-if="machineStatus == 'Cleaning'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.clean") }}
            </div>
            <div
              v-if="machineStatus == 'Prepare'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.prepare") }}
            </div>
            <div
              v-if="machineStatus == 'Employee Break'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.break") }}
            </div>
            <div
              v-if="machineStatus == 'Stopped'"
              class="rounded-[16px] w-[190px] h-[36px] bg-[#F63D68] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.stopped") }}
            </div>
          </div>
        </div>
      </v-col>
      <!-- Workers Navigation bar -->
      <v-col v-else :cols="isLoggedIn ? '10' : '8'" class="items-center pr-14">
        <div class="flex text-[16px] font-[400] items-center gap-2 pr-14">
          <div class="flex gap-2">
            <!-- Automatic labeling tab  -->
            <router-link
              :to="`${this.isManual === 'Manual' ? '/manual-worker' : ''}/atl/${
                this.routeMachineId || this.aftMacId
              }`"
            >
              <div
                class="w-[212px] h-[40px] flex justify-center items-center text-[#344054]"
                :class="
                  currentTab.includes('/atl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("thenavbar.automaticLabeling") }}
              </div>
            </router-link>

            <!-- Shiftlog tab -->
            <router-link
              :to="`${this.isManual === 'Manual' ? '/manual-worker' : ''}/shl/${
                this.routeMachineId || this.aftMacId
              }`"
            >
              <div
                class="w-[212px] h-[40px] flex justify-center items-center text-[#344054]"
                :class="
                  currentTab.includes('/shl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("thenavbar.shiftlog") }}
              </div></router-link
            >
          </div>
          <div class="flex items-end justify-center w-full gap-2">
            <div class="text-[#344054] font-[700] text-[24px]">
              {{ machineName }}
            </div>
            <div
              v-if="machineStatus == 'Automatic'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#32D583] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.production") }}
            </div>
            <div
              v-if="machineStatus == 'Planned Stop'"
              class="rounded-[16px] w-[280px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.plannedStop") }}
            </div>
            <div
              v-if="machineStatus == 'Cleaning'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.clean") }}
            </div>
            <div
              v-if="machineStatus == 'Prepare'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.prepare") }}
            </div>
            <div
              v-if="machineStatus == 'Employee Break'"
              class="rounded-[16px] w-[121px] h-[36px] bg-[#FF9C66] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.break") }}
            </div>
            <div
              v-if="machineStatus == 'Stopped'"
              class="rounded-[16px] w-[190px] h-[36px] bg-[#F63D68] text-[#ECFDF3] text-[18px] font-semibold flex justify-center items-center"
            >
              {{ $t("thenavbar.stopped") }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="1" class="flex items-center" v-if="isLoggedIn">
        <div class="nav-icons">
          <div>
            <!-- Language button  -->
            <v-menu left offset-y>
              <template v-slot:activator="{ on }">
                <div class="w-[20px] h-[20px]">
                  <v-img
                    class="cursor-pointer"
                    v-on="on"
                    :src="selectedLang.flag"
                  ></v-img>
                </div>
              </template>
              <v-list class="language-list">
                <v-list-item
                  v-for="(language, index) in languages"
                  :key="index"
                  class="language-items border-b-2 border-[#F2F4F7]"
                >
                  <v-list-item-title
                    class="cursor-pointer"
                    @click="setLocale(language.country.toLowerCase())"
                  >
                    <div class="flex items-center gap-2">
                      <div>
                        <v-img
                          class="w-[32px] h-[32px]"
                          :src="language.flag"
                        ></v-img>
                      </div>
                      <div class="font-[400] text-[14px] text-[#344054]">
                        {{ language.country }}
                      </div>
                    </div></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div>
            <!-- Profile button  -->
            <v-menu
              left
              offset-y
              min-width="240"
              rounded="lg"
              content-class="profile-container"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="flex justify-center items-center w-[40px] h-[40px] bg-[#F8F8FF] rounded-[200px] cursor-pointer"
                >
                  <div class="w-[18px] h-[17px]">
                    <v-img
                      :src="require(`../assets/profile-image-icon.svg`)"
                    ></v-img>
                  </div>
                </div>
              </template>
              <v-list class="profile-list">
                <v-list-item
                  class="profile-items border-b-2 border-[#F2F4F7] !h-[40px]"
                  v-for="(profile, index) in profiles"
                  :key="index"
                >
                  <v-list-item-title
                    ><div class="flex">
                      <div
                        v-if="profile.title"
                        @click="profileAction(profile.title)"
                        class="w-full cursor-pointer"
                      >
                        <div class="text-[#344054] text-[14px] font-[400]">
                          <span v-if="profile.title == 'name'">
                            {{ formatFullName(fullName, 40) }}
                          </span>
                          <span v-else> {{ profile.title }}</span>
                        </div>
                      </div>
                      <div
                        v-if="profile.text"
                        class="flex items-center w-full gap-3 cursor-pointer"
                        @click="logOut"
                      >
                        <div class="w-[12px] h-[12px]">
                          <v-img :src="profile.logoutIcon"></v-img>
                        </div>
                        <div class="text-[#344054] text-[14px] font-[400]">
                          {{ profile.text }}
                        </div>
                      </div>
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-col>
      <v-col v-else :cols="!isLoggedIn ? '2' : ''">
        <v-btn
          depressed
          width="230"
          height="44"
          color="primary"
          class="text-[#ffffff] text-[18px] font-[700] flex justify-center items-center rounded-[6px]"
          @click="workerLoginPage"
          >{{ $t("thenavbar.login") }}</v-btn
        >
      </v-col>
    </v-row>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </v-app-bar>
</template>

<script>
import { logoutUser } from "../services/authentication";
import { mapActions } from "vuex";
import { format, parseISO } from "date-fns";
import Api from "../services/api";
import AlertComponent from "../components/AlertComponent.vue";
import moment from "moment";

export default {
  components: { AlertComponent },
  data() {
    return {
      configurations: [
        { title: "Machine Group", link: "/machine-group" },
        { title: "User Role", link: "/user-role" },
        { title: "Global Messages", link: "/manual-messages" },
        { title: "Machine Specific Messages", link: "/automatic-messages" },
      ],
      deConfigurations: [
        { title: "Maschinengruppe", link: "/machine-group" },
        { title: "Benutzer-Rolle", link: "/user-role" },
        { title: "Globale Nachrichten", link: "/manual-messages" },
        {
          title: "Maschinenspezifische Meldungen",
          link: "/automatic-messages",
        },
      ],
      esConfigurations: [
        { title: "Grupo de máquinas", link: "/machine-group" },
        { title: "Función del usuario", link: "/user-role" },
        { title: "Mensajes globales", link: "/manual-messages" },
      ],
      roConfigurations: [
        { title: "Grupul de mașini", link: "/machine-group" },
        { title: "Rolul utilizatorului", link: "/user-role" },
        { title: "Mesaje globale", link: "/manual-messages" },
        { title: "Mesaje specifice mașinii", link: "/automatic-messages" },
      ],
      cnConfigurations: [
        { title: "机械集团", link: "/machine-group" },
        { title: "用户角色", link: "/user-role" },
        { title: "全球讯息", link: "/manual-messages" },
        { title: "机器特定信息", link: "/automatic-messages" },
      ],
      baConfigurations: [
        { title: "Grupa Mašina", link: "/machine-group" },
        { title: "Uloga korisnika", link: "/user-role" },
        { title: "Globalne Poruke", link: "/manual-messages" },
        { title: "Poruke specifične za mašinu", link: "/automatic-messages" },
      ],
      rsConfigurations: [
        { title: "Мацхине Гроуп", link: "/machine-group" },
        { title: "Улога корисника", link: "/user-role" },
        { title: "Глобальные сообщения", link: "/manual-messages" },
      ],
      languages: [
        {
          flag: require("../assets/GB-flag.svg"),
          country: "EN",
        },
        {
          flag: require("../assets/DE-flag.svg"),
          country: "DE",
        },
        {
          flag: require("../assets/CN-flag.svg"),
          country: "CN",
        },
        {
          flag: require("../assets/RO-flag.svg"),
          country: "RO",
        },
        {
          flag: require("../assets/BA-flag.svg"),
          country: "BA",
        },
        {
          flag: require("../assets/ES-flag.svg"),
          country: "ES",
        },
        {
          flag: require("../assets/RS-flag.svg"),
          country: "RS",
        },
      ],
      profiles: [],
      adminProfile: [
        {
          title: "name",
        },
        {
          title: "Edit Profile",
        },
        {
          title: "Change Password",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Log out",
        },
      ],
      userProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Log out",
        },
      ],
      deAdminProfile: [
        {
          title: "name",
        },
        {
          title: "Profil bearbeiten",
        },
        {
          title: "Passwort ändern",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Abmelden",
        },
      ],
      deUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Abmelden",
        },
      ],
      cnAdminProfile: [
        {
          title: "name",
        },
        {
          title: "编辑个人资料",
        },
        {
          title: "更改密码",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "登出",
        },
      ],
      cnUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "登出",
        },
      ],
      roAdminProfile: [
        {
          title: "name",
        },
        {
          title: "Editează profilul",
        },
        {
          title: "Schimbă parola",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Deconectați-vă",
        },
      ],
      roUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Deconectați-vă",
        },
      ],
      esAdminProfile: [
        {
          title: "name",
        },
        {
          title: "Editar perfil",
        },
        {
          title: "Cambiar contraseña",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Cerrar sesión",
        },
      ],
      esUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Cerrar sesión",
        },
      ],
      rsAdminProfile: [
        {
          title: "name",
        },
        {
          title: "Уреди профил",
        },
        {
          title: "Промени лозинку",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Одјавити се",
        },
      ],
      rsUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Одјавити се",
        },
      ],
      baAdminProfile: [
        {
          title: "name",
        },
        {
          title: "Uredi profil",
        },
        {
          title: "Promijeni lozinku",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Odjaviti se",
        },
      ],
      baUserProfile: [
        {
          title: "name",
        },
        {
          logoutIcon: require("../assets/logout-icon.svg"),
          text: "Odjaviti se",
        },
      ],
      showInfo: false,
      isAdminRole: this.$store.getters["authentication/getIsAdmin"],
      isManualRole: this.$store.getters["authentication/getisManual"],
      dialogWorker: false,
      currentTab: this.$router.history.current.fullPath,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,

      currentDate: moment().format() || "2002-01-12T09:07:02",
      isLoggingOut: false,

      // dynamicMachineId: "4", // temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
    };
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.authentication.isLoggedIn;
    },

    storeLang() {
      return this.$store.state.translation.i18n.locale;
    },

    selectedLang() {
      return this.languages.find(
        (lang) => lang.country == this.storeLang.toUpperCase()
      );
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isManual() {
      return this.$store.state.authentication.currentUser?.data.user
        ?.machine_group;
    },

    fullName() {
      return this.$store.state.authentication.currentUser?.data?.user?.name;
    },

    machineStatus() {
      return this.$store.state.machines.machineStatus;
    },

    currentShiftStartDate() {
      return this.$store.state.users.currentShiftStartDate;
    },

    currentShiftEndDate() {
      return this.$store.state.users.currentShiftEndDate;
    },

    currentUserData() {
      return this.$store.state.authentication.currentUser?.data;
    },

    userMachineId() {
      return this.currentUserData?.user?.machine_id;
    },

    machineName() {
      return this.$store.state.machines.machineName;
    },

    otherMachineStatus() {
      return this.$store.state.machines.otherMachineStatus;
    },
  },

  methods: {
    workerLoginPage() {
      this.$router.push("/login-worker");
    },
    logOut() {
      if (this.isAdmin) {
        this.$router.push("/login-admin");
        location.reload();
        logoutUser();
      } else if (this.isManual) {
        this.$router.push("/login");
        location.reload();
        logoutUser();
      } else {
        this.userWorkerLogout();
      }
    },

    updateRole() {
      const profilesMap = {
        en: this.isAdminRole ? this.adminProfile : this.userProfile,
        de: this.isAdminRole ? this.deAdminProfile : this.deUserProfile,
        es: this.isAdminRole ? this.esAdminProfile : this.esUserProfile,
        ro: this.isAdminRole ? this.roAdminProfile : this.roUserProfile,
        cn: this.isAdminRole ? this.cnAdminProfile : this.cnUserProfile,
        ba: this.isAdminRole ? this.baAdminProfile : this.baUserProfile,
        rs: this.isAdminRole ? this.rsAdminProfile : this.rsUserProfile,
      };
      this.profiles = profilesMap[this.storeLang] || this.rsUserProfile;
    },

    profileAction(profileTitle) {
      if (
        profileTitle == "Edit Profile" ||
        profileTitle == "Profil bearbeiten" ||
        profileTitle == "编辑个人资料" ||
        profileTitle == "Editează profilul" ||
        profileTitle == "Editar perfil" ||
        profileTitle == "Уреди профил" ||
        profileTitle == "Uredi profil"
      ) {
        this.$router.push("/admin/edit-profile");
      } else if (
        profileTitle == "Change Password" ||
        profileTitle == "Passwort ändern" ||
        profileTitle == "更改密码" ||
        profileTitle == "Schimbă parola" ||
        profileTitle == "Cambiar contraseña" ||
        profileTitle == "Промени лозинку" ||
        profileTitle == "Promijeni lozinku"
      ) {
        this.$router.push("/admin/change-password");
      }
    },

    formatFullName(fullName, value) {
      return fullName?.length > value
        ? fullName?.slice(0, value) + "..."
        : fullName;
    },

    formatDate(data) {
      return format(parseISO(data), "EEEE LLL dd, yyyy");
    },

    formatTime(data) {
      return format(parseISO(data), "hh:mm aaa");
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId
        ? this.$route.params.routeMachineId
        : this.dynamicMachineId;
    },

    userWorkerLogout() {
      this.isLoggingOut = true;
      Api()
        .post(
          `/auth/worker-logout/${this.routeMachineId || this.userMachineId}/`
        )
        .then((res) => {
          if (res.status === 200) {
            this.$router.push(`/redirect/${this.routeMachineId}`);
            location.reload();
            this.isLoggingOut = false;
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    handleTokenExpireLogout(errorMessage) {
      if (this.isLoggingOut) {
        this.$router.push(`/redirect/${this.routeMachineId}`);
        location.reload();
        this.isLoggingOut = false;
      } else {
        this.showAlert = true;
        this.alertColor = "#e76a6a";
        this.textColor = "#ffffff";
        this.alertMessage = errorMessage;
        this.isSuccess = false;
      }
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    hideAlert() {
      this.showAlert = false;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.handleTokenExpireLogout(errorMessage);
    },

    ...mapActions("translation", ["setLocale"]),
  },

  watch: {
    storeLang(val) {
      if (val) {
        this.updateRole();
      }
    },
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );

    this.updateRole();
  },

  created() {
    this.getMachineId();
    this.getMachineIdFromRoute();
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.nav-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}

.nav-links-item {
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  padding: 8px 12px;
}

.nav-links-item:active,
.nav-links-item:hover {
  background: #f5f5ff;
  border-radius: 6px;
  /* color: #0408e7; */
}

.nav-links-item-active {
  font-weight: 400;
  font-size: 16px;
  color: #0408e7;
  padding: 8px 12px;
}

.nav-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80%;
  gap: 25px;
}

.v-tooltip__content {
  width: 398px;
  opacity: 1;
  border: 2px solid #7cd4fd !important;
  border-radius: 8px;
}

.profile-list .profile-items:last-child {
  border: none !important;
}

.profile-container {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-top: 8px;
}

.profile-container .v-list-item--link:before {
  height: 40px !important;
}

.v-list-item {
  min-height: 40px !important;
}

.language-list .language-items:last-child {
  border: none !important;
}

.configuration-list .configuration-items:last-child {
  border: none !important;
}

.configuration-container .v-list-item--link:before {
  height: 40px !important;
}

.configuration-container {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: 1px solid #f2f4f7;
}

.contentWidth {
  width: max-content;
}

.position-alert {
  position: absolute;
  top: 100%;
  right: 3%;
  z-index: 999;
}
</style>
