<template>
  <div class="bg-[#ffffff]">
    <div class="w-[94%] pl-12 pt-5 pb-10">
      <div class="flex justify-start">
        <div class="flex items-center justify-start gap-5">
          <div
            class="flex justify-center items-center w-[91px] h-[40px] gap-5 cursor-pointer"
            @click="goBack()"
          >
            <div>
              <v-img
                :src="require(`../../assets/chevron-left-gray.svg`)"
              ></v-img>
            </div>
            <div class="text-[#667085] text-[14px] font-[400]">
              {{ $t("assignedworkerview.back") }}
            </div>
          </div>
          <div class="flex flex-col gap-1">
            <div class="text-[#101828] text-[18px] font-[600]">
              {{ workerName }}
            </div>
            <div class="text-[#667085] text-[14px] font-[400]">
              {{ $t("assignedworkerview.manageWhatMachine") }}
            </div>
          </div>
        </div>
      </div>

      <v-divider class="mt-10"></v-divider>
      <!-- As a Worker -->
      <div class="flex mt-5 gap-14">
        <div class="w-[30%] flex flex-col">
          <div class="text-[#344054] text-[16px] font-[600]">
            {{ $t("assignedworkerview.asAWorker") }}
          </div>
          <div class="flex flex-col w-full ml-3">
            <div class="flex-1 mt-5">
              <div>
                <v-label>{{ $t("assignedworkerview.machineGroups") }}</v-label>
              </div>
              <div class="flex justify-start w-full gap-6 mt-1">
                <div class="w-[200px] xl:w-[65%]">
                  <v-select
                    :items="machineGroups"
                    item-text="name"
                    item-value="id"
                    v-model="selectedWorkerMacGroupId"
                    @change="
                      changeWorkerMacGroup(selectedWorkerMacGroupId, true)
                    "
                    dense
                    outlined
                    :label="$t('assignedworkerview.selectMachineGroup')"
                    color="#D0D5DD"
                    single-line
                  ></v-select>
                </div>
                <div class="mt-1">
                  <v-btn
                    width="115"
                    height="36"
                    depressed
                    color="#F5F5FF"
                    class="save-btn"
                    :loading="workerSaveLoader"
                    @click="startAssignToWorker()"
                    >{{ $t("assignedworkerview.save") }}</v-btn
                  >
                </div>
              </div>
            </div>
            <div>
              <WorkerMachineGroup
                :workerDetails="workerDetails"
                :selectedWorkerMacGroupId="selectedWorkerMacGroupId"
                @assign-machineToWorker="assignMachineToWorker"
                ref="WorkerMachineGroupRef"
              />
            </div>
          </div>
        </div>
        <div class="w-[30%] flex flex-col ml-14">
          <div class="flex flex-col gap-3 mt-14">
            <div class="text-[#475467] text-[16px] font-[400]">
              {{ $t("assignedworkerview.totalAssigned") }}
            </div>
            <div class="text-[#475467] text-[18px] font-[600] mb-4">
              {{
                $t("assignedworkerview.machines", { "1": totalWorkerMacCount })
              }}
            </div>
          </div>
          <div>
            <WorkerMachine :workerDetails="workerDetails" />
          </div>
        </div>
      </div>

      <v-divider class="mt-10"></v-divider>
      <!-- As A technician -->
      <div class="flex mt-5 gap-14">
        <div class="w-[30%] flex flex-col">
          <div class="text-[#344054] text-[16px] font-[600]">
            {{ $t("assignedworkerview.asATechnician") }}
          </div>
          <div class="ml-3">
            <div class="mt-5">
              <div>
                <v-label>{{ $t("assignedworkerview.machineGroups") }}</v-label>
              </div>
              <div class="flex justify-start gap-6 mt-1">
                <div class="w-[200px] xl:w-[65%]">
                  <v-select
                    :items="machineGroups"
                    item-text="name"
                    item-value="id"
                    v-model="selectedTechnicianMacGroupId"
                    @change="
                      changeTechnicianMacGroup(
                        selectedTechnicianMacGroupId,
                        true
                      )
                    "
                    dense
                    outlined
                    :label="$t('assignedworkerview.selectMachineGroup')"
                    color="#D0D5DD"
                    single-line
                  ></v-select>
                </div>
                <div class="mt-1">
                  <v-btn
                    width="115"
                    height="36"
                    depressed
                    color="#F5F5FF"
                    class="save-btn"
                    :loading="technicianSaveLoader"
                    @click="startAssignToTechnician()"
                    >{{ $t("assignedworkerview.save") }}</v-btn
                  >
                </div>
              </div>
            </div>
            <div>
              <TechnicianMachineGroup
                :technicianDetails="technicianDetails"
                :selectedTechnicianMacGroupId="selectedTechnicianMacGroupId"
                @assign-machineToTechnician="assignMachineToTechnician"
                ref="TechnicianMachineGroupRef"
              />
            </div>
          </div>
        </div>
        <div class="w-[30%] flex flex-col ml-14">
          <div class="flex flex-col gap-3 mt-14">
            <div class="text-[#475467] text-[16px] font-[400]">
              {{ $t("assignedworkerview.totalAssigned") }}
            </div>
            <div class="text-[#475467] text-[18px] font-[600] mb-4">
              {{
                $t("assignedworkerview.machines", {
                  "1": totalTechnicianMacCount,
                })
              }}
            </div>
          </div>
          <div>
            <TechnicianMachine :technicianDetails="technicianDetails" />
          </div>
        </div>
      </div>
    </div>
    <v-overlay
      :value="allUserDataLoader || workerDataLoader || technicianDataLoader"
      color="grey"
    >
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import WorkerMachineGroup from "@/components/WorkerMachineGroup.vue";
import WorkerMachine from "@/components/WorkerMachine.vue";
import TechnicianMachineGroup from "@/components/TechnicianMachineGroup.vue";
import TechnicianMachine from "@/components/TechnicianMachine.vue";
import AlertComponent from "@/components/AlertComponent.vue";
import Api from "../../services/api";
export default {
  components: {
    WorkerMachineGroup,
    WorkerMachine,
    TechnicianMachineGroup,
    TechnicianMachine,
    AlertComponent,
  },

  data() {
    return {
      rfidKey: "",
      allUserDataLoader: false,
      workerDataLoader: false,
      technicianDataLoader: false,
      workerId: 1,
      technicianId: 2,
      workerDetails: null,
      technicianDetails: null,
      workerName: "",

      machineGroups: [],
      allMachineGroupsLoader: false,
      selectedWorkerMacGroupId: "",
      selectedTechnicianMacGroupId: "",
      workerSaveLoader: false,
      technicianSaveLoader: false,
      totalWorkerMacCount: 0,
      totalTechnicianMacCount: 0,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
    };
  },

  methods: {
    getAllUserMachines() {
      this.allUserDataLoader = true;
      Api()
        .post(`/machines/all-user-machines/${this.rfidKey}/`)
        .then((res) => {
          if (res.data.status == "success") {
            let savedData = res.data.data;
            this.workerName = savedData.user;
            this.workerDetails = savedData.worker_machine;
            this.totalWorkerMacCount = this.workerDetails.total_assignments;
            this.selectedWorkerMacGroupId = this.workerDetails.machine_group_id;
            this.technicianDetails = savedData.technician_machines;
            this.selectedTechnicianMacGroupId =
              this.technicianDetails.machine_group_id;
            this.totalTechnicianMacCount =
              this.technicianDetails.total_assignments;
          }
          this.allUserDataLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allUserDataLoader = false;
        });
    },

    getMachineGroups() {
      this.allMachineGroupsLoader = true;
      Api()
        .post("/machines/get-dropdowns/4/")
        .then((res) => {
          if (res.data.status == "success") {
            this.machineGroups = [...res.data.data];
          }
          this.allMachineGroupsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allMachineGroupsLoader = false;
        });
    },

    changeWorkerMacGroup(macGroupId, showLoader) {
      this.workerDataLoader = showLoader;
      let newData = {
        rfid_key_number: this.rfidKey,
        machine_group_id: macGroupId,
        machine_role_id: this.workerId,
      };
      Api()
        .post("/machines/machines-in-machine-group/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.workerDetails = res.data.data;
            this.selectedWorkerMacGroupId = this.workerDetails.machine_group_id;
            this.totalWorkerMacCount = this.workerDetails.total_assignments;
          }
          this.workerDataLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.workerDataLoader = false;
        });
    },

    startAssignToWorker() {
      this.$refs.WorkerMachineGroupRef?.assignMachineToWorker();
    },

    assignMachineToWorker(selectedList) {
      this.workerSaveLoader = true;
      let newData = {
        machines: selectedList,
        machine_role_id: this.workerId,
      };
      Api()
        .post(`/machines/assign-machine/${this.rfidKey}/`, newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.changeWorkerMacGroup(this.selectedWorkerMacGroupId, false);
            this.changeTechnicianMacGroup(
              this.selectedTechnicianMacGroupId,
              false
            );
            this.showSuccess(res.data.detail);
          }
          this.workerSaveLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.workerSaveLoader = false;
        });
    },

    changeTechnicianMacGroup(macGroupId, showLoader) {
      this.technicianDataLoader = showLoader;
      let newData = {
        rfid_key_number: this.rfidKey,
        machine_group_id: macGroupId,
        machine_role_id: this.technicianId,
      };
      Api()
        .post("/machines/machines-in-machine-group/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.technicianDetails = res.data.data;
            this.selectedTechnicianMacGroupId =
              this.technicianDetails.machine_group_id;
            this.totalTechnicianMacCount =
              this.technicianDetails.total_assignments;
          }
          this.technicianDataLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.technicianDataLoader = false;
        });
    },

    startAssignToTechnician() {
      this.$refs.TechnicianMachineGroupRef?.assignMachineToTechnician();
    },

    assignMachineToTechnician(selectedList) {
      this.technicianSaveLoader = true;
      let newData = {
        machines: selectedList,
        machine_role_id: this.technicianId,
      };
      Api()
        .post(`/machines/assign-machine/${this.rfidKey}/`, newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.changeWorkerMacGroup(this.selectedWorkerMacGroupId, false);
            this.changeTechnicianMacGroup(
              this.selectedTechnicianMacGroupId,
              false
            );
            this.showSuccess(res.data.detail);
          }
          this.technicianSaveLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.technicianSaveLoader = false;
        });
    },

    goBack() {
      this.$router.go(-1);
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    getRfidKeyFromRoute() {
      this.rfidKey = this.$route.params.rfidKey;
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllUserMachines();
    this.getMachineGroups();
    this.scrollToTop();
  },

  created() {
    this.getRfidKeyFromRoute();
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
  width: 280px;
}

.save-btn {
  color: #0408e7;
  border-radius: 8px;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
