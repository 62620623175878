<template>
  <!-- Edit Machine Group dialog box -->
  <v-dialog :retain-focus="false" v-model="editDialog" width="408">
    <div class="w-[408px]">
      <v-card class="p-1">
        <v-card-title>
          <div class="mx-auto font-semibold text-lg text-[#101828]">
            {{ $t("editMachineGroupDialog.editMachine") }}
          </div>
          <div>
            <v-img
              @click="editDialog = false"
              :src="require(`../../assets/close-icon.svg`)"
              :lazy-src="require(`../../assets/close-icon.svg`)"
              class="cursor-pointer"
            >
            </v-img>
          </div>
        </v-card-title>
        <v-card-text>
          <v-label>{{ $t("editMachineGroupDialog.name") }}</v-label>
          <v-text-field
            dense
            v-model="newMachineName"
            outlined
            color="#667085"
          ></v-text-field>
          <div class="flex justify-between">
            <div>
              <v-btn
                elevation="0"
                class="cancel-btn !bg-[#ffffff]"
                @click="editDialog = false"
                >{{ $t("editMachineGroupDialog.cancel") }}</v-btn
              >
            </div>
            <div>
              <v-btn
                elevation="0"
                depressed
                color="primary"
                class="save-btn"
                :loading="editMachineGroupLoader"
                @click="editMachineGroup"
                >{{ $t("editMachineGroupDialog.save") }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: ["selectedMachineGroup"],
  data() {
    return {
      editDialog: false,
      editMachineGroupLoader: false,
    };
  },

  computed: {
    newMachineName: {
      get() {
        return this.selectedMachineGroup?.machine_group_name;
      },
      set(val) {
        this.$emit("update-machineGroupName", val);
      },
    },
  },

  methods: {
    showEditDialog() {
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },

    stopEditLoader() {
      this.editMachineGroupLoader = false;
    },

    editMachineGroup() {
      let newData = {
        name: this.newMachineName,
        machine_group_id: this.selectedMachineGroup.machine_group_id,
      };

      this.editMachineGroupLoader = true;
      this.$emit("update-machineGroup", newData);
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}
</style>
