<template>
  <!-- Edit Members dialog box -->
  <v-dialog :retain-focus="false" v-model="editDialog" width="408">
    <div class="w-[408px]">
      <v-card class="p-1">
        <v-card-title>
          <div class="mx-auto text-lg font-semibold text-[#101828]">
            {{ $t("edituserdialog.editMember") }}
          </div>
          <div>
            <v-img
              @click="editDialog = false"
              :src="require(`../../assets/close-icon.svg`)"
              class="cursor-pointer"
            >
            </v-img>
          </div>
        </v-card-title>
        <v-card-text>
          <v-label>{{ $t("edituserdialog.name") }}</v-label>
          <v-text-field
            dense
            ref="name"
            v-model="newName"
            outlined
            color="#667085"
          ></v-text-field>
          <v-label>{{ $t("edituserdialog.username") }}</v-label>
          <v-text-field
            dense
            ref="userName"
            v-model="newUserName"
            outlined
            color="#667085"
            :rules="[noSpaceAllowed(usersViewLang)]"
          ></v-text-field>
          <v-label>{{ $t("edituserdialog.role") }}</v-label>
          <v-select
            :label="$t('edituserdialog.selectRole')"
            :items="userRoles"
            item-text="name"
            item-value="id"
            v-model="newSelectedRoleId"
            dense
            outlined
            single-line
          ></v-select>
          <v-label>{{ $t("edituserdialog.rfid") }}</v-label>
          <v-text-field
            dense
            ref="pin"
            v-model="newRfidKeynumber"
            outlined
            color="#667085"
          ></v-text-field>
          <div class="flex justify-between">
            <div>
              <v-btn
                @click="editDialog = false"
                depressed
                class="cancel-btn !bg-[#ffffff]"
                >{{ $t("edituserdialog.cancel") }}</v-btn
              >
            </div>
            <div>
              <v-btn
                depressed
                color="primary"
                class="save-btn"
                :loading="editMemberLoader"
                @click="editMember"
                >{{ $t("edituserdialog.save") }}</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import validation from "../../services/validation";
export default {
  props: ["selectedUser", "userRoles"],
  data() {
    return {
      editDialog: false,
      show1: false,
      editMemberLoader: false,
      selectedRoleId: "",
      oldRfidNumber: "",
      ...validation,
    };
  },

  computed: {
    newName: {
      get() {
        return this.selectedUser?.name;
      },
      set(val) {
        this.$emit("update-name", val);
      },
    },

    newUserName: {
      get() {
        return this.selectedUser?.user_name;
      },
      set(val) {
        this.$emit("update-username", val);
      },
    },

    newRfidKeynumber: {
      get() {
        return this.selectedUser?.rfid_key_number;
      },
      set(val) {
        this.$emit("update-rfidKeynumber", val);
      },
    },

    newSelectedRoleId: {
      get() {
        return this.selectedUser?.user_role_id;
      },
      set(val) {
        this.$emit("update-selectedRoleId", val);
      },
    },

    usersViewLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  methods: {
    showEditDialog() {
      this.editDialog = true;
    },

    closeEditDialog() {
      this.editDialog = false;
    },

    stopEditLoader() {
      this.editMemberLoader = false;
    },

    editMember() {
      let newData = {
        name: this.newName,
        user_name: this.newUserName,
        user_key: this.selectedUser.user_key,
        role_id: this.newSelectedRoleId,
      };
      let newDataWithRfid = {
        name: this.newName,
        user_name: this.newUserName,
        rfid_key_number: this.newRfidKeynumber,
        user_key: this.selectedUser.user_key,
        role_id: this.newSelectedRoleId,
      };

      this.editMemberLoader = true;
      this.$emit(
        "update-member",
        this.oldRfidNumber == this.newRfidKeynumber ? newData : newDataWithRfid
      );
    },
  },

  watch: {
    editDialog(val) {
      if (val) {
        this.oldRfidNumber = this.selectedUser?.rfid_key_number;
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  width: 174px;
}
</style>
