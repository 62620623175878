<template>
  <div class="w-[full]">
    <!-- Worker Machine table  -->
    <v-data-table
      fixed-header
      dense
      height="404"
      item-key="name"
      :headers="
        workerMachineLang === 'en'
          ? headers
          : workerMachineLang === 'de'
          ? deHeaders
          : workerMachineLang === 'cn'
          ? cnHeaders
          : workerMachineLang === 'ro'
          ? roHeaders
          : workerMachineLang === 'ba'
          ? baHeaders
          : workerMachineLang === 'es'
          ? esHeaders
          : rsHeaders
      "
      :items="formattedAssignedMachines"
      class="border-2"
      :hide-default-footer="true"
      :no-data-text="$t('assignedworkerview.nodata')"
      disable-pagination
    >
      <template v-slot:[`item.name`]="{ item }">
        <div class="text-[#101828] text-[14px] font-[400]">{{ item.name }}</div>
      </template>
      <template v-slot:[`item.group`]="{ item }">
        <div class="text-[#667085] text-[14px] font-[400]">
          {{ item.group }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["workerDetails"],

  data() {
    return {
      headers: [
        {
          text: "Names",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Group", value: "group" },
      ],
      deHeaders: [
        {
          text: "Namen",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Gruppe", value: "group" },
      ],
      cnHeaders: [
        {
          text: "名称",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "集团", value: "group" },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Grupul", value: "group" },
      ],
      esHeaders: [
        {
          text: "Nombres",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Grupo", value: "group" },
      ],
      baHeaders: [
        {
          text: "Imena",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Grupa", value: "group" },
      ],
      rsHeaders: [
        {
          text: "Имена",
          align: "start",
          value: "name",
        },
        {},
        {},
        { text: "Група", value: "group" },
      ],
      machines: [
        {
          name: "SAR3",
          group: "Group 1",
        },
      ],

      formattedAssignedMachines: [],
    };
  },
  computed: {
    workerMachineLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },
  methods: {
    formatAssignedMachinesData() {
      return this.workerDetails.assigned_work_machines.map((machine) => {
        machine = this.addAssignedMachineFeatures(machine);
        return machine;
      });
    },

    addAssignedMachineFeatures(data) {
      data.name = data.machine_name;
      data.group = data.machine_group;
      return data;
    },
  },

  watch: {
    workerDetails(val) {
      if (val) {
        this.formattedAssignedMachines = this.formatAssignedMachinesData();
      }
    },
  },
};
</script>

<style scoped>
* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar {
  width: 9px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #eaecf0;
  border-radius: 90px;
}

*/deep/ .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #d0d5dd;
  border-radius: 90px;
}
</style>
