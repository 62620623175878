<template>
  <div class="py-5 px-14 w-[full] bg-[#fff]">
    <div
      v-if="machineStatus == 'Stopped' && !machinesLogsLoader"
      class="w-[100%] pb-4"
    >
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.machineHasStoppedWorking") }}
        </div>
      </div>
    </div>
    <div v-if="isLongAlert && !isEmployeeBreak" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#F63D68] gap-3 rounded-[8px]"
      >
        <div class="w-[16.67px] h-[16.67px]">
          <v-img :src="require(`../../assets/alert-circle-icon.svg`)"></v-img>
        </div>
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ longAlertMsg }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Employee Break'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.workerIsOnBreak") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Planned Stop'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.plannedStop") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Cleaning'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.clean") }}
        </div>
      </div>
    </div>
    <div v-if="machineStatus == 'Prepare'" class="w-[100%] pb-4">
      <div
        class="flex justify-center items-center h-[60px] bg-[#FF9C66] gap-3 rounded-[8px]"
      >
        <div class="font-[600] text-[18px] text-[#FEF3F2]">
          {{ $t("shlview.prepare") }}
        </div>
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div class="flex items-center justify-start gap-11">
        <div class="flex items-center justify-center gap-7">
          <div class="text-[#344054] text-[24px] font-[700]">
            {{ machineName }}
          </div>
          <div
            v-if="machineStatus == 'Automatic'"
            class="flex justify-center items-center rounded-[16px] bg-[#ECFDF3] w-[99px] h-[28px] text-[#027A48] text-[14px] font-[600]"
          >
            {{ $t("adminshlview.production") }}
          </div>
          <div
            v-if="machineStatus == 'Planned Stop'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[220px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.plannedStop") }}
          </div>
          <div
            v-if="machineStatus == 'Cleaning'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.clean") }}
          </div>
          <div
            v-if="machineStatus == 'Prepare'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.prepare") }}
          </div>
          <div
            v-if="machineStatus == 'Employee Break'"
            class="flex justify-center items-center rounded-[16px] bg-[#FF9C66] w-[99px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("shlview.break") }}
          </div>
          <div
            v-if="machineStatus == 'Stopped'"
            class="flex justify-center items-center rounded-[16px] bg-[#F63D68] w-[145px] h-[28px] text-[#ECFDF3] text-[14px] font-[600]"
          >
            {{ $t("adminshlview.stopped") }}
          </div>
        </div>
        <div class="flex justify-center gap-8">
          <div>
            <!-- Automatic labeling tab -->
            <router-link :to="{ name: 'adminAtl' }">
              <div
                class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] rounded-[6px]"
              >
                {{ $t("adminshlview.automaticLabeling") }}
              </div>
            </router-link>
          </div>
          <div>
            <!-- Shiftlog tab  -->
            <router-link :to="{ name: 'adminShl' }">
              <div
                class="flex items-center justify-center text-[16px] text-[#344054] w-[212px] h-[40px] bg-[#F5F5FF] rounded-[6px]"
                :class="
                  currentTab.includes('/admin-shl')
                    ? 'text-[#0408E7] rounded-[6px] bg-[#F5F5FF]'
                    : ''
                "
              >
                {{ $t("adminshlview.shiftlog") }}
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <!-- View all shifts tab  -->
        <router-link :to="{ name: 'allShifts' }"
          ><div class="text-[#344054] font-[400] text-[16px]">
            {{ $t("adminshlview.viewAllShifts") }}
          </div></router-link
        >
      </div>
    </div>

    <div class="flex justify-between gap-5 mt-5">
      <div class="w-[70%]">
        <!-- Faults table  -->
        <v-data-table
          dense
          :headers="headers"
          :items="formattedFaultLogs"
          item-key="id"
          class="border-2"
          :hide-default-footer="true"
          :no-data-text="$t('adminshlview.nodata')"
        >
          <template v-slot:[`header.faults`]="{}">
            <div class="flex mt-5 mr-5">
              <div class="text-[#344054] font-[400] text-[14px]">
                {{ $t("adminshlview.faults") }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.faults`]="{ item }"
            ><div class="text-[#101828] text-[14px] font-[400]">
              {{ item[`reason_type__${formattedLang}`] }}
            </div></template
          >
          <template v-slot:[`item.info`]="{ item }">
            <div class="flex items-center justify-end gap-3 mr-6">
              <div class="w-[13.33px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div>
                {{ item.info.quantity }}
              </div>
              <div class="w-[16.67px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div>
                {{ item.info.time }}
              </div>
            </div>
          </template>
        </v-data-table>

        <!-- NIO table  -->
        <v-data-table
          dense
          :headers="
            shlViewLang === 'en'
              ? headersTwo
              : shlViewLang === 'de'
              ? deHeadersTwo
              : shlViewLang === 'cn'
              ? cnHeadersTwo
              : shlViewLang === 'ro'
              ? roHeadersTwo
              : shlViewLang === 'ba'
              ? baHeadersTwo
              : shlViewLang === 'es'
              ? esHeadersTwo
              : rsHeadersTwo
          "
          :items="formattedNioLogs"
          item-key="id"
          class="mt-5 border-2"
          :hide-default-footer="true"
          :no-data-text="$t('adminshlview.nodata')"
        >
          <template v-slot:[`header.nio`]="{}">
            <div class="flex mt-5 mr-5">
              <div class="text-[#344054] font-[400] text-[14px]">
                {{ $t("adminshlview.nio") }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.nio`]="{ item }">
            <div class="text-[#344054] text-[14px] font-[400]">
              {{ item[`reason_type__${formattedLang}`] }}
            </div>
          </template>
          <template v-slot:[`item.infoTwo`]="{ item }">
            <div class="flex items-center justify-end gap-3 mr-6">
              <div class="w-[13.33px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div>{{ item.infoTwo.quantity }}</div>
            </div>
          </template>
        </v-data-table>

        <!-- Comments table  -->
        <v-data-table
          dense
          :headers="headersThree"
          :items="formattedCommentLogs"
          item-key="id"
          class="mt-5 border-2"
          :hide-default-footer="true"
          :no-data-text="$t('adminshlview.nodata')"
        >
          <template v-slot:[`header.comments`]="{}">
            <div class="flex mt-5 mr-5">
              <div class="text-[#344054] font-[400] text-[14px]">
                {{ $t("adminshlview.comments") }}
              </div>
            </div>
          </template>
          <template v-slot:[`item.comments`]="{ item }">
            <div class="flex mr-5">
              <div class="text-[#344054] font-[400] text-[14px]">
                {{ item[`reason_type__${formattedLang}`] }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.comment`]="{ item }">
            <div class="text-[#344054] font-[400] text-[14px]">
              {{ item.comment }}
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div class="text-[#344054] font-[400] text-[14px]">
              {{ item.date }}
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="flex justify-end pr-14">
              <div>{{ item.name }}</div>
            </div>
          </template>
        </v-data-table>
      </div>
      <!-- Div with start break button -->
      <div
        class="border-2 rounded-[8px] w-[30%] h-[920px] bg-[#fff] border-[#EAECF0] flex flex-col p-5"
      >
        <div class="flex justify-end pb-[20px]">
          <div>
            <v-btn
              v-if="!isEmployeeBreak"
              depressed
              color="#ffffff"
              width="190"
              height="44"
              class="flex items-center start-break-btn justify-evenly"
              :loading="startBreakLoader"
              :disabled="!isCurrentShift"
              @click="startBreak()"
            >
              <div class="w-[16.67px] h-[16.67px]">
                <v-img
                  :src="require('../../assets/break-clock-icon.svg')"
                ></v-img>
              </div>
              <div>{{ $t("shlview.startBreak") }}</div>
            </v-btn>

            <v-btn
              v-if="isEmployeeBreak"
              depressed
              color="#32d583"
              width="240"
              height="44"
              class="flex items-center start-break-btn justify-evenly"
              :loading="stopBreakLoader"
              :disabled="!isCurrentShift"
              @click="stopBreak()"
            >
              <div class="w-[16.67px] h-[16.67px}">
                <v-img
                  :src="require('../../assets/break-clock-icon-white.svg')"
                ></v-img>
              </div>
              <div class="text-[#ffffff]">{{ $t("shlview.stopBreak") }}</div>
            </v-btn>
          </div>
        </div>

        <div class="border-b-[1px]" id="modalTop">
          <div v-if="isLoggedIn" class="flex justify-start gap-1 pb-5">
            <div class="text-[#1D2939] text-[14px] font-[400]">
              {{ formatShiftDate(currentDate) }}
            </div>
            <div class="text-[#1D2939] text-[14px] font-[600]">
              {{ formatShiftTime(currentShiftStartDate) }} -
              {{ formatShiftTime(currentShiftEndDate) }}
            </div>
          </div>
          <div class="flex justify-between">
            <div class="flex items-center justify-center">
              <v-btn
                min-width="0"
                min-height="0"
                width="40"
                height="40"
                depressed
                color="#EAECF0"
                class="flex items-center justify-center rounded-[8px]"
                @click="goBackwardShift(true)"
              >
                <div class="w-[5px] h-[10px]">
                  <v-img
                    :src="require(`../../assets/chevron-left.svg`)"
                  ></v-img>
                </div>
              </v-btn>
              <div class="ml-[15px] font-bold">
                {{ formatFullName(fullName, 25) }}
              </div>
            </div>
            <div>
              <v-btn
                min-width="0"
                min-height="0"
                width="40"
                height="40"
                depressed
                color="#EAECF0"
                class="!w-[40px] !h-[40px] flex items-center justify-center rounded-[8px] bg-[#EAECF0]"
                :disabled="isDisableNextButton"
                @click="goForwardShift(true)"
              >
                <div class="w-[5px] h-[10px]">
                  <v-img
                    :src="require(`../../assets/chevron-right.svg`)"
                  ></v-img>
                </div>
              </v-btn>
            </div>
          </div>
          <div class="flex flex-col py-5">
            <div class="mb-1 text-[#101828] text-[14px] font-[400]">
              {{ $t("shlview.plantStatus") }}
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.automaticMode") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.automatic_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.atomatic_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.stopCount") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.stop_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.stop_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.plannedStop") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.planned_stop_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.planned_stop_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.clean") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.cleaning_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.cleaning_duration }}
              </div>
            </div>
            <div class="flex items-center gap-1">
              <div class="mb-1 text-[#475467] text-[16px] font-[400]">
                {{ $t("shlview.prepare") }}
              </div>
              <div class="w-[8px] h-[9px]">
                <v-img :src="require(`../../assets/hash-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.prepare_count }}
              </div>
              <div class="w-[10px] h-[10px]">
                <v-img :src="require(`../../assets/clock-icon.svg`)"></v-img>
              </div>
              <div class="text-[#475467] text-[16px] font-[400]">
                {{ plantStatus?.prepare_duration }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isEmployeeBreak">
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.faults") }}
            </div>
            <div>
              <v-btn
                min-width="0"
                min-height="0"
                width="36"
                height="36"
                color="#C3C4FE"
                depressed
                @click="showFaultDialog"
                :disabled="!isCurrentShift"
                class="rounded-[8px] flex justify-center items-center"
              >
                <div class="w-[11.67px] h-[11.67px]">
                  <v-img :src="require(`../../assets/plus-icon.svg`)"></v-img>
                </div>
              </v-btn>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.nio") }}
            </div>
            <div>
              <v-btn
                min-width="0"
                min-height="0"
                width="36"
                height="36"
                color="#C3C4FE"
                @click="showNioDialog"
                :disabled="!isCurrentShift"
                depressed
                class="rounded-[8px] flex justify-center items-center"
              >
                <div class="w-[11.67px] h-[11.67px]">
                  <v-img :src="require(`../../assets/plus-icon.svg`)"></v-img>
                </div>
              </v-btn>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.comments") }}
            </div>
            <div>
              <v-btn
                min-width="0"
                min-height="0"
                width="36"
                height="36"
                color="#C3C4FE"
                @click="showCommentDialog"
                :disabled="!isCurrentShift"
                depressed
                class="rounded-[8px] flex justify-center items-center"
              >
                <div class="w-[11.67px] h-[11.67px]">
                  <v-img :src="require(`../../assets/plus-icon.svg`)"></v-img>
                </div>
              </v-btn>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.plannedStop") }}
            </div>
            <div>
              <v-switch
                :class="isPlannedStop ? 'planned-stop switch' : 'switch'"
                v-model="isPlannedStop"
                @change="plannedStopMachine(isPlannedStop)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div
            class="border-b-[1px] py-[22px] flex justify-between items-center"
          >
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.prepare") }}
            </div>
            <div>
              <v-switch
                :class="isPrepare ? 'planned-stop switch' : 'switch'"
                v-model="isPrepare"
                @change="prepareMachine(isPrepare)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
          <div class="py-[22px] flex justify-between items-center">
            <div class="font-medium text-[#344054]">
              {{ $t("shlview.clean") }}
            </div>
            <div>
              <v-switch
                :class="isCleaning ? 'planned-stop switch' : 'switch'"
                v-model="isCleaning"
                @change="cleanMachine(isCleaning)"
                :disabled="!isCurrentShift"
                inset
                color="#ffffff"
              ></v-switch>
            </div>
          </div>
        </div>
        <div v-else>
          <v-img
            :src="require(`../../assets/break-time-image.svg`)"
            :lazy-src="require(`../../assets/break-time-image.svg`)"
          ></v-img>
        </div>
      </div>
    </div>

    <AddFaultDialog
      :faultReasons="faultReasons"
      :formattedLang="formattedLang"
      @add-machineFault="addMachineFault"
      ref="AddFaultDialogRef"
    />
    <AddNioDialog
      :nioReasons="nioReasons"
      :formattedLang="formattedLang"
      @add-machineNio="addMachineNio"
      ref="AddNioDialogRef"
    />
    <AddCommentDialog
      :commentReasons="commentReasons"
      :formattedLang="formattedLang"
      @add-machineComment="addMachineComment"
      ref="AddCommentDialogRef"
    />
    <v-overlay :value="machinesLogsLoader || previousLogsLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import ApiNoToken from "../../services/apiNoToken";
import AddFaultDialog from "../../components/admin/AddFaultDialog.vue";
import AddNioDialog from "../../components/admin/AddNioDialog.vue";
import AddCommentDialog from "../../components/admin/AddCommentDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";
import { format, parseISO } from "date-fns";
import { actionLogTypes, checkLanguage } from "../../services/utils";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    AddFaultDialog,
    AddNioDialog,
    AddCommentDialog,
    AlertComponent,
  },
  data() {
    return {
      currentDate: moment().format() || "2002-01-12T09:07:02",
      currentTab: this.$router.history.current.fullPath,
      headers: [
        {
          text: "Faults",
          align: "start",
          value: "faults",
        },
        { text: "", value: "info" },
      ],
      faults: [
        {
          faults: "Plant 1 Error Msg",
          info: "",
        },
      ],
      headersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      deHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      esHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      roHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      cnHeadersTwo: [
        {
          text: "蔚来",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      baHeadersTwo: [
        {
          text: "NIO",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      rsHeadersTwo: [
        {
          text: "НИО",
          align: "start",
          value: "nio",
        },
        { text: "", value: "infoTwo" },
      ],
      nio: [
        {
          nio: "Plant 1 Error Msg",
          infoTwo: "",
        },
      ],
      headersThree: [
        {
          text: "Comments",
          align: "start",
          value: "comments",
        },
        { text: "", value: "comment" },
        { text: "", value: "date" },
        { text: "", value: "name" },
      ],
      commentsInfo: [
        {
          comments: "Handover",
          comment: "comment",
          date: "18/09/22",
          name: "John Doe ",
        },
      ],

      previousShift: null,
      currentShift: null,
      nextShift: null,
      plantStatus: null,
      faultLogs: [],
      nioLogs: [],
      commentLogs: [],

      machinesLogsLoader: false,
      formattedLang: [],
      commentReasons: [],
      faultReasons: [],
      nioReasons: [],
      formattedFaultLogs: [],
      formattedNioLogs: [],
      formattedCommentLogs: [],

      routeMachineId: "",

      isCleaning: false,
      isEmployeeBreak: false,
      isPlannedStop: false,
      isPrepare: false,
      showLoader: false,

      longAlertMsg: "",
      isLongAlert: false,
      setPoint: "",
      current: "",
      startBreakLoader: false,
      stopBreakLoader: false,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,
      aftMacId: "",
      machineStatus: "Automatic",

      showPreviousShift: false,
      showCurrentShift: true,
      showNextShift: false,

      // dynamicMachineId: "4", //temporal
      dynamicMachineId: localStorage.getItem("aftMacId"),
      timer: null,
      errorCount: 0,
      mainCurrentShift: null,
      allowEndpoint: true,
      previousLogsLoader: false,
    };
  },

  methods: {
    // get Machine logs
    getAllMachinesLogs(showLoader) {
      clearInterval(this.timer);
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .get(
          `/machines/current-shift-logs/${
            this.routeMachineId || this.aftMacId
          }/`
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
              this.getMainCurrentShiftData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
          clearInterval(this.timer);
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
          clearInterval(this.timer);
        })
        .finally(() => {
          this.timer = setInterval(() => {
            if (this.errorCount < 10) {
              if (this.allowEndpoint) {
                this.getCurrentShift(false);
              }
            } else {
              clearInterval(this.timer);
            }
          }, 5000);
        });
    },

    structureData(data) {
      if (Object.hasOwnProperty.call(data, "cleaning" || "prepare")) {
        this.isCleaning = data.cleaning;
        this.isEmployeeBreak = data.employee_break;
        this.isPlannedStop = data.planned_stop;
        this.isPrepare = data.prepare;
      } else if (
        Object.hasOwnProperty.call(data, "previous_shift_start_date")
      ) {
        this.previousShift = data;
      } else if (Object.hasOwnProperty.call(data, "current_shift_start_date")) {
        this.currentShift = data;
      } else if (Object.hasOwnProperty.call(data, "next_shift_start_date")) {
        this.nextShift = data;
      } else if (Object.hasOwnProperty.call(data, "planned_stop_count")) {
        this.plantStatus = data;
      } else if (Object.hasOwnProperty.call(data, "fault_logs")) {
        this.faultLogs = [...data.fault_logs];
      } else if (Object.hasOwnProperty.call(data, "nio_logs")) {
        this.nioLogs = [...data.nio_logs];
      } else if (Object.hasOwnProperty.call(data, "comment_logs")) {
        this.commentLogs = [...data.comment_logs];
      } else if (Object.hasOwnProperty.call(data, "machine_status")) {
        this.setPoint = data.set_point;
        this.machineStatus = data.machine_status;
        this.current = data.currrent;
        this.setMachineName(data.machine_name);
      }
    },

    addMachineFault(data) {
      Api()
        .post(`/machines/fault-log/${this.routeMachineId}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.stopAddFaultLoader();
            this.closeAddFaultDialog();
            this.getCurrentShift(false);
            this.resetFaultData();
          }
        })
        .catch((err) => {
          this.showError(err);
          this.stopAddFaultLoader();
        });
    },

    addToFaultList(fault) {
      fault = this.addFaultFeatures(fault);
      this.formattedFaultLogs.unshift(fault);
    },

    formatFaultLogs(faultList) {
      return faultList.map((faultItem) => {
        faultItem = this.addFaultFeatures(faultItem);
        return faultItem;
      });
    },

    addFaultFeatures(faultItem) {
      faultItem.info = {
        time: faultItem.duration,
        quantity: faultItem.fault_count,
      };
      faultItem.faults = faultItem.reason_type__name;
      return faultItem;
    },

    showFaultDialog() {
      this.$refs.AddFaultDialogRef.showFaultDialog();
    },
    stopAddFaultLoader() {
      this.$refs.AddFaultDialogRef?.stopAddFaultLoader();
    },
    closeAddFaultDialog() {
      this.$refs.AddFaultDialogRef?.closeAddFaultDialog();
    },
    resetFaultData() {
      this.$refs.AddFaultDialogRef?.resetFaultData();
    },

    addMachineNio(data) {
      Api()
        .post(`/machines/nio-log/${this.routeMachineId}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.stopAddNioLoader();
            this.closeAddNioDialog();
            this.getCurrentShift(false);
            this.resetNioData();
          }
        })
        .catch((err) => {
          this.showError(err);
          this.stopAddNioLoader();
        });
    },

    addToNioList(nio) {
      nio = this.addNioFeatures(nio);
      this.formattedNioLogs.unshift(nio);
    },

    formatNioLogs(nioList) {
      return nioList.map((nioItem) => {
        nioItem = this.addNioFeatures(nioItem);
        return nioItem;
      });
    },

    addNioFeatures(nioItem) {
      nioItem.infoTwo = {
        counter: "1",
        quantity: nioItem.quantity,
      };
      nioItem.nio = nioItem.reason_type__name;
      return nioItem;
    },

    showNioDialog() {
      this.$refs.AddNioDialogRef.showNioDialog();
    },
    stopAddNioLoader() {
      this.$refs.AddNioDialogRef?.stopAddNioLoader();
    },
    closeAddNioDialog() {
      this.$refs.AddNioDialogRef?.closeAddNioDialog();
    },
    resetNioData() {
      this.$refs.AddNioDialogRef?.resetNioData();
    },

    addMachineComment(data) {
      Api()
        .post(`/machines/comment-log/${this.routeMachineId}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.stopAddCommentLoader();
            this.closeAddCommentDialog();
            this.getCurrentShift(false);
            this.resetCommentData();
          }
        })
        .catch((err) => {
          this.showError(err);
          this.stopAddCommentLoader();
        });
    },

    addToCommentList(comment) {
      comment = this.addCommentFeatures(comment);
      this.formattedCommentLogs.unshift(comment);
    },

    formatCommentLogs(commentList) {
      return commentList.map((commentItem) => {
        commentItem = this.addCommentFeatures(commentItem);
        return commentItem;
      });
    },

    addCommentFeatures(commentItem) {
      commentItem.comments = commentItem.reason_type__name;
      commentItem.comment = commentItem.description;
      commentItem.date = this.formatDate(commentItem.timestamp);
      commentItem.name = commentItem.worker__name;

      return commentItem;
    },

    showCommentDialog() {
      this.$refs.AddCommentDialogRef.showCommentDialog();
    },
    stopAddCommentLoader() {
      this.$refs.AddCommentDialogRef?.stopAddCommentLoader();
    },
    closeAddCommentDialog() {
      this.$refs.AddCommentDialogRef?.closeAddCommentDialog();
    },
    resetCommentData() {
      this.$refs.AddCommentDialogRef?.resetCommentData();
    },

    formatDate(data) {
      return format(parseISO(data), "dd/MM/yy");
    },
    formatTime(data) {
      return format(parseISO(data), "hh:mm");
    },

    formatFullName(fullName, value) {
      return fullName?.length > value
        ? fullName?.slice(0, value) + "..."
        : fullName;
    },

    formatShiftDate(data) {
      return format(parseISO(data), "EEEE, LLL dd, yyyy");
    },

    formatShiftTime(data) {
      return format(parseISO(data), "HH:mm");
    },

    goBackwardShift(showLoader) {
      this.allowEndpoint = false;
      clearInterval(this.timer);
      this.previousLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${this.routeMachineId || this.aftMacId}/`,
          {
            shift_start_date: this.previousShift?.previous_shift_start_date,
            shift_end_date: this.previousShift?.previous_shift_end_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.previousLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.previousLogsLoader = false;
        });
    },

    goForwardShift(showLoader) {
      this.allowEndpoint = true;
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${this.routeMachineId || this.aftMacId}/`,
          {
            shift_start_date: this.nextShift?.next_shift_start_date,
            shift_end_date: this.nextShift?.next_shift_end_date,
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
        })
        .finally(() => {
          clearInterval(this.timer);
          this.timer = setInterval(() => {
            if (this.errorCount < 10 && this.isCurrentShift) {
              this.getCurrentShift(false);
            } else {
              clearInterval(this.timer);
            }
          }, 5000);
        });
    },

    getCurrentShift(showLoader) {
      this.machinesLogsLoader = showLoader;
      ApiNoToken()
        .post(
          `/machines/logs-per-shift/${this.routeMachineId || this.aftMacId}/`,
          {
            shift_start_date:
              this.currentShift?.current_shift_start_date ||
              "2022-01-12T08:07:02",
            shift_end_date:
              this.currentShift?.current_shift_end_date ||
              "2022-01-12T09:07:02",
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            let dataList = res.data.data;
            dataList.forEach((item) => {
              this.structureData(item);
            });
            this.formattedFaultLogs = this.formatFaultLogs(this.faultLogs);
            this.formattedNioLogs = this.formatNioLogs(this.nioLogs);
            this.formattedCommentLogs = this.formatCommentLogs(
              this.commentLogs
            );
          }
          this.machinesLogsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.machinesLogsLoader = false;
          this.errorCount++;
        });
    },

    getMainCurrentShiftData(data) {
      if (Object.hasOwnProperty.call(data, "current_shift_start_date")) {
        this.mainCurrentShift = data;
        this.setCurrentShiftStartDate(data.current_shift_start_date);
        this.setCurrentShiftEndDate(data.current_shift_end_date);
      }
    },

    // Switches Section
    plannedStopMachine(active) {
      Api()
        .post(`/machines/action-log/${this.routeMachineId || this.aftMacId}/`, {
          reason_type_id: actionLogTypes.plannedStop,
          status: active ? 1 : 0,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMahineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isPlannedStop = !this.isPlannedStop;
        });
    },

    prepareMachine(active) {
      Api()
        .post(`/machines/action-log/${this.routeMachineId || this.aftMacId}/`, {
          reason_type_id: actionLogTypes.prepare,
          status: active ? 1 : 0,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMahineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isPrepare = !this.isPrepare;
        });
    },

    cleanMachine(active) {
      Api()
        .post(`/machines/action-log/${this.routeMachineId || this.aftMacId}/`, {
          reason_type_id: actionLogTypes.cleaning,
          status: active ? 1 : 0,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.showSuccess(res.data.detail);
            this.updateMahineSwitches(res.data.data);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.isCleaning = !this.isCleaning;
        });
    },

    startBreak() {
      this.startBreakLoader = true;
      Api()
        .post(`/machines/action-log/${this.routeMachineId || this.aftMacId}/`, {
          reason_type_id: actionLogTypes.employeeBreak,
          status: 1,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.startBreakLoader = false;
            this.setStartBreak();
            this.showSuccess(res.data.detail);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.startBreakLoader = false;
        });
    },

    stopBreak() {
      this.stopBreakLoader = true;
      Api()
        .post(`/machines/action-log/${this.routeMachineId || this.aftMacId}/`, {
          reason_type_id: actionLogTypes.employeeBreak,
          status: 0,
        })
        .then((res) => {
          if (res.data.status == "success") {
            this.stopBreakLoader = false;
            this.setStopBreak();
            this.showSuccess(res.data.detail);
          }
        })
        .catch((err) => {
          this.showError(err);
          this.stopBreakLoader = false;
        });
    },

    updateMahineSwitches(newData) {
      this.isCleaning = newData.clean_data;
      this.isPlannedStop = newData.stopped_data;
      this.isPrepare = newData.prepare_data;
    },

    setStartBreak() {
      this.scrollToModalTop();
      this.isEmployeeBreak = true;
    },

    setStopBreak() {
      this.isEmployeeBreak = false;
    },

    // Getting reasons
    getAllCommentReasons() {
      ApiNoToken()
        .post("/machines/get-dropdowns/5/")
        .then((res) => {
          if (res.data.status == "success") {
            this.commentReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getAllFaultReasons() {
      const payload = {
        machine_id: Number(this.routeMachineId),
      };
      ApiNoToken()
        .post("/machines/get-dropdowns/6/", payload)
        .then((res) => {
          if (res.data.status == "success") {
            this.faultReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    getAllNioReasons() {
      const payload = {
        machine_id: Number(this.routeMachineId),
      };
      ApiNoToken()
        .post("/machines/get-dropdowns/7/", payload)
        .then((res) => {
          if (res.data.status == "success") {
            this.nioReasons = [...res.data.data];
            this.formattedLang = checkLanguage(this.shlViewLang);
          }
        })
        .catch((err) => {
          this.showError(err);
        });
    },

    showErrorAlert(errorMessage) {
      let msg = "Authentication credentials were not provided.";
      if (msg == errorMessage && this.shlViewLang === "en") {
        this.longAlertMsg = "Please log in to perform this action";
        this.isLongAlert = true;
        this.dialogFaults = false;
      } else if (msg == errorMessage && this.shlViewLang === "de") {
        this.longAlertMsg =
          "Bitte melden Sie sich an, um diese Aktion durchzuführen";
        this.isLongAlert = true;
        this.dialogFaults = false;
      } else {
        this.showAlert = true;
        this.alertColor = "#e76a6a";
        this.textColor = "#ffffff";
        this.alertMessage = errorMessage;
        this.isSuccess = false;
      }
      this.scrollToTop();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    scrollToModalTop() {
      setTimeout(() => {
        const modalTop = document.querySelector("#modalTop");
        modalTop.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },

    getMachineId() {
      this.aftMacId = localStorage.getItem("aftMacId");
    },

    getMachineIdFromRoute() {
      this.routeMachineId = this.$route.params.routeMachineId;
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showErrorAlert(errorMessage);
    },

    ...mapActions("users", [
      "setCurrentShiftStartDate",
      "setCurrentShiftEndDate",
    ]),

    ...mapActions("machines", ["setMachineStatus", "setMachineName"]),
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.authentication.isLoggedIn;
    },
    currentShiftStartDate() {
      return this.$store.state.users.currentShiftStartDate;
    },
    currentShiftEndDate() {
      return this.$store.state.users.currentShiftEndDate;
    },
    fullName() {
      return this.currentUserData?.user?.name;
    },

    currentUserData() {
      return this.$store.state.authentication.currentUser?.data;
    },

    isAdmin() {
      return this.$store.state.authentication.isAdmin;
    },

    isCurrentShift() {
      return (
        this.mainCurrentShift?.current_shift_start_date ==
        this.currentShift?.current_shift_start_date
      );
    },

    isDisableNextButton() {
      return (
        this.mainCurrentShift?.current_shift_end_date ==
        this.nextShift?.next_shift_start_date
      );
    },

    machineName() {
      return this.$store.state.machines.machineName;
    },

    otherMachineStatus() {
      return this.$store.state.machines.otherMachineStatus;
    },

    shlViewLang() {
      return this.$store.state.translation.i18n.locale;
    },
  },

  mounted() {
    this.$watch(
      "$route.path",
      function () {
        this.currentTab = this.$router.history.current.fullPath;
      },
      { deep: true }
    );

    this.getAllMachinesLogs(true);
    this.getAllFaultReasons();
    this.getAllCommentReasons();
    this.getAllNioReasons();
  },

  watch: {
    shlViewLang(val) {
      if (val) {
        this.formattedLang = checkLanguage(val);
      }
    },
  },

  created() {
    this.getMachineIdFromRoute();
    this.getMachineId();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

* /deep/ .switch .v-input--selection-controls__input {
  margin-right: 0 !important;
}

* /deep/ .theme--light.v-input--switch .v-input--switch__track {
  background-color: #dad5d5;
}

* /deep/.switch.planned-stop .v-input--switch__track {
  background-color: #32d583;
  opacity: 1;
}

.start-break-btn {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
