import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
const AUTH_TOKEN_KEY = "aftToken";
const REFRESH_TOKEN_KEY = "aftRefreshToken";
import store from "../store/index";

export function setAuthToken(token) {
  Cookies.set(AUTH_TOKEN_KEY, token);
}

export function getRefreshToken() {
  return Cookies.get(REFRESH_TOKEN_KEY);
}

export function setRefreshToken(refreshToken) {
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken);
}

export function getAuthToken() {
  return Cookies.get(AUTH_TOKEN_KEY);
}

export function isLoggedIn() {
  const authToken = store.state.authentication.authToken;
  return !!authToken;
}

function getTokenExpirationDate(encodedToken) {
  const token = jwt_decode(encodedToken);
  if (!token.exp) {
    return null;
  }
  return token.exp;
}

export function logoutUser() {
  clearAuthToken();
  clearRefreshToken();
  store.dispatch("authentication/clearCurrentUser");
  localStorage.setItem("aftvuex", null);
}

export function clearAuthToken() {
  return Cookies.remove(AUTH_TOKEN_KEY);
}

export function clearRefreshToken() {
  return Cookies.remove(REFRESH_TOKEN_KEY);
}

export function userRole() {
  return store.state.authentication.role;
}
export function isTokenActive(token) {
  const expirationDate = getTokenExpirationDate(token);
  const now = Math.floor(Date.now() / 1000);
  return expirationDate > now;
}
