export const required = (propertyType) => {
  if (propertyType) {
    return (v) =>
      (v && v.length > 0 && v !== undefined) ||
      `Please provide your ${propertyType}`;
  }
};

export const checkboxRules = () => {
  return (val) => !!val || "Please check this box to continue!";
};

export const validEmail = (val) => {
  const regex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
  return (val && regex.test(val)) || "Please provide a valid email";
};

export const minValue = (propertyType, minimumValue) => {
  return (v) =>
    (v && v > minimumValue) ||
    `${propertyType} should be more than ${minimumValue}`;
};

export const maxValue = (propertyType, maximumValue) => {
  return (v) =>
    (v && v <= maximumValue) ||
    `${propertyType} should not be more than ${maximumValue}`;
};

export const minLength = (propertyType, minLen) => {
  return (v) =>
    (v && v.length >= minLen) ||
    `${propertyType} should be at least ${minLen} characters`;
};

export const maxLength = (propertyType, maxLen) => {
  return (v) =>
    (v && v.length <= maxLen) ||
    `${propertyType} should be less than ${maxLen} characters`;
};

export const minDigitLength = (propertyType, minLen) => {
  return (v) =>
    (v && v.toString().length <= minLen) ||
    `${propertyType} should be at least ${minLen} digits`;
};

export const maxDigitLength = (propertyType, maxLen) => {
  return (v) =>
    (v && v.toString().length <= maxLen) ||
    `${propertyType} should be less than ${maxLen} digits`;
};

export const onlyNumbers = (language) => {
  const messages = {
    en: "Should contain only numbers",
    de: "Sollte nur Zahlen enthalten",
    cn: "应只包含数字",
    ro: "Ar trebui să conțină numai numere",
    es: "Sólo debe contener númeross",
    ba: "Treba da sadrži samo brojeve",
    rs: "Треба да садржи само бројеве",
  };
  return (v) => /^\d*$/.test(v) || messages[language];
};

export const notRequiredMaxLength = (val, propertyType, maxValLength) => {
  if (val !== "" && val !== null) {
    return (
      val.length <= maxValLength ||
      `${propertyType} should be less than ${maxValLength} characters`
    );
  }
  return true;
};

export const emailFormat = () => {
  const regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return (v) => (v && regex.test(v)) || "Please provide a valid email";
};

export const mustContainNumber = () => {
  return (v) => (v && v.match(/(\d)/)) || "Should contain a numeric value";
};

export const noSpaceAllowed = (language) => {
  const messages = {
    en: "No spaces allowed",
    de: "Keine Leerzeichen erlaubt",
    cn: "不允许有空格",
    ro: "Nu sunt permise spații",
    es: "No se admiten espacios",
    ba: "Nema dozvoljenih mjesta",
    rs: "Нема дозвољених места",
  };
  return (v) => (v && !/\s/.test(v)) || messages[language];
};

export const nonNegativeNumber = (language) => {
  const messages = {
    en: "Quantity should not be less than 0",
    de: "Die Menge sollte nicht kleiner als 0 sein",
    cn: "数量不应少于0",
    ro: "Cantitatea nu trebuie să fie mai mică de 0",
    es: "La cantidad no debe ser inferior a 0",
    ba: "Količina ne smije biti manja od 0",
    rs: "Количина не би требало да буде мања од 0",
  };
  return (v) =>
    v === "" ||
    (!isNaN(parseFloat(v)) && isFinite(v) && v >= 0) ||
    messages[language];
};

export const mustContainLower = (password) => {
  const regex = /^[a-z]+$/;
  return password.match(regex) || "Should contain at least one lower case";
};

export const mustContainSymbol = (password) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /[-!$%^&*@#()_+|~=`{}\[\]:";'<>?,.\/]/;
  // eslint-disable-next-line no-mixed-operators
  return password.match(regex) || "Should contain at least one symbol";
};

export const passwordMatch = (password1, password2, language) => {
  const messages = {
    en: "Passwords do not match",
    de: "Passwörter stimmen nicht überein",
    cn: "密码不匹配",
    ro: "Parolele nu se potrivesc",
    es: "Las contraseñas no coinciden",
    ba: "Lozinke se ne poklapaju",
    rs: "Лозинке се не поклапају",
  };
  return (v) => (v && v === password1 && v === password2) || messages[language];
};

export const maxFileSize = (prototype, maxSize) => {
  return (files) =>
    !files ||
    !files.some((file) => file.size > maxSize * 1024 * 1024) ||
    `${prototype} size should be less than ${maxSize} MB!`;
};

export const phoneNumberFormat = () => {
  const regex = /^\+?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,9})$/;
  // eslint-disable-next-line no-mixed-operators
  return (v) => (v && regex.test(v)) || "Should be a valid phone number";
};

export const notRequiredPhoneNumberFormat = (val) => {
  if (val) {
    const regex = /^\+?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4,9})$/;
    // eslint-disable-next-line no-mixed-operators
    return (v) => (v && regex.test(v)) || "Should be a valid phone number";
  } else {
    return true;
  }
};

export const removeDot = () => {
  const regex = /\./g; // regular expression to match all dots in the string
  return (v) => (v && !regex.test(v)) || "Should not contain any dot";
};

export const FileSizeValidation = (fileSize, requiredSize) => {
  /** onst sizeInMb = requiredSize / 1024 / 1024 **/
  return requiredSize >= fileSize;
};

export const customRule = (propertyType) => {
  if (propertyType) {
    return (v) =>
      (v && v.length > 0 && v !== undefined) || "This is an error message";
  }
};

export default {
  FileSizeValidation,
  maxFileSize,
  passwordMatch,
  mustContainLower,
  mustContainNumber,
  mustContainSymbol,
  emailFormat,
  required,
  minLength,
  maxLength,
  minDigitLength,
  maxDigitLength,
  notRequiredMaxLength,
  noSpaceAllowed,
  validEmail,
  onlyNumbers,
  phoneNumberFormat,
  removeDot,
  notRequiredPhoneNumberFormat,
  checkboxRules,
  minValue,
  maxValue,
  customRule,
  nonNegativeNumber,
};
