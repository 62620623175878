<template>
  <div>
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 6.66665H1.5M10.1667 1.33331V3.99998M4.83333 1.33331V3.99998M4.7 14.6666H10.3C11.4201 14.6666 11.9802 14.6666 12.408 14.4487C12.7843 14.2569 13.0903 13.951 13.282 13.5746C13.5 13.1468 13.5 12.5868 13.5 11.4666V5.86665C13.5 4.74654 13.5 4.18649 13.282 3.75867C13.0903 3.38234 12.7843 3.07638 12.408 2.88463C11.9802 2.66665 11.4201 2.66665 10.3 2.66665H4.7C3.5799 2.66665 3.01984 2.66665 2.59202 2.88463C2.21569 3.07638 1.90973 3.38234 1.71799 3.75867C1.5 4.18649 1.5 4.74654 1.5 5.86665V11.4666C1.5 12.5868 1.5 13.1468 1.71799 13.5746C1.90973 13.951 2.21569 14.2569 2.59202 14.4487C3.01984 14.6666 3.5799 14.6666 4.7 14.6666Z"
        stroke="#98A2B3"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {};
</script>
