<template>
  <div class="bg-[#ffffff] h-[100vh] max-h-[90.5vh]">
    <div class="w-[94%] pl-14">
      <v-card elevation="0">
        <v-card-text>
          <div class="flex justify-between items-center my-4">
            <div class="text-[18px] font-[600] text-[#101828]">
              {{ $t("machinegroup.machineGroup") }}
            </div>
            <!-- Add Machine Group dialog box  -->
            <v-dialog v-model="dialogAdd" width="408">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  height="40"
                  v-bind="attrs"
                  v-on="on"
                  color="#f5f5ff"
                  depressed
                  class="add-btn"
                  >{{ $t("machinegroup.add") }}</v-btn
                >
              </template>
              <v-card>
                <v-form ref="machineGroupForm">
                  <v-card-title
                    ><div class="mx-auto font-semibold text-lg text-[#101828]">
                      {{ $t("machinegroup.machineGroup") }}
                    </div>

                    <div>
                      <v-img
                        @click="dialogAdd = false"
                        :src="require(`../../assets/close-icon.svg`)"
                        :lazy-src="require(`../../assets/close-icon.svg`)"
                        class="cursor-pointer"
                      >
                      </v-img></div
                  ></v-card-title>
                  <v-card-text>
                    <v-label>{{ $t("machinegroup.addName") }}</v-label>
                    <v-text-field
                      :placeholder="$t('machinegroup.forEgSae')"
                      dense
                      ref="name"
                      v-model="machineGroupName"
                      outlined
                      color="#667085"
                    ></v-text-field>
                    <div class="flex justify-between items-center">
                      <div>
                        <v-btn
                          color="#ffffff"
                          class="cancel-btn"
                          @click="dialogAdd = false"
                          depressed
                          outlined
                          width="174"
                          height="44"
                          >{{ $t("machinegroup.cancel") }}</v-btn
                        >
                      </div>
                      <div>
                        <v-btn
                          class="save-btn"
                          depressed
                          color="primary"
                          width="174"
                          height="44"
                          :loading="addMachineGroupLoader"
                          @click="addMachineGroup"
                          >{{ $t("machinegroup.save") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-dialog>
          </div>
          <v-divider class="my-6"></v-divider>
          <!-- Machine Group table  -->
          <v-data-table
            dense
            :headers="
              machineGroupLang === 'en'
                ? headers
                : machineGroupLang === 'de'
                ? deHeaders
                : machineGroupLang === 'cn'
                ? cnHeaders
                : machineGroupLang === 'ro'
                ? roHeaders
                : machineGroupLang === 'ba'
                ? baHeaders
                : machineGroupLang === 'es'
                ? esHeaders
                : rsHeaders
            "
            :items="formattedMachineGroups"
            item-key="machine_group_id"
            class="border-2"
            :hide-default-footer="true"
            :no-data-text="$t('machinegroup.nodata')"
          >
            <template v-slot:[`header.icons`]="{}">
              <div class="flex justify-end mt-5 mr-12">
                <div>{{ $t("machinegroup.actions") }}</div>
              </div>
            </template>
            <template v-slot:[`item.icons`]="{ item }">
              <div class="flex items-center justify-end gap-5 pr-10">
                <div>
                  <v-dialog
                    :retain-focus="false"
                    v-model="dialogDelete"
                    width="400"
                    overlay-opacity="0.1"
                    content-class="elevation-0"
                  >
                    <template v-slot:activator="{}">
                      <v-img
                        class="cursor-pointer w-[15px]"
                        :src="require('../../assets/delete-icon.svg')"
                        :lazy-src="require('../../assets/delete-icon.svg')"
                        @click="showDeleteDialog(item)"
                      ></v-img>
                    </template>
                    <v-card class="pb-4">
                      <v-card-title class="flex justify-between">
                        <div class="font-semibold text-lg text-[#101828]">
                          {{
                            $t("machinegroup.delete", {
                              "1": selectedMachineGroup?.machine_group_name,
                            })
                          }}
                        </div>
                        <div>
                          <v-img
                            @click="dialogDelete = false"
                            :src="require(`../../assets/close-icon.svg`)"
                            :lazy-src="require(`../../assets/close-icon.svg`)"
                            class="cursor-pointer"
                          ></v-img>
                        </div>
                      </v-card-title>
                      <v-card-text
                        ><div class="text-[#667085] font-[400] text-[14px]">
                          {{ $t("machinegroup.areYouSure")
                          }}<span class="font-bold">{{
                            $t("machinegroup.containing", {
                              "1": selectedMachineGroup?.machine_group_name,
                              "2": selectedMachineGroup?.total_machines,
                            })
                          }}</span
                          >{{ $t("machinegroup.thisAction") }}
                        </div></v-card-text
                      >
                      <div class="flex justify-center items-center gap-3">
                        <div>
                          <v-btn
                            class="group-cancel-btn"
                            outlined
                            depressed
                            color="#ffffff"
                            width="170"
                            height="44"
                            @click="dialogDelete = false"
                            >{{ $t("machinegroup.cancel") }}</v-btn
                          >
                        </div>
                        <div>
                          <v-btn
                            class="group-delete-btn"
                            depressed
                            color="#D92D20"
                            width="170"
                            height="44"
                            :loading="deleteMachineGroupLoader"
                            @click="removeMachineGroup"
                            >{{ $t("machinegroup.yesDelete") }}</v-btn
                          >
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                </div>
                <div>
                  <v-img
                    class="cursor-pointer w-[15px]"
                    :src="require('../../assets/edit-icon.svg')"
                    :lazy-src="require('../../assets/edit-icon.svg')"
                    @click="startEditMachineGroup(item)"
                  ></v-img>
                </div>
              </div>
            </template>
          </v-data-table>

          <EditMachineGroupDialog
            :selectedMachineGroup="selectedMachineGroup"
            @update-machineGroupName="updateMachineGroupName"
            @update-machineGroup="updateMachineGroup"
            ref="EditMachineGroupDialogRef"
          />

          <div class="flex justify-end mt-4">
            <v-pagination
              class="paginate_class"
              v-model="page"
              :length="totalPages"
              :total-visible="8"
              next-icon="mdi-arrow-right"
              prev-icon="mdi-arrow-left"
              @input="handlePageChange"
              color="#F5F5F5"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay :value="allMachineGroupsLoader" color="grey">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <div class="position-alert" v-if="showAlert">
      <AlertComponent
        :alertColor="alertColor"
        :textColor="textColor"
        :alertMessage="alertMessage"
        :isSuccess="isSuccess"
        @hide-alert="hideAlert"
      />
    </div>
  </div>
</template>

<script>
import Api from "../../services/api";
import EditMachineGroupDialog from "../../components/admin/EditMachineGroupDialog.vue";
import AlertComponent from "../../components/AlertComponent.vue";

export default {
  components: { EditMachineGroupDialog, AlertComponent },
  data() {
    return {
      dialogAdd: false,
      dialogDelete: false,
      headers: [
        {
          text: "Name",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      deHeaders: [
        {
          text: "Name",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      esHeaders: [
        {
          text: "Nombre",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      cnHeaders: [
        {
          text: "命名",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      roHeaders: [
        {
          text: "Nume",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      baHeaders: [
        {
          text: "Ime",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      rsHeaders: [
        {
          text: "Име",
          align: "start",
          value: "machine_group_name",
        },
        { text: "", value: "icons" },
      ],
      machineGroup: [
        {
          machine_group_name: "SAE3",
        },
        {
          machine_group_name: "KL-automat",
        },
      ],

      machineGroupName: "",
      allMachineGroupsLoader: false,
      formattedMachineGroups: [],
      selectedMachineGroup: null,
      addMachineGroupLoader: false,
      deleteMachineGroupLoader: false,
      page: 1,
      totalPages: 1,
      perPage: 10,
      totalMachineGroupsCount: 1,

      showAlert: false,
      alertColor: "",
      textColor: "",
      alertMessage: "",
      isSuccess: true,

      machineCountData: null,
    };
  },

  computed: {
    machineGroupLang() {
      return this.$store.state.translation.i18n.locale;
    },
    machineCounts() {
      if (this.selectedMachineGroup?.machine_group__name == "Automatic") {
        return this.machineCountData?.automatic_machine_count;
      } else if (this.selectedMachineGroup?.machine_group__name == "Manual") {
        return this.machineCountData?.manual_machine;
      } else {
        return this.machineCountData?.other_machine_count;
      }
    },
  },

  methods: {
    getAllMachineGroups(showLoader) {
      this.allMachineGroupsLoader = showLoader;
      Api()
        .get(`/machines/paginated-machine-groups/${this.page}/`)
        .then((res) => {
          if (res.data.status == "success") {
            this.formattedMachineGroups = [
              ...this.formatMachineGroupsData(res.data.data),
            ];
            this.totalPages = res.data.total_pages;
            this.totalMachineGroupsCount = res.data.total_machine_groups;
            this.machineCountData = res.data.machine_counts;
          }
          this.allMachineGroupsLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.allMachineGroupsLoader = false;
        });
    },

    handlePageChange(value) {
      this.page = value;
      this.getAllMachineGroups(true);
      this.scrollToTop();
    },

    // Adding a Machine Group
    addMachineGroupFeatures(group) {
      group.deleteIcon = require("../../assets/delete-icon.svg");
      group.editIcon = require("../../assets/edit-icon.svg");
      group.machine_group_name = group.machine_group__name;
      return group;
    },

    formatMachineGroupsData(machineGroups) {
      return machineGroups.map((group) => {
        group = this.addMachineGroupFeatures(group);
        return group;
      });
    },

    addToMachineGroupsList(group) {
      group = this.addMachineGroupFeatures(group);
      this.formattedMachineGroups.unshift(group);
      this.totalMachineGroupsCount++;
      this.totalPages = Math.ceil(this.totalMachineGroupsCount / this.perPage);
    },

    addMachineGroup() {
      this.addMachineGroupLoader = true;
      let newData = {
        name: this.machineGroupName,
      };
      Api()
        .post("/machines/add-machine-group/", newData)
        .then((res) => {
          if (res.data.status == "success") {
            this.addToMachineGroupsList(res.data.data);
            this.removeLastMachineGroup();
            this.showSuccess(res.data.detail);
            this.dialogAdd = false;
          }
          this.addMachineGroupLoader = false;
          this.resetMachineGroupData();
        })
        .catch((err) => {
          this.showError(err);
          this.addMachineGroupLoader = false;
        });
    },

    removeLastMachineGroup() {
      if (this.perPage < this.totalMachineGroupsCount) {
        this.formattedMachineGroups.splice(this.perPage, 1);
      }
    },

    // Deleting a machine group
    showDeleteDialog(data) {
      this.dialogDelete = true;
      this.selectedMachineGroup = { ...data };
    },

    removeFromMachineGroupsList(groupId) {
      let machineGroups = [...this.formattedMachineGroups];
      this.formattedMachineGroups = machineGroups.filter(
        (group) => group.machine_group_id !== groupId
      );
    },

    removeMachineGroup() {
      this.deleteMachineGroupLoader = true;
      Api()
        .delete(
          `/machines/delete-machine-group/${this.selectedMachineGroup.machine_group_id}/`
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.removeFromMachineGroupsList(
              this.selectedMachineGroup.machine_group_id
            );
            this.getAllMachineGroups(false);
            this.dialogDelete = false;
            this.showSuccess(res.data.detail);
          }
          this.deleteMachineGroupLoader = false;
        })
        .catch((err) => {
          this.showError(err);
          this.deleteMachineGroupLoader = false;
        });
    },

    resetMachineGroupData() {
      this.machineGroupName = "";
    },

    // Updating Machine group Details
    startEditMachineGroup(data) {
      this.selectedMachineGroup = { ...data };
      this.showEditModal();
    },

    updateMachineGroup(data) {
      Api()
        .put(`/machines/update-machine-group/${data.machine_group_id}/`, data)
        .then((res) => {
          if (res.data.status == "success") {
            this.updateMachineGroupsList(res.data.data);
            this.showSuccess(res.data.detail);
            this.closeEditModal();
          }
          this.stopEditLoader();
        })
        .catch((err) => {
          this.showError(err);
          this.stopEditLoader();
        });
    },

    updateMachineGroupsList(group) {
      group = this.addMachineGroupFeatures(group);
      this.findAndReplaceMachineGroup(group);
    },

    findAndReplaceMachineGroup(updatedGroup) {
      let foundIndex = this.formattedMachineGroups.findIndex(
        (group) => group.machine_group_id == updatedGroup.machine_group_id
      );
      if (foundIndex == 0 || foundIndex) {
        this.formattedMachineGroups.splice(foundIndex, 1, updatedGroup);
      }
    },

    updateMachineGroupName(data) {
      this.selectedMachineGroup.machine_group_name = data;
    },

    showEditModal() {
      this.$refs.EditMachineGroupDialogRef?.showEditDialog();
    },

    closeEditModal() {
      this.$refs.EditMachineGroupDialogRef?.closeEditDialog();
    },

    stopEditLoader() {
      this.$refs.EditMachineGroupDialogRef?.stopEditLoader();
    },

    closeAddMachineGroupDialog() {
      this.$refs.machineGroupForm.reset();
    },

    hideAlert() {
      this.showAlert = false;
    },

    showSuccess(message) {
      this.showAlert = true;
      this.alertColor = "#A6F4C5";
      this.textColor = "#054F31";
      this.alertMessage = message;
      this.isSuccess = true;
      this.scrollToTop();
    },

    scrollToTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 100);
    },

    showError(err) {
      let errorData = err?.response?.data;
      let errorMessage = errorData?.messages
        ? errorData?.messages[0].message
        : errorData?.detail
        ? errorData?.detail
        : "An unexpected error occurred";

      this.showAlert = true;
      this.alertColor = "#e76a6a";
      this.textColor = "#ffffff";
      this.alertMessage = errorMessage;
      this.isSuccess = false;
      this.scrollToTop();
    },
  },

  mounted() {
    this.getAllMachineGroups(true);
    this.scrollToTop();
  },

  watch: {
    dialogAdd(val) {
      !val && this.closeAddMachineGroupDialog();
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none;
}

* /deep/ .v-pagination__item {
  border-radius: 0px;
  box-shadow: none;
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__more {
  margin: 0rem;
  height: 40px;
  width: 40px;
  border: 0.5px solid #d0d5dd;
}

* /deep/ .v-pagination__navigation {
  height: 38px;
  width: 38px;
  margin: 0rem;
  box-shadow: none;
}

* /deep/ .theme--light.v-pagination .v-pagination__item--active {
  color: #667085;
  border-color: #d0d5dd !important;
}

* /deep/ .mdi-arrow-left::before {
  font-size: 18px;
}

* /deep/ .mdi-arrow-right::before {
  font-size: 18px;
}

* /deep/ .paginate_class li:first-child {
  border: 0.1px solid #d0d5dd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

* /deep/ .paginate_class li:last-child {
  border: 0.1px solid #d0d5dd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

* /deep/.v-data-table tr:nth-child(2n + 1) {
  background-color: #fcfcfd;
}

* /deep/thead tr:first-child {
  background-color: #ffffff !important;
}

* /deep/ thead tr th {
  color: #667085 !important;
  font-weight: 400;
  font-size: 12px;
}

.add-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #f5f5ff;
  color: #0408e7;
}

.v-label {
  font-weight: 400;
  font-size: 14px;
  color: #344054;
}

.v-text-field--outlined >>> fieldset {
  border-color: #d0d5dd;
}

.v-text-field {
  border-radius: 8px !important;
}

.cancel-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
  color: #344054 !important;
}

.save-btn {
  font-weight: 400;
  font-size: 14px;
  border-radius: 8px !important;
}

.group-cancel-btn {
  font-weight: 400;
  font-size: 16px;
  color: #344054 !important;
  border-radius: 8px !important;
  border: 1px solid #d0d5dd;
}

.group-delete-btn {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff !important;
  border-radius: 8px !important;
}

.position-alert {
  position: absolute;
  top: 0%;
  right: 5%;
  z-index: 999;
}
</style>
